import React, { useEffect, useState, useRef, Fragment, useCallback } from "react";
import axios from "axios";
import { Formik, Form, Field } from "formik";
import AppLoader from "../../components/Helpers/AppLoader";
import { Dialog, Transition } from "@headlessui/react";
import SettingsTextInput from "../../components/Forms/SettingsTextInput";
import SettingsSelect from "../../components/Forms/SettingsSelect";
import BookingTextArea from "../../components/Booking/BookingTextArea";
import SubmitButton from "../../components/Forms/SubmitButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleDown, faChevronCircleRight, faPenCircle, faPlusCircle, faSave, faTrashCircle, faClone } from "@fortawesome/pro-solid-svg-icons";
import { useSelector } from "react-redux";
import SettingsTextArea from "../../components/Forms/SettingsTextarea";
import { Link } from "react-router-dom";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function EditUser() {
    const cancelButtonRef = useRef(null);
    const [openPopup, setOpenPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [bot_flows, setBotFlows] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [initialValues, setInitialValues] = useState({
        id: 0,
        parent_bot_flow_id: 0,
        name: "",
        do_close: 0,
    });
    const [locations, setLocations] = useState({
        noTags: [], // folders with has_tag = 0
        hasTags: [] // folders with has_tag = 1
    });
    const { tags } = useSelector((state) => state.tags);
    const [years, setYears] = useState([]);
    const [openManageGroups, setOpenManageGroups] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [whatsappTemplates, setWhatsappTemplates] = useState([]);
    const [properties, setProperties] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredBotFlows, setFilteredBotFlows] = useState([]);
    const [documentTemplates, setDocumentTemplates] = useState([]);
    const [lookups, setLookups] = useState([]);

    const funding_types = [
        { label: "NSFAS UJ", value: "4" },
        { label: "NSFAS WITS", value: "5" },
        // { label: "CJC", value: "6" },
        { label: "NSFAS TVET", value: "1" },
        { label: "Bursary", value: "2" },
        { label: "Private", value: "3" },
    ];

    useEffect(() => {
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}get_bot_flows`,
        })
            .then((response) => {
                setBotFlows(response.data[0]);
                setYears(response.data[1]);
                setLoading(false);
            })
            .catch((error) => {
                alert("Failed to get bot flows");
                setLoading(false);
            });

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}folders`,
        }).then((response) => {
            let allFolders = response.data.folders;
            let noTagsFolders = [];
            let hasTagsFolders = [];

            // Filter out specific folders we don't want to show
            const excludedFolders = ["All", "Declined", "Cancelled", "Archived"];

            Object.keys(allFolders).forEach((key) => {
                const folder = allFolders[key];
                if (!excludedFolders.includes(folder.name)) {
                    // Create the folder object
                    const folderObj = {
                        id: folder.id || key,
                        name: folder.name === "All Residents" ? "Residents" : folder.name,
                        value: folder.name === "All Residents" ? "Residents" : folder.name,
                        label: folder.name === "All Residents" ? "Residents" : folder.name
                    };

                    // Add to appropriate group based on has_tags value
                    if (Number(folder.has_tags) === 1) {
                        hasTagsFolders.push(folderObj);
                    } else {
                        noTagsFolders.push(folderObj);
                    }
                }
            });

            setLocations({
                noTags: noTagsFolders,
                hasTags: hasTagsFolders
            });
        });

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}templates`,
        })
            .then((response) => {
                setTemplates(response.data);
            })
            .catch((error) => {
                alert("Failed to get templates");
            });

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}whatsapp_templates`,
        })
            .then((response) => {
                setWhatsappTemplates(response.data);
            })
            .catch((error) => {
                alert("Failed to get templates");
            });

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}properties`,
        })
            .then((response) => {
                setProperties(response.data);
            })
            .catch((error) => {
                alert("Failed to get properties");
            });

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}get_document_templates`,
        })
            .then((response) => {
                setDocumentTemplates(response.data);
            })
            .catch((error) => {
                alert("Failed to get Document Templates");
            });

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}uc_lookups`,
        })
            .then((response) => {
                setLookups(response.data);
            })
            .catch((error) => {
                alert("Failed to get Document Templates");
            });
    }, []);

    const getTagsForLocation = (locationName) => {
        return tags.filter(tag => tag.parent_folder === locationName);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value); // Only update the search term
    };

    useEffect(() => {
        if (searchTerm.trim()) {
            // Filter bot_flows based on search term
            const termLower = searchTerm.toLowerCase();

            const filtered = bot_flows.filter((flow) => {
                // Check if the current flow matches
                const currentFlowMatches =
                    (flow.name || "").toLowerCase().includes(termLower) ||
                    (flow.incoming_message || "").toLowerCase().includes(termLower) || // Check incoming_message
                    (flow.action || "").toLowerCase().includes(termLower); // Check action

                // Check if any children match
                const childrenMatch = (flow.children || []).some(
                    (child) =>
                        (child.name || "").toLowerCase().includes(termLower) ||
                        (child.incoming_message || "").toLowerCase().includes(termLower) || // Check child incoming_message
                        (child.action || "").toLowerCase().includes(termLower) // Check child action
                );

                return currentFlowMatches || childrenMatch;
            });

            setFilteredBotFlows(filtered);
        } else {
            setFilteredBotFlows(bot_flows); // Reset if search term is empty
        }
    }, [searchTerm, bot_flows]);

    if (loading === true) {
        return <AppLoader />;
    }

    const open_popup = (e, id, child_id, task) => {
        e.preventDefault();

        if (child_id > 0 && task === "edit") {
            setInitialValues(bot_flows.filter((flow) => flow.id === id)[0].children.filter((child) => child.id === child_id)[0]);
        } else if (child_id == 0 && task === "edit") {
            setInitialValues(bot_flows.filter((flow) => flow.id === id)[0]);
        } else if (id > 0 && task === "add") {
            setInitialValues({
                id: 0,
                parent_bot_flow_id: id,
                name: "",
                do_close: 0,
                do_archive: 0,
                end_bot_flow: 0,
                match_message: 0,
                match_location: 0,
                do_move_chat: 0,
                do_skip_item: 0,
                do_update_data: 0,
                do_action: 0,
                do_change_bot_flow: 0,
                do_send_message: 0,
                message_type: 0,
                bot_type: "Whatsapp",
                action: "All",
                do_move_linked_chat: 0,
                do_upload_file: 0,
                gallery_to_send: 0,
                force_year: 0,
                retrigger_limit: 0,
                custom_data: "",
                action_match: "",
                button_name: "",
                message_attachment: 0,
            });
        } else {
            setInitialValues({
                id: 0,
                parent_bot_flow_id: 0,
                name: "",
                do_close: 0,
                do_archive: 0,
                end_bot_flow: 0,
                match_message: 0,
                match_location: 0,
                do_move_chat: 0,
                do_skip_item: 0,
                do_update_data: 0,
                do_action: 0,
                do_change_bot_flow: 0,
                do_send_message: 0,
                message_type: 0,
                bot_type: "Whatsapp",
                action: "All",
                do_move_linked_chat: 0,
                do_upload_file: 0,
                gallery_to_send: 0,
                force_year: 0,
                retrigger_limit: 0,
                custom_data: "",
                action_match: "",
                button_name: "",
                message_attachment: 0,
            });
        }

        setOpenPopup(1);
    };

    const handleDelete = (e, id) => {
        e.preventDefault();

        if (window.confirm("Are you sure you want to delete this flow?")) {
            axios({
                method: "delete",
                url: `${process.env.REACT_APP_API_URL}delete_bot_flow`,
                data: {
                    id: id,
                },
            })
                .then((resp) => {
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}get_bot_flows`,
                    })
                        .then((response) => {
                            setBotFlows(response.data[0]);
                            setYears(response.data[1]);
                            setLoading(false);
                        })
                        .catch((error) => {
                            alert("Failed to get bot flows");
                            setLoading(false);
                        });
                })
                .catch((error) => {
                    console.log(error);
                    console.log(error.response);
                });
        }
    };

    const edit_rules = (e, bot_flow_id, child_id, rule_id, action) => {
        e.preventDefault();

        if (action === "edit") {
            if (child_id > 0) {
                setInitialValues(
                    bot_flows
                        .find((flow) => flow.id === bot_flow_id)
                        ?.children?.find((child) => child.id == child_id)
                        ?.bot_flow_rules.find((rule) => rule.id === rule_id)
                );
            } else {
                setInitialValues(bot_flows.find((flow) => flow.id === bot_flow_id)?.bot_flow_rules.filter((rule) => rule.id === rule_id)[0]);
            }
            setOpenPopup(2);
        }

        if (action === "add") {
            setInitialValues({
                id: 0,
                bot_flow_id: child_id > 0 ? child_id : bot_flow_id,
                incoming_message: "",
                do_send_message: 0,
                message_to_send: "",
                do_move_chat: 0,
                move_to_location: "",
                end_bot_flow: 0,
                bot_type: "Whatsapp",
                action: "All",
                match_message: 0,
                match_location: 0,
                do_match_funding_type: 0,
                do_match_property: 0,
                status: "Active",
                do_change_bot_flow: 0,
                do_action: 0,
                action_to_do: 0,
                do_move_linked_chat: 0,
                do_upload_file: 0,
                force_year: 0,
                do_match_linked_chat: 0,
                do_match_renewal: 0,
                do_match_budget: 0,
                budget_id: 0,
                do_match_study_year: 0,
                study_year_id: 0,
                do_match_signed_lease: 0,
                robot_colour: 0,
            });

            setOpenPopup(2);
        }

        if (action == "delete") {
            if (window.confirm("Are you sure you want to delete this rule?")) {
                axios({
                    method: "delete",
                    url: `${process.env.REACT_APP_API_URL}delete_bot_flow_rule`,
                    data: {
                        id: rule_id,
                    },
                })
                    .then((resp) => {
                        axios({
                            method: "get",
                            url: `${process.env.REACT_APP_API_URL}get_bot_flows`,
                        })
                            .then((response) => {
                                setBotFlows(response.data[0]);
                                setYears(response.data[1]);
                                setLoading(false);
                            })
                            .catch((error) => {
                                alert("Failed to get bot flows");
                                setLoading(false);
                            });
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error.response);
                    });
            }
        }
    };

    const edit_options = (e, bot_flow_id, child_id, rule_id, action) => {
        e.preventDefault();

        if (action == "edit") {
            if (child_id > 0) {
                setInitialValues(
                    bot_flows
                        .find((flow) => flow.id === bot_flow_id)
                        ?.children?.find((child) => child.id == child_id)
                        ?.bot_flow_options.find((rule) => rule.id === rule_id)
                );
            } else {
                setInitialValues(bot_flows.filter((flow) => flow.id === bot_flow_id)[0].bot_flow_options.filter((rule) => rule.id === rule_id)[0]);
            }

            setOpenPopup(3);
        }

        if (action == "add") {
            setInitialValues({
                id: 0,
                bot_flow_id: child_id > 0 ? child_id : bot_flow_id,
                option_name: "",
                sort_order: 0,
            });
            setOpenPopup(3);
        }

        if (action == "delete") {
            if (window.confirm("Are you sure you want to delete this rule?")) {
                axios({
                    method: "delete",
                    url: `${process.env.REACT_APP_API_URL}delete_bot_flow_option`,
                    data: {
                        id: rule_id,
                    },
                })
                    .then((resp) => {
                        axios({
                            method: "get",
                            url: `${process.env.REACT_APP_API_URL}get_bot_flows`,
                        })
                            .then((response) => {
                                setBotFlows(response.data[0]);
                                setYears(response.data[1]);
                                setLoading(false);
                            })
                            .catch((error) => {
                                alert("Failed to get bot flows");
                                setLoading(false);
                            });
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error.response);
                    });
            }
        }
    };

    const duplicateBotFlow = (id) => {
        if (window.confirm("Are you sure you want to duplicate this flow?")) {
            axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}clone_bot_flow`,
                data: { id: id },
            })
                .then((response) => {
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}get_bot_flows`,
                    })
                        .then((response) => {
                            setBotFlows(response.data[0]);
                            setYears(response.data[1]);
                            setLoading(false);
                        })
                        .catch((error) => {
                            alert("Failed to get bot flows");
                            setLoading(false);
                        });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const findBotFlowName = (id) => {
        // Loop through bot_flows
        for (const botFlow of bot_flows) {
            // Check if the parent flow matches
            if (botFlow.id === id) {
                return botFlow.name;
            }
            // If not, check its children
            if (botFlow.children && botFlow.children.length > 0) {
                const childFlow = botFlow.children.find((child) => child.id === id);
                if (childFlow) {
                    return childFlow.name;
                }
            }
        }
        // If no match found, return the id (or some placeholder text)
        return "Unknown Flow";
    };

    return (
        <div className="px-1 sm:px-4 my-2 xl:my-0 xl:p-4 h-screen w-full">
            <div className="flex justify-between mb-8 items-center">
                <h1 className="text-lg font-bold">Bot Flows</h1>

                <div className="flex space-x-2">
                    <button className="bg-primary text-white px-4 py-2 rounded-full" onClick={(e) => open_popup(e, 0, 0, "add")}>
                        Add a Flow
                    </button>

                    <button className="bg-primary text-white px-4 py-2 rounded-full" onClick={(e) => setOpenManageGroups(openManageGroups ? false : true)}>
                        Manage Groups
                    </button>

                    <Link to="/settings_new" className="bg-primary text-white px-4 py-2 rounded-full">
                        Back to Settings
                    </Link>
                </div>
            </div>

            {isOpen === false && (
                <div className="mb-4">
                    <input type="text" placeholder="Search Bot Flows" value={searchTerm} onChange={handleSearchChange} className="w-full p-2 border rounded" />
                </div>
            )}

            {filteredBotFlows?.map((bot_flow) => (
                <div key={"bot_flow_" + bot_flow.id} className={isOpen === false || isOpen === bot_flow.id ? "" : "hidden"}>
                    <div className="mb-8 bg-gray-200 px-4 py-2">
                        <div className="flex justify-between">
                            <div title={bot_flow.id}>
                                <strong>Name:</strong> {bot_flow.name} | {bot_flow.id} | ({bot_flow.status}) - {bot_flow.children.length} {bot_flow.children.length == 1 ? "Item" : "Items"}
                                <span className="ml-8">
                                    <strong>Message:</strong> {bot_flow.incoming_message}
                                </span>
                                <span className="ml-8">
                                    <strong>Trigger:</strong> {bot_flow.action}
                                </span>
                            </div>
                            <div className="space-x-2 flex items-center">
                                <div>{bot_flow.sort_order}</div>

                                {isOpen === bot_flow.id && bot_flow.children.length == 0 && (
                                    <button onClick={(e) => handleDelete(e, bot_flow.id)}>
                                        <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faTrashCircle} />
                                    </button>
                                )}

                                {isOpen === bot_flow.id && (
                                    <button onClick={(e) => duplicateBotFlow(bot_flow.id)}>
                                        <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faClone} />
                                    </button>
                                )}

                                {isOpen === bot_flow.id && (
                                    <button onClick={(e) => open_popup(e, bot_flow.id, 0, "edit")}>
                                        <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faPenCircle} />
                                    </button>
                                )}

                                <button onClick={() => setIsOpen(isOpen === bot_flow.id ? false : bot_flow.id)}>
                                    {isOpen === bot_flow.id ? (
                                        <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faChevronCircleDown} />
                                    ) : (
                                        <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faChevronCircleRight} />
                                    )}
                                </button>
                            </div>
                        </div>

                        {isOpen === bot_flow.id && (
                            <div>
                                <div>
                                    <strong>Bot Type:</strong> {bot_flow.bot_type}
                                    <br />
                                    <strong>Trigger:</strong> {bot_flow.action}
                                </div>

                                <div className="mt-4 grid grid-cols-4">
                                    <div>
                                        <strong>Match Message:</strong> {bot_flow.match_message === 1 ? "Yes" : "No"}
                                    </div>
                                    <div className="col-span-3">{bot_flow.incoming_message}</div>
                                </div>

                                <div className="grid grid-cols-4">
                                    <div>
                                        <strong>Match Location:</strong> {bot_flow.match_location === 1 ? "Yes" : "No"}
                                    </div>
                                    <div className="col-span-3">
                                        {bot_flow.current_location} {bot_flow.current_tag_id > 0 && " - (Tag = " + tags.filter((tag) => tag.id === bot_flow.current_tag_id)[0]?.name + ")"}
                                    </div>
                                </div>

                                <div className="grid grid-cols-4">
                                    <div>
                                        <strong>Move Chat:</strong> {bot_flow.do_move_chat === 1 ? "Yes" : "No"}
                                    </div>
                                    <div className="col-span-3">
                                        {bot_flow.move_to_location}
                                        {bot_flow.move_to_tag > 0 && " - (Tag = " + tags.filter((tag) => tag.id === bot_flow.move_to_tag)[0]?.name + ")"}
                                    </div>
                                </div>

                                <div className="grid grid-cols-4">
                                    <div>
                                        <strong>Skip Item:</strong> {bot_flow.do_skip_item > 0 ? "Yes" : "No"}
                                    </div>
                                    <div className="col-span-3">{bot_flow.do_skip_item == 1 && "Yes, if data already chosen"}</div>
                                </div>

                                <div className="grid grid-cols-4">
                                    <div>
                                        <strong>Force Year:</strong> {bot_flow.force_year > 0 ? "Yes" : "No"}
                                    </div>
                                    <div className="col-span-3">{years?.find((year) => year.value === bot_flow.force_year)?.label}</div>
                                </div>

                                <div className="grid grid-cols-4">
                                    <div>
                                        <strong>Do Action:</strong> {bot_flow.do_action === 1 ? "Yes" : "No"}
                                    </div>

                                    {bot_flow.do_action === 1 && (
                                        <div className="col-span-3">
                                            {bot_flow.action_to_do === 1 && (
                                                <>
                                                    <strong>Action to do:</strong> Create Booking - {bot_flow.booking_year && years.find((y) => y.value === bot_flow.booking_year)?.label}
                                                </>
                                            )}
                                            {bot_flow.action_to_do === 2 && (
                                                <>
                                                    <strong>Action to do:</strong> Create Booking and Archive old Booking
                                                </>
                                            )}
                                            {bot_flow.action_to_do === 3 && (
                                                <>
                                                    <strong>Action to do:</strong> Refund - Location Move
                                                </>
                                            )}
                                            {bot_flow.action_to_do === 4 && (
                                                <>
                                                    <strong>Action to do:</strong> Generate and Send AOS
                                                </>
                                            )}
                                            {bot_flow.action_to_do === 5 && (
                                                <>
                                                    <strong>Action to do:</strong> Generate and Send PDF Booking Summary
                                                </>
                                            )}
                                            {bot_flow.action_to_do === 6 && (
                                                <>
                                                    <strong>Action to do:</strong> Keep previous year bed & Generate and Send PDF Booking Summary
                                                </>
                                            )}
                                            {bot_flow.action_to_do === 7 && (
                                                <>
                                                    <strong>Action to do:</strong> Run TPN Check
                                                </>
                                            )}
                                            {bot_flow.action_to_do === 8 && (
                                                <>
                                                    <strong>Action to do:</strong> Biometrics - User Image Flow
                                                </>
                                            )}
                                            {bot_flow.action_to_do === 9 && (
                                                <>
                                                    <strong>Action to do:</strong> Biometrics - Request Guest Flow
                                                </>
                                            )}
                                            {bot_flow.action_to_do === 10 && (
                                                <>
                                                    <strong>Action to do:</strong> Auto Allocate Bed
                                                </>
                                            )}
                                            {bot_flow.action_to_do === 11 && (
                                                <>
                                                    <strong>Action to do:</strong> PayStack Refund
                                                </>
                                            )}
                                            {bot_flow.action_to_do === 12 && (
                                                <>
                                                    <strong>Action to do:</strong> Biometrics - Request Photo
                                                </>
                                            )}
                                            {bot_flow.action_to_do === 13 && (
                                                <>
                                                    <strong>Action to do:</strong> Unlink Bed
                                                </>
                                            )}
                                            {bot_flow.action_to_do === 14 && (
                                                <>
                                                    <strong>Action to do:</strong> Invalidate Lease URL
                                                </>
                                            )}
                                            {bot_flow.action_to_do === 15 && (
                                                <>
                                                    <strong>Action to do:</strong> Generate NSFAS Lease & Invoice
                                                </>
                                            )}
                                        </div>
                                    )}
                                </div>

                                <div className="grid grid-cols-4">
                                    <div>
                                        <strong>Close:</strong> {bot_flow.do_close === 1 ? "Yes" : "No"}
                                    </div>
                                </div>

                                <div className="grid grid-cols-4">
                                    <div>
                                        <strong>Archive:</strong> {bot_flow.do_archive === 1 ? "Yes" : "No"}
                                    </div>
                                </div>

                                <div className="grid grid-cols-4">
                                    <div>
                                        <strong>End Flow:</strong> {bot_flow.end_bot_flow === 1 ? "Yes" : "No"}
                                    </div>
                                </div>

                                <div className="grid grid-cols-4">
                                    <div>
                                        <strong>Message Type:</strong> {bot_flow.message_type == 0 ? "Normal Message" : bot_flow.message_type == 1 ? "List" : "Buttons"}
                                    </div>
                                </div>

                                <div className="mt-4 grid grid-cols-4">
                                    <div>
                                        <strong>Send Message:</strong> {bot_flow.do_send_message > 0 ? "Yes" : "No"}
                                    </div>
                                    <div className="col-span-3">
                                        {bot_flow.bot_type == "Email" && (
                                            <div>
                                                <strong>Email Subject:</strong> {bot_flow.email_subject}
                                            </div>
                                        )}
                                        <div>
                                            <strong>Robot Colour:</strong> {bot_flow.robot_colour === 0 ? "Red" : "Green"}
                                        </div>
                                        <div>
                                            {bot_flow.do_send_message === 1 && (
                                                <>
                                                    <strong>Message:</strong>
                                                    <pre className="text-base">{bot_flow.message_to_send}</pre>
                                                </>
                                            )}
                                            {bot_flow.do_send_message === 2 && (
                                                <>
                                                    <strong>Template:</strong>{" "}
                                                    {(() => {
                                                        const foundTemplate = Object.keys(templates)
                                                            .flatMap(
                                                                (category) =>
                                                                    templates[category]
                                                                        .sort((a, b) => a.sort_order - b.sort_order)
                                                                        .map((template) => ({
                                                                            ...template,
                                                                            category, // Ensure category is added to each template
                                                                        }))
                                                                        .find((template) => template.id === bot_flow.template_to_send) // Find template by ID
                                                            )
                                                            .filter(Boolean)[0]; // Get the first matched template

                                                        return foundTemplate
                                                            ? `${foundTemplate.category} - ${foundTemplate.title}` // Correctly display category and title
                                                            : "Template not found"; // Fallback if no template is found
                                                    })()}
                                                </>
                                            )}
                                            {bot_flow.do_send_message === 3 && (
                                                <>
                                                    <strong>Galllery</strong>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-4 grid grid-cols-4">
                                    <div className="flex justify-start items-start">
                                        <div className="mr-4">
                                            <strong>Button Options:</strong> {bot_flow.bot_flow_options?.length > 0 ? "Yes" : "No"}
                                        </div>
                                        <button onClick={(e) => edit_options(e, bot_flow.id, 0, 0, "add")}>
                                            <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faPlusCircle} />
                                        </button>
                                    </div>

                                    {bot_flow.bot_flow_options?.length > 0 && (
                                        <div className="col-span-3">
                                            {bot_flow.bot_flow_options
                                                ?.sort((a, b) => a.sort_order - b.sort_order)
                                                ?.map((rule) => (
                                                    <div className="flex justify-start items-center space-x-4">
                                                        <div>
                                                            {rule.option_name} - {rule.sort_order}
                                                        </div>
                                                        <button onClick={(e) => edit_options(e, bot_flow.id, 0, rule.id, "edit")}>
                                                            <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faPenCircle} />
                                                        </button>
                                                        <button onClick={(e) => edit_options(e, bot_flow.id, 0, rule.id, "delete")}>
                                                            <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faTrashCircle} />
                                                        </button>
                                                    </div>
                                                ))}
                                        </div>
                                    )}
                                </div>

                                <div className="mt-4 grid grid-cols-4">
                                    <div className="flex justify-start items-start">
                                        <div className="mr-4">
                                            <strong>Rules:</strong> {bot_flow.bot_flow_rules?.length > 0 ? "Yes" : "No"}
                                        </div>

                                        <button onClick={(e) => edit_rules(e, bot_flow.id, 0, 0, "add")}>
                                            <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faPlusCircle} />
                                        </button>
                                    </div>

                                    {bot_flow.bot_flow_rules?.length > 0 && (
                                        <div className="col-span-3">
                                            {bot_flow.bot_flow_rules.map((rule) => (
                                                <div className="mb-4">
                                                    <div className="space-x-2">
                                                        <strong>Incoming Message:</strong> {rule.incoming_message} | {rule.id} | ({rule.status})
                                                        <button onClick={(e) => edit_rules(e, bot_flow.id, 0, rule.id, "edit")}>
                                                            <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faPenCircle} />
                                                        </button>
                                                        <button onClick={(e) => edit_rules(e, bot_flow.id, 0, rule.id, "delete")}>
                                                            <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faTrashCircle} />
                                                        </button>
                                                    </div>
                                                    <div className="grid grid-cols-4">
                                                        <div className="col-span-2">
                                                            <strong>Match Location:</strong> {rule.match_location > 0 ? "Yes" : "No"}
                                                        </div>
                                                        <div className="col-span-2">{rule.match_location === 1 && rule.current_location}</div>
                                                    </div>
                                                    <div className="grid grid-cols-4">
                                                        <div className="col-span-2">
                                                            <strong>Match Funding Type:</strong> {rule.do_match_funding_type > 0 ? "Yes" : "No"}
                                                        </div>
                                                        <div className="col-span-2">
                                                            {rule.do_match_funding_type === 1 && funding_types.find((ft) => ft.value === rule.current_funding_type)?.label}
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-cols-4">
                                                        <div className="col-span-2">
                                                            <strong>Match Property:</strong> {rule.do_match_property > 0 ? "Yes" : "No"}
                                                        </div>
                                                        <div className="col-span-2">{rule.do_match_property === 1 && properties?.find((p) => p.value === rule.current_property_id)?.label}</div>
                                                    </div>
                                                    <div className="grid grid-cols-4">
                                                        <div className="col-span-2">
                                                            <strong>Match Renewal:</strong> {rule.do_match_renewal > 0 ? "Yes" : "No"}
                                                        </div>
                                                        <div className="col-span-2">{rule.do_match_renewal === 2 ? "Renewal" : rule.do_match_renewal === 1 ? "New Lead" : "No Match"}</div>
                                                    </div>
                                                    <div className="grid grid-cols-4">
                                                        <div className="col-span-2">
                                                            <strong>Send Message:</strong> {rule.do_send_message === 1 ? "Yes" : "No"}
                                                        </div>
                                                        <div className="col-span-2">{rule.do_send_message === 1 && rule.message_to_send}</div>
                                                    </div>
                                                    `{" "}
                                                    <div className="grid grid-cols-4">
                                                        <div className="col-span-2">
                                                            <strong>Move Location:</strong> {rule.do_move_chat === 1 ? "Yes" : "No"}
                                                        </div>
                                                        <div className="col-span-2">{rule.do_move_chat === 1 && rule.move_to_location}</div>
                                                    </div>
                                                    <div className="grid grid-cols-4">
                                                        <div>
                                                            <strong>End Flow:</strong> {rule.end_bot_flow == 1 ? "Yes" : "No"}
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-cols-4">
                                                        <div>
                                                            <strong>Do Action:</strong> {rule.do_action == 1 ? "Yes" : "No"}
                                                        </div>

                                                        {rule.do_action == 1 && (
                                                            <div className="col-span-3">
                                                                {rule.action_to_do === 1 && (
                                                                    <>
                                                                        <strong>Action to do:</strong> Create Booking -{" "}
                                                                        {bot_flow.booking_year && years.find((y) => y.value == bot_flow.booking_year)?.label}
                                                                    </>
                                                                )}
                                                                {rule.action_to_do === 2 && (
                                                                    <>
                                                                        <strong>Action to do:</strong> Create Booking and Archive old Booking
                                                                    </>
                                                                )}
                                                                {rule.action_to_do === 3 && (
                                                                    <>
                                                                        <strong>Action to do:</strong> Refund - Location Move
                                                                    </>
                                                                )}
                                                                {rule.action_to_do === 4 && (
                                                                    <>
                                                                        <strong>Action to do:</strong> Generate and Send AOS
                                                                    </>
                                                                )}
                                                                {rule.action_to_do === 5 && (
                                                                    <>
                                                                        <strong>Action to do:</strong> Generate and Send PDF Booking Summary
                                                                    </>
                                                                )}
                                                                {rule.action_to_do === 6 && (
                                                                    <>
                                                                        <strong>Action to do:</strong> Keep previous year bed & Generate and Send PDF Booking Summary
                                                                    </>
                                                                )}
                                                                {rule.action_to_do === 7 && (
                                                                    <>
                                                                        <strong>Action to do:</strong> Run TPN Check
                                                                    </>
                                                                )}
                                                                {rule.action_to_do === 8 && (
                                                                    <>
                                                                        <strong>Action to do:</strong> Biometrics - User Image Flow
                                                                    </>
                                                                )}
                                                                {rule.action_to_do === 9 && (
                                                                    <>
                                                                        <strong>Action to do:</strong> Biometrics - Request Guest Flow
                                                                    </>
                                                                )}
                                                                {rule.action_to_do === 10 && (
                                                                    <>
                                                                        <strong>Action to do:</strong> Auto Allocate Bed
                                                                    </>
                                                                )}
                                                                {rule.action_to_do === 11 && (
                                                                    <>
                                                                        <strong>Action to do:</strong> PayStack Refund
                                                                    </>
                                                                )}
                                                                {bot_flow.action_to_do === 12 && (
                                                                    <>
                                                                        <strong>Action to do:</strong> Biometrics - Request Photo
                                                                    </>
                                                                )}
                                                                {bot_flow.action_to_do === 13 && (
                                                                    <>
                                                                        <strong>Action to do:</strong> Unlink Bed
                                                                    </>
                                                                )}
                                                                {bot_flow.action_to_do === 13 && (
                                                                    <>
                                                                        <strong>Action to do:</strong> Invalidate Lease URL
                                                                    </>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="grid grid-cols-4">
                                                        <div className="col-span-2">
                                                            <strong>Change Flow:</strong> {rule.do_change_bot_flow === 1 ? "Yes" : "No"}
                                                        </div>
                                                        {rule.do_change_bot_flow === 1 && (
                                                            <div className="col-span-2">
                                                                {rule.new_bot_flow_id != isOpen ? (
                                                                    <button className="bg-primary text-white px-4 rounded-full" onClick={() => setIsOpen(rule.new_bot_flow_id)}>
                                                                        {findBotFlowName(rule.new_bot_flow_id)}
                                                                    </button>
                                                                ) : (
                                                                    <span>{findBotFlowName(rule.new_bot_flow_id)}</span>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>

                    {isOpen === bot_flow.id && (
                        <div className="text-center">
                            <button className="bg-primary text-white px-4 py-2 rounded-full mb-8" onClick={(e) => open_popup(e, bot_flow.id, 0, "add")}>
                                Add Flow Inside {bot_flow.name}
                            </button>
                        </div>
                    )}

                    {isOpen === bot_flow.id && (
                        <div className="px-8">
                            {bot_flow.children.map((child) => (
                                <div className="mb-4 bg-gray-100 px-4 py-2">
                                    <div className="flex justify-between">
                                        <div title={child.id}>
                                            <strong>Name:</strong> {child.name} | {child.id} | ({child.status})
                                        </div>

                                        <div className="flex space-x-2 items-center">
                                            <div>{child.sort_order}</div>

                                            <button onClick={(e) => open_popup(e, bot_flow.id, child.id, "edit")}>
                                                <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faPenCircle} />
                                            </button>

                                            <button onClick={(e) => handleDelete(e, child.id)}>
                                                <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faTrashCircle} />
                                            </button>
                                        </div>
                                    </div>

                                    <div className="mt-4 grid grid-cols-4">
                                        <div>
                                            <strong>Match Message:</strong> {child.match_message === 1 ? "Yes" : "No"}
                                        </div>
                                        <div className="col-span-3">{child.incoming_message}</div>
                                    </div>

                                    <div className="grid grid-cols-4">
                                        <div>
                                            <strong>Match Location:</strong> {child.match_location === 1 ? "Yes" : "No"}
                                        </div>
                                        <div className="col-span-3">
                                            {child.current_location}
                                            {child.current_tag_id > 0 && " - (Tag = " + tags.filter((tag) => tag.id === child.current_tag_id)[0]?.name + ")"}
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4">
                                        <div>
                                            <strong>Move Chat:</strong> {child.do_move_chat === 1 ? "Yes" : "No"}
                                        </div>
                                        <div className="col-span-3">
                                            {child.move_to_location}
                                            {child.move_to_tag > 0 && " - (Tag = " + tags.filter((tag) => tag.id === child.move_to_tag)[0]?.name + ")"}
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4">
                                        <div>
                                            <strong>Skip Item:</strong> {child.do_skip_item > 0 ? "Yes" : "No"}
                                        </div>
                                        <div className="col-span-3">{child.do_skip_item == 1 && "Yes, if data already chosen"}</div>
                                    </div>

                                    <div className="grid grid-cols-4">
                                        <div>
                                            <strong>Do Action:</strong> {child.do_action === 1 ? "Yes" : "No"}
                                        </div>

                                        {child.do_action === 1 && (
                                            <div className="col-span-3">
                                                {child.action_to_do === 1 && (
                                                    <>
                                                        <strong>Action to do:</strong> Create Booking - {child.booking_year && years.find((y) => y.value == child.booking_year)?.label}
                                                    </>
                                                )}
                                                {child.action_to_do === 2 && (
                                                    <>
                                                        <strong>Action to do:</strong> Create Booking and Archive old Booking
                                                    </>
                                                )}
                                                {child.action_to_do === 3 && (
                                                    <>
                                                        <strong>Action to do:</strong> Refund - Location Move
                                                    </>
                                                )}
                                                {child.action_to_do === 4 && (
                                                    <>
                                                        <strong>Action to do:</strong> Generate and Send AOS
                                                    </>
                                                )}
                                                {child.action_to_do === 5 && (
                                                    <>
                                                        <strong>Action to do:</strong> Generate and Send PDF Booking Summary
                                                    </>
                                                )}
                                                {child.action_to_do === 6 && (
                                                    <>
                                                        <strong>Action to do:</strong> Keep previous year bed & Generate and Send PDF Booking Summary
                                                    </>
                                                )}
                                                {child.action_to_do === 7 && (
                                                    <>
                                                        <strong>Action to do:</strong> Run TPN Check
                                                    </>
                                                )}
                                                {child.action_to_do === 10 && (
                                                    <>
                                                        <strong>Action to do:</strong> Auto Allocate bed
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </div>

                                    <div className="grid grid-cols-4">
                                        <div>
                                            <strong>Close:</strong> {child.do_close === 1 ? "Yes" : "No"}
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4">
                                        <div>
                                            <strong>Archive:</strong> {child.do_archive === 1 ? "Yes" : "No"}
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4">
                                        <div>
                                            <strong>End Flow:</strong> {child.end_bot_flow === 1 ? "Yes" : "No"}
                                        </div>
                                    </div>

                                    <div className="mt-4 grid grid-cols-4">
                                        <div>
                                            <strong>Send Message:</strong> {child.do_send_message === 1 ? "Yes" : "No"}
                                        </div>
                                        <div className="col-span-3">
                                            <pre>{child.do_send_message === 1 && child.message_to_send}</pre>
                                        </div>
                                    </div>

                                    <div className="mt-4 grid grid-cols-4">
                                        <div className="flex justify-start items-start">
                                            <div className="mr-4">
                                                <strong>Button Options:</strong> {child.bot_flow_options?.length > 0 ? "Yes" : "No"}
                                            </div>
                                            <button onClick={(e) => edit_options(e, bot_flow.id, child.id, 0, "add")}>
                                                <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faPlusCircle} />
                                            </button>
                                        </div>

                                        <div>
                                            {child.bot_flow_options
                                                ?.sort((a, b) => a.sort_order - b.sort_order)
                                                ?.map((rule) => (
                                                    <div className="flex justify-start items-center space-x-4">
                                                        <div>
                                                            {rule.option_name} - {rule.sort_order}
                                                        </div>
                                                        <button onClick={(e) => edit_options(e, bot_flow.id, child.id, rule.id, "edit")}>
                                                            <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faPenCircle} />
                                                        </button>
                                                        <button onClick={(e) => edit_options(e, bot_flow.id, child.id, rule.id, "delete")}>
                                                            <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faTrashCircle} />
                                                        </button>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>

                                    <div className="mt-4 grid grid-cols-4">
                                        <div className="flex justify-start items-start">
                                            <div className="mr-4">
                                                <strong>Rules:</strong> {child.bot_flow_rules?.length > 0 ? "Yes" : "No"}
                                            </div>

                                            <button onClick={(e) => edit_rules(e, bot_flow.id, child.id, 0, "add")}>
                                                <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faPlusCircle} />
                                            </button>
                                        </div>

                                        {child.bot_flow_rules?.length > 0 && (
                                            <div className="col-span-3">
                                                {child.bot_flow_rules?.map((rule) => (
                                                    <div className="mb-4">
                                                        <div className="space-x-2">
                                                            <strong>Incoming Message:</strong> {rule.incoming_message} | {rule.id} | ({rule.status})
                                                            <button onClick={(e) => edit_rules(e, bot_flow.id, child.id, rule.id, "edit")}>
                                                                <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faPenCircle} />
                                                            </button>
                                                            <button onClick={(e) => edit_rules(e, bot_flow.id, child.id, rule.id, "delete")}>
                                                                <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faTrashCircle} />
                                                            </button>
                                                        </div>
                                                        <div className="grid grid-cols-4">
                                                            <div className="col-span-2">
                                                                <strong>Match Location:</strong> {rule.match_location > 0 ? "Yes" : "No"}
                                                            </div>
                                                            <div className="col-span-2">{rule.match_location === 1 && rule.current_location}</div>
                                                        </div>
                                                        <div className="grid grid-cols-4">
                                                            <div className="col-span-2">
                                                                <strong>Match Funding Type:</strong> {rule.do_match_funding_type > 0 ? "Yes" : "No"}
                                                            </div>
                                                            <div className="col-span-2">
                                                                {rule.do_match_funding_type === 1 && funding_types.find((ft) => ft.value == rule.current_funding_type)?.label}
                                                            </div>
                                                        </div>
                                                        <div className="grid grid-cols-4">
                                                            <div className="col-span-2">
                                                                <strong>Match Property:</strong> {rule.do_match_property > 0 ? "Yes" : "No"}
                                                            </div>
                                                            <div className="col-span-2">{rule.do_match_property == 1 && properties?.find((p) => p.value == rule.current_property_id)?.label}</div>
                                                        </div>
                                                        <div className="grid grid-cols-4">
                                                            <div className="col-span-2">
                                                                <strong>Send Message:</strong> {rule.do_send_message === 1 ? "Yes" : "No"}
                                                            </div>
                                                            <div className="col-span-2">{rule.do_send_message === 1 && rule.message_to_send}</div>
                                                        </div>
                                                        <div className="grid grid-cols-4">
                                                            <div className="col-span-2">
                                                                <strong>Move Location:</strong> {rule.do_move_chat === 1 ? "Yes" : "No"}
                                                            </div>
                                                            <div className="col-span-2">{rule.do_move_chat === 1 && rule.move_to_location}</div>
                                                        </div>
                                                        <div className="grid grid-cols-4">
                                                            <div>
                                                                <strong>End Flow:</strong> {rule.end_bot_flow === 1 ? "Yes" : "No"}
                                                            </div>
                                                        </div>
                                                        <div className="grid grid-cols-4">
                                                            <div className="col-span-2">
                                                                <strong>Do Action:</strong> {rule.do_action == 1 ? "Yes" : "No"}
                                                            </div>

                                                            {rule.do_action === 1 && (
                                                                <div className="col-span-2">
                                                                    {rule.action_to_do === 1 && (
                                                                        <>
                                                                            <strong>Action to do:</strong> Create Booking -{" "}
                                                                            {rule.booking_year && years.find((y) => y.value == rule.booking_year)?.label}
                                                                        </>
                                                                    )}
                                                                    {rule.action_to_do == 2 && (
                                                                        <>
                                                                            <strong>Action to do:</strong> Create Booking and Archive old Booking
                                                                        </>
                                                                    )}
                                                                    {rule.action_to_do == 3 && (
                                                                        <>
                                                                            <strong>Action to do:</strong> Refund - Location Move
                                                                        </>
                                                                    )}
                                                                    {rule.action_to_do == 4 && (
                                                                        <>
                                                                            <strong>Action to do:</strong> Generate and Send AOS
                                                                        </>
                                                                    )}
                                                                    {rule.action_to_do == 5 && (
                                                                        <>
                                                                            <strong>Action to do:</strong> Generate and Send PDF Booking Summary
                                                                        </>
                                                                    )}
                                                                    {rule.action_to_do == 6 && (
                                                                        <>
                                                                            <strong>Action to do:</strong> Keep previous year bed & Generate and Send PDF Booking Summary
                                                                        </>
                                                                    )}
                                                                    {rule.action_to_do == 7 && (
                                                                        <>
                                                                            <strong>Action to do:</strong> Run TPN Check
                                                                        </>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="grid grid-cols-4">
                                                            <div className="col-span-2">
                                                                <strong>Change Flow:</strong> {rule.do_change_bot_flow === 1 ? "Yes" : "No"}
                                                            </div>
                                                            {rule.do_change_bot_flow === 1 && (
                                                                <div className="col-span-2">
                                                                    {rule.new_bot_flow_id != isOpen ? (
                                                                        <button className="bg-primary text-white px-4 rounded-full" onClick={() => setIsOpen(rule.new_bot_flow_id)}>
                                                                            {findBotFlowName(rule.new_bot_flow_id)}
                                                                        </button>
                                                                    ) : (
                                                                        <span>{findBotFlowName(rule.new_bot_flow_id)}</span>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}

                            {isOpen === bot_flow.id && (
                                <div className="text-center">
                                    <button className="bg-primary text-white px-4 py-2 rounded-full mb-8" onClick={(e) => open_popup(e, bot_flow.id, 0, "add")}>
                                        Add Flow Inside {bot_flow.name}
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            ))}

            <div className="h-1 mb-20"></div>

            <Transition.Root show={openPopup === 1} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => {}}>
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                                    <Formik
                                        initialValues={initialValues}
                                        enableReinitialize="true"
                                        onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                            setSubmitting(true);

                                            const formData = new FormData();
                                            for (const key in values) {
                                                if (values.hasOwnProperty(key)) {
                                                    formData.append(key, values[key]);
                                                }
                                            }

                                            // Append file if it exists
                                            if (values.file) {
                                                formData.append("file", values.file);
                                            }

                                            await axios({
                                                method: "post",
                                                url: `${process.env.REACT_APP_API_URL}save_bot_flow`,
                                                data: formData,
                                            })
                                                .then((resp) => {
                                                    axios({
                                                        method: "get",
                                                        url: `${process.env.REACT_APP_API_URL}get_bot_flows`,
                                                    })
                                                        .then((response) => {
                                                            setBotFlows(response.data[0]);
                                                            setYears(response.data[1]);
                                                            setLoading(false);
                                                        })
                                                        .catch((error) => {
                                                            alert("Failed to get bot flows");
                                                            setLoading(false);
                                                        });
                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                    console.log(error.response);
                                                })
                                                .finally(() => {
                                                    setSubmitting(false);
                                                    setOpenPopup(false);
                                                });
                                        }}
                                    >
                                        {({ values, touched, errors, setFieldValue, setFieldTouched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
                                            <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4">
                                                <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                    <div className="space-y-4">
                                                        <div className="grid grid-cols-4 gap-4">
                                                            <div>
                                                                <Field name="name" type="text" as={SettingsTextInput} label="Name" />
                                                            </div>
                                                            <div>
                                                                <SettingsSelect
                                                                    label="Status"
                                                                    name="status"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.status}
                                                                    touched={touched.status}
                                                                    error={errors.status}
                                                                    options={[
                                                                        { value: "Active", label: "Active" },
                                                                        { value: "Disabled", label: "Disabled" },
                                                                    ]}
                                                                />
                                                            </div>
                                                            <div>
                                                                <SettingsSelect
                                                                    label="Bot Type"
                                                                    name="bot_type"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.bot_type}
                                                                    touched={touched.bot_type}
                                                                    error={errors.bot_type}
                                                                    options={[
                                                                        { value: "Whatsapp", label: "Whatsapp" },
                                                                        { value: "Email", label: "Email" },
                                                                    ]}
                                                                />
                                                            </div>
                                                            <div>
                                                                <SettingsSelect
                                                                    label="Trigger"
                                                                    name="action"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.action}
                                                                    touched={touched.action}
                                                                    error={errors.action}
                                                                    options={[
                                                                        { value: "All", label: "All" },
                                                                        {
                                                                            value: "First Incoming Email",
                                                                            label: "First Incoming Email",
                                                                        },
                                                                        {
                                                                            value: "Match Email Subject",
                                                                            label: "Match Email Subject",
                                                                        },
                                                                        { value: "Move Chat", label: "Move Chat" },
                                                                        {
                                                                            value: "Student Manually Chooses Bed",
                                                                            label: "Student Manually Chooses Bed",
                                                                        },
                                                                        {
                                                                            value: "Ozow Booking Fee Paid",
                                                                            label: "Ozow Booking Fee Paid",
                                                                        },
                                                                        {
                                                                            value: "Ozow Booking Fee Failed",
                                                                            label: "Ozow Booking Fee Failed",
                                                                        },
                                                                        {
                                                                            value: "Ozow Paid",
                                                                            label: "Ozow Paid",
                                                                        },
                                                                        {
                                                                            value: "Ozow Failed",
                                                                            label: "Ozow Failed",
                                                                        },
                                                                        { value: "Missed Call", label: "Missed Call" },
                                                                        { value: "Lease Signed", label: "Lease Signed" },
                                                                        {
                                                                            value: "Bank Details Update",
                                                                            label: "Bank Details Update",
                                                                        },
                                                                        {
                                                                            value: "Appointment Requested",
                                                                            label: "Appointment Requested",
                                                                        },
                                                                        {
                                                                            value: "Appointment Approved",
                                                                            label: "Appointment Approved",
                                                                        },{
                                                                            value: "Appointment Cancelled",
                                                                            label: "Appointment Cancelled",
                                                                        },
                                                                        {
                                                                            value: "Sign Document",
                                                                            label: "Sign Document",
                                                                        },
                                                                        {
                                                                            value: "Move In Inspection",
                                                                            label: "Move In Inspection",
                                                                        },
                                                                        {
                                                                            value: "Move Out Inspection",
                                                                            label: "Move Out Inspection",
                                                                        },
                                                                        {
                                                                            value: "Hik Face Scan",
                                                                            label: "Hik Face Scan",
                                                                        },{
                                                                            value: "Hik Add Photo",
                                                                            label: "Hik Add Photo",
                                                                        },
                                                                        {
                                                                            value: "Payfast Paid",
                                                                            label: "Payfast Paid",
                                                                        },
                                                                        {
                                                                            value: "Payfast Failed",
                                                                            label: "Payfast Failed",
                                                                        },
                                                                        {
                                                                            value: "Check In Form Saved",
                                                                            label: "Check In Form Saved",
                                                                        },
                                                                        {
                                                                            value: "Mda Tenant Created",
                                                                            label: "Mda Tenant Created",
                                                                        },
                                                                        {
                                                                            value: "Mda Lease Created",
                                                                            label: "Mda Lease Created",
                                                                        },
                                                                        {
                                                                            value: "Move In Form Signed",
                                                                            label: "Move In Form Signed",
                                                                        },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="grid grid-cols-5 gap-4">
                                                            <div>
                                                                <Field name="sort_order" type="text" as={SettingsTextInput} label="Sort Order" />
                                                            </div>

                                                            <div>
                                                                <Field name="retrigger_limit" type="text" as={SettingsTextInput} label="Retrigger Limit" placeholder="Minutes" />
                                                            </div>

                                                            <div>
                                                                <SettingsSelect
                                                                    label="Close Chat"
                                                                    name="do_close"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.do_close}
                                                                    touched={touched.do_close}
                                                                    error={errors.do_close}
                                                                    options={[
                                                                        { value: 0, label: "No" },
                                                                        { value: 1, label: "Yes" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            <div>
                                                                <SettingsSelect
                                                                    label="Archive Chat"
                                                                    name="do_archive"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.do_archive}
                                                                    touched={touched.do_archive}
                                                                    error={errors.do_archive}
                                                                    options={[
                                                                        { value: 0, label: "No" },
                                                                        { value: 1, label: "Yes" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            <div>
                                                                <SettingsSelect
                                                                    label="End Bot Flow"
                                                                    name="end_bot_flow"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.end_bot_flow}
                                                                    touched={touched.end_bot_flow}
                                                                    error={errors.end_bot_flow}
                                                                    options={[
                                                                        { value: 0, label: "No" },
                                                                        { value: 1, label: "Yes" },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>

                                                        {values.action === "Sign Document" && (
                                                            <div className="grid grid-cols-2 gap-4">
                                                                <div>
                                                                    <SettingsSelect
                                                                        label="Match Document"
                                                                        name="match_document"
                                                                        onChange={setFieldValue}
                                                                        onBlur={setFieldTouched}
                                                                        value={values.match_document}
                                                                        touched={touched.match_document}
                                                                        error={errors.match_document}
                                                                        options={[
                                                                            { value: 0, label: "Choose a Document" },
                                                                            ...documentTemplates.map((doc) => ({
                                                                                value: doc.id,
                                                                                label: doc.name,
                                                                            })),
                                                                        ]}
                                                                        required
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}

                                                        {(values.action === "Ozow Paid" || values.action === "Payfast Paid") && (
                                                            <div className="grid grid-cols-2 gap-4">
                                                                <div>
                                                                    <Field name="action_match" type="text" as={SettingsTextInput} label="Payment Module" />
                                                                </div>
                                                            </div>
                                                        )}

                                                        <div className="grid grid-cols-2 gap-4">
                                                            <div>
                                                                <Field name="button_name" type="text" as={SettingsTextInput} label="Button Name" />
                                                            </div>
                                                        </div>

                                                        <div className="grid grid-cols-2 gap-4">
                                                            <div>
                                                                <SettingsSelect
                                                                    label="Match Incoming Message"
                                                                    name="match_message"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.match_message}
                                                                    touched={touched.match_message}
                                                                    error={errors.match_message}
                                                                    options={[
                                                                        { value: 0, label: "No" },
                                                                        { value: 1, label: "Yes" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            {values.match_message == 1 && (
                                                                <div>
                                                                    <Field name="incoming_message" type="text" as={SettingsTextInput} label="Incoming Message" />
                                                                </div>
                                                            )}
                                                        </div>

                                                        <div className="grid grid-cols-2 gap-4">
                                                            <div>
                                                                <SettingsSelect
                                                                    label="Match Current Location"
                                                                    name="match_location"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.match_location}
                                                                    touched={touched.match_location}
                                                                    error={errors.match_location}
                                                                    options={[
                                                                        { value: 0, label: "No" },
                                                                        { value: 1, label: "Yes" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            {values.match_location == 1 && (
                                                                <div className="grid grid-cols-2">
                                                                    <div>
                                                                        <SettingsSelect
                                                                            label="Current Location"
                                                                            name="current_location"
                                                                            onChange={setFieldValue}
                                                                            onBlur={setFieldTouched}
                                                                            value={values.current_location}
                                                                            touched={touched.current_location}
                                                                            error={errors.current_location}
                                                                            options={locations}
                                                                        />
                                                                    </div>

                                                                    {(values.current_location === "Residents" ||
                                                                        values.current_location === "Historical" ||
                                                                        values.current_location === "Management 2023") && (
                                                                        <div>
                                                                            <label htmlFor="current_location" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline mx-2">
                                                                                Current Tag
                                                                            </label>
                                                                            <Field
                                                                                as="select"
                                                                                name="current_tag_id"
                                                                                className="capitalize cursor-pointer relative p-2 mx-2 bg-light_background rounded-md transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
                                                                            >
                                                                                {values.current_location === "Residents" && <option value="0">No Tag</option>}

                                                                                {tags
                                                                                    .filter(
                                                                                        (tag) =>
                                                                                            tag.parent_folder === values.current_location ||
                                                                                            (tag.parent_folder === "Historical" && values.current_location == "Management 2023")
                                                                                    )
                                                                                    .map((tag) => (
                                                                                        <option
                                                                                            key={tag.id}
                                                                                            value={tag.id}
                                                                                            className={classNames(tag.id === values.current_tag_id ? "text-white bg-primary" : "text-black")}
                                                                                        >
                                                                                            {tag.name}
                                                                                        </option>
                                                                                    ))}
                                                                            </Field>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>

                                                        <div className="grid grid-cols-2 gap-4">
                                                            <div>
                                                                <SettingsSelect
                                                                    label="Move Chat"
                                                                    name="do_move_chat"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.do_move_chat}
                                                                    touched={touched.do_move_chat}
                                                                    error={errors.do_move_chat}
                                                                    options={[
                                                                        { value: 0, label: "No" },
                                                                        { value: 1, label: "Yes" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            {values.do_move_chat == 1 && (
                                                                // <div>
                                                                //     <Field name="move_to_location" type="text" as={SettingsTextInput} label="Location to Move To" />
                                                                // </div>

                                                                <div className="grid grid-cols-2">
                                                                    <div>
                                                                        <label htmlFor="move_to_location" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline mx-2">
                                                                            Location to Move To
                                                                        </label>
                                                                        <Field
                                                                            as="select"
                                                                            name="move_to_location"
                                                                            className="capitalize cursor-pointer relative p-2 mx-2 bg-light_background rounded-md transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
                                                                            onChange={(e) => {
                                                                                setFieldValue("move_to_location", e.target.value);
                                                                                // Reset tag when location changes
                                                                                setFieldValue("move_to_tag", "");
                                                                            }}
                                                                        >
                                                                            <option value="">Select a location</option>

                                                                            {/* Display locations with no tags */}
                                                                            {locations.noTags.length > 0 && (
                                                                                <optgroup label="Folders">
                                                                                    {locations.noTags.map((location) => (
                                                                                        <option
                                                                                            key={location.id}
                                                                                            value={location.name}
                                                                                            className={classNames(
                                                                                                location.name === values.move_to_location ? "text-white bg-primary" : "text-black"
                                                                                            )}
                                                                                        >
                                                                                            {location.name}
                                                                                        </option>
                                                                                    ))}
                                                                                </optgroup>
                                                                            )}

                                                                            {/* Display locations with tags */}
                                                                            {locations.hasTags.length > 0 && (
                                                                                <optgroup label="Folders with Tags">
                                                                                    {locations.hasTags.map((location) => (
                                                                                        <option
                                                                                            key={location.id}
                                                                                            value={location.name}
                                                                                            className={classNames(
                                                                                                location.name === values.move_to_location ? "text-white bg-primary" : "text-black"
                                                                                            )}
                                                                                        >
                                                                                            {location.name}
                                                                                        </option>
                                                                                    ))}
                                                                                </optgroup>
                                                                            )}
                                                                        </Field>
                                                                    </div>

                                                                    {/* Only show tags dropdown if selected location is in the hasTags list */}
                                                                    {values.move_to_location && locations.hasTags.some(loc => loc.name === values.move_to_location) && (
                                                                        <div>
                                                                            <label htmlFor="move_to_tag" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline mx-2">
                                                                                Tag
                                                                            </label>
                                                                            <Field
                                                                                as="select"
                                                                                name="move_to_tag"
                                                                                className="capitalize cursor-pointer relative p-2 mx-2 bg-light_background rounded-md transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
                                                                            >
                                                                                <option value="0">No Tag</option>
                                                                                {getTagsForLocation(values.move_to_location).map((tag) => (
                                                                                    <option
                                                                                        key={tag.id}
                                                                                        value={tag.id}
                                                                                        className={classNames(
                                                                                            tag.id === values.move_to_tag ? "text-white bg-primary" : "text-black"
                                                                                        )}
                                                                                    >
                                                                                        {tag.name}
                                                                                    </option>
                                                                                ))}
                                                                            </Field>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>

                                                        <div className="grid grid-cols-2 gap-4">
                                                            <div>
                                                                <SettingsSelect
                                                                    label="Move Linked Chat"
                                                                    name="do_move_linked_chat"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.do_move_linked_chat}
                                                                    touched={touched.do_move_linked_chat}
                                                                    error={errors.do_move_linked_chat}
                                                                    options={[
                                                                        { value: 0, label: "No" },
                                                                        { value: 1, label: "Yes" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            {values.do_move_linked_chat === 1 && (
                                                                <div className="grid grid-cols-2">
                                                                    <div>
                                                                        <label htmlFor="move_linked_to_location" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline mx-2">
                                                                            Location to Move To
                                                                        </label>
                                                                        <Field
                                                                            as="select"
                                                                            name="move_linked_to_location"
                                                                            className="capitalize cursor-pointer relative p-2 mx-2 bg-light_background rounded-md transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
                                                                            onChange={(e) => {
                                                                                setFieldValue("move_linked_to_location", e.target.value);
                                                                                // Reset tag when location changes
                                                                                setFieldValue("move_linked_to_tag", "");
                                                                            }}
                                                                        >
                                                                            <option value="">Select a location</option>

                                                                            {/* Display locations with no tags */}
                                                                            {locations.noTags.length > 0 && (
                                                                                <optgroup label="Folders">
                                                                                    {locations.noTags.map((location) => (
                                                                                        <option
                                                                                            key={location.id}
                                                                                            value={location.name}
                                                                                            className={classNames(
                                                                                                location.name === values.move_linked_to_location ? "text-white bg-primary" : "text-black"
                                                                                            )}
                                                                                        >
                                                                                            {location.name}
                                                                                        </option>
                                                                                    ))}
                                                                                </optgroup>
                                                                            )}

                                                                            {/* Display locations with tags */}
                                                                            {locations.hasTags.length > 0 && (
                                                                                <optgroup label="Folders with Tags">
                                                                                    {locations.hasTags.map((location) => (
                                                                                        <option
                                                                                            key={location.id}
                                                                                            value={location.name}
                                                                                            className={classNames(
                                                                                                location.name === values.move_linked_to_location ? "text-white bg-primary" : "text-black"
                                                                                            )}
                                                                                        >
                                                                                            {location.name}
                                                                                        </option>
                                                                                    ))}
                                                                                </optgroup>
                                                                            )}
                                                                        </Field>
                                                                    </div>

                                                                    {/* Only show tags dropdown if selected location is in the hasTags list */}
                                                                    {values.move_linked_to_location && locations.hasTags.some(loc => loc.name === values.move_linked_to_location) && (
                                                                        <div>
                                                                            <label htmlFor="move_linked_to_tag" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline mx-2">
                                                                                Tag
                                                                            </label>
                                                                            <Field
                                                                                as="select"
                                                                                name="move_linked_to_tag"
                                                                                className="capitalize cursor-pointer relative p-2 mx-2 bg-light_background rounded-md transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
                                                                            >
                                                                                <option value="0">No Tag</option>
                                                                                {getTagsForLocation(values.move_linked_to_location).map((tag) => (
                                                                                    <option
                                                                                        key={tag.id}
                                                                                        value={tag.id}
                                                                                        className={classNames(
                                                                                            tag.id === values.move_linked_to_tag ? "text-white bg-primary" : "text-black"
                                                                                        )}
                                                                                    >
                                                                                        {tag.name}
                                                                                    </option>
                                                                                ))}
                                                                            </Field>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>

                                                        <div className="grid grid-cols-2 gap-4">
                                                            <div>
                                                                <SettingsSelect
                                                                    label="Skip Item"
                                                                    name="do_skip_item"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.do_skip_item}
                                                                    touched={touched.do_skip_item}
                                                                    error={errors.do_skip_item}
                                                                    options={[
                                                                        { value: 0, label: "No" },
                                                                        {
                                                                            value: 1,
                                                                            label: "Yes, if data already chosen",
                                                                        },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="grid grid-cols-2 gap-4">
                                                            <div>
                                                                <SettingsSelect
                                                                    label="Force Booking Year"
                                                                    name="force_year"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.force_year}
                                                                    touched={touched.force_year}
                                                                    error={errors.force_year}
                                                                    options={[{ value: 0, label: "No" }, ...years]}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="grid grid-cols-2 gap-4">
                                                            <div>
                                                                <SettingsSelect
                                                                    label="Update Data"
                                                                    name="do_update_data"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.do_update_data}
                                                                    touched={touched.do_update_data}
                                                                    error={errors.do_update_data}
                                                                    options={[
                                                                        { value: 0, label: "No" },
                                                                        { value: 1, label: "Yes" },
                                                                    ]}
                                                                />
                                                                {values.do_update_data === 1 && values.end_bot_flow == 1 && (
                                                                    <p className="text-red-500 bold">Data will not save if you have end bot flow selected!</p>
                                                                )}
                                                            </div>
                                                            {values.do_update_data === 1 && (
                                                                <div>
                                                                    <SettingsSelect
                                                                        label="Field to Update"
                                                                        name="field_to_update"
                                                                        onChange={setFieldValue}
                                                                        onBlur={setFieldTouched}
                                                                        value={values.field_to_update}
                                                                        touched={touched.field_to_update}
                                                                        error={errors.field_to_update}
                                                                        options={[
                                                                            { value: "", label: "None" },
                                                                            {
                                                                                value: "bookings.current_funding_type",
                                                                                label: "Bookings -> Student Type",
                                                                            },
                                                                            {
                                                                                value: "bookings.budget_id",
                                                                                label: "Bookings -> Budget",
                                                                            },
                                                                            {
                                                                                value: "bookings.property_id",
                                                                                label: "Bookings -> Property",
                                                                            },
                                                                            {
                                                                                value: "bookings.unit_type",
                                                                                label: "Bookings -> Unit Type",
                                                                            },
                                                                            {
                                                                                value: "bookings.duration",
                                                                                label: "Bookings -> Duration",
                                                                            },

                                                                            {
                                                                                value: "custom.move_in_date",
                                                                                label: "Custom -> Move In Date",
                                                                            },

                                                                            {
                                                                                value: "user_profile.gender",
                                                                                label: "User Profile -> Gender",
                                                                            },
                                                                            {
                                                                                value: "user_profile.id_number",
                                                                                label: "User Profile -> ID Number",
                                                                            },
                                                                            {
                                                                                value: "user_profile.passport",
                                                                                label: "User Profile -> Passport Number",
                                                                            },
                                                                            {
                                                                                value: "user_profile.date_of_birth",
                                                                                label: "User Profile -> Date of Birth",
                                                                            },
                                                                            {
                                                                                value: "user_profile.institution_campus",
                                                                                label: "User Profile -> Campus",
                                                                            },
                                                                            {
                                                                                value: "user_profile.institution_study",
                                                                                label: "User Profile -> Institution",
                                                                            },
                                                                            {
                                                                                value: "user_profile.study_year",
                                                                                label: "User Profile -> Study Year",
                                                                            },
                                                                            {
                                                                                value: "user_profile.student_number",
                                                                                label: "User Profile -> Student Number",
                                                                            },

                                                                            {
                                                                                value: "users.name",
                                                                                label: "Users -> Name",
                                                                            },
                                                                            {
                                                                                value: "users.surname",
                                                                                label: "Users -> Surname",
                                                                            },
                                                                            {
                                                                                value: "users.cell",
                                                                                label: "Users -> Cell",
                                                                            },

                                                                            {
                                                                                value: "user_profile.banking_bank",
                                                                                label: "Bank Details -> Bank",
                                                                            },
                                                                            {
                                                                                value: "user_profile.banking_account_type",
                                                                                label: "Bank Details -> Account Type",
                                                                            },
                                                                            {
                                                                                value: "user_profile.banking_branch",
                                                                                label: "Bank Details -> Branch",
                                                                            },
                                                                            {
                                                                                value: "user_profile.banking_bank_code",
                                                                                label: "Bank Details -> Branch Code",
                                                                            },
                                                                            {
                                                                                value: "user_profile.banking_account_number",
                                                                                label: "Bank Details -> Account Number",
                                                                            },
                                                                            {
                                                                                value: "user_profile.banking_account_holder",
                                                                                label: "Bank Details -> Account Holder",
                                                                            },

                                                                            {
                                                                                value: "user_affordability.gross_salary",
                                                                                label: "Affordability -> Gross Salary",
                                                                            },

                                                                            {
                                                                                value: "user_next_of_kin.name",
                                                                                label: "Emergency Contact -> Name",
                                                                            },
                                                                            {
                                                                                value: "user_next_of_kin.surname",
                                                                                label: "Emergency Contact -> Surname",
                                                                            },
                                                                            {
                                                                                value: "user_next_of_kin.email",
                                                                                label: "Emergency Contact -> Email",
                                                                            },
                                                                            {
                                                                                value: "user_next_of_kin.contact",
                                                                                label: "Emergency Contact -> Cell",
                                                                            },
                                                                            {
                                                                                value: "user_next_of_kin.relationship",
                                                                                label: "Emergency Contact -> Relationship",
                                                                            },
                                                                            {
                                                                                value: "user_next_of_kin.address",
                                                                                label: "Emergency Contact -> Address",
                                                                            },

                                                                            {
                                                                                value: "user_parent.name",
                                                                                label: "Responsible for Funding -> Name",
                                                                            },
                                                                            {
                                                                                value: "user_parent.surname",
                                                                                label: "Responsible for Funding -> Surname",
                                                                            },
                                                                            {
                                                                                value: "user_parent.email",
                                                                                label: "Responsible for Funding -> Email",
                                                                            },
                                                                            {
                                                                                value: "user_parent.cell",
                                                                                label: "Responsible for Funding -> Cell",
                                                                            },
                                                                            {
                                                                                value: "user_parent.id_number",
                                                                                label: "Responsible for Funding -> ID Number",
                                                                            },
                                                                            {
                                                                                value: "custom_data",
                                                                                label: "Custom Data",
                                                                            },
                                                                        ]}
                                                                    />
                                                                </div>
                                                            )}

                                                            {values.field_to_update === "custom_data" && (
                                                                <div>
                                                                    <Field name="custom_data" type="text" as={SettingsTextInput} label="Custom Data Key" />
                                                                </div>
                                                            )}
                                                        </div>

                                                        <div className="grid grid-cols-2 gap-4">
                                                            <div>
                                                                <SettingsSelect
                                                                    label="Upload File"
                                                                    name="do_upload_file"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.do_upload_file}
                                                                    touched={touched.do_upload_file}
                                                                    error={errors.do_upload_file}
                                                                    options={[
                                                                        { value: 0, label: "No" },
                                                                        { value: 1, label: "Yes" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            {values.do_upload_file === 1 && (
                                                                <div>
                                                                    <Field name="rename_file" type="text" as={SettingsTextInput} label="Rename File" />
                                                                </div>
                                                            )}
                                                        </div>

                                                        <div className="grid grid-cols-3 gap-4">
                                                            <div>
                                                                <SettingsSelect
                                                                    label="Do Action"
                                                                    name="do_action"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.do_action}
                                                                    touched={touched.do_action}
                                                                    error={errors.do_action}
                                                                    options={[
                                                                        { value: 0, label: "No" },
                                                                        { value: 1, label: "Yes" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            {values.do_action == 1 && (
                                                                <div>
                                                                    <SettingsSelect
                                                                        label="Action to do"
                                                                        name="action_to_do"
                                                                        onChange={setFieldValue}
                                                                        onBlur={setFieldTouched}
                                                                        value={values.action_to_do}
                                                                        touched={touched.action_to_do}
                                                                        error={errors.action_to_do}
                                                                        options={[
                                                                            {
                                                                                value: 1,
                                                                                label: "Create a Booking (Old Booking Active)",
                                                                            },
                                                                            {
                                                                                value: 2,
                                                                                label: "Create a Booking and Archive old Booking",
                                                                            },
                                                                            { value: 3, label: "Refund - Location Move" },
                                                                            { value: 4, label: "Geneate & Email AOS" },
                                                                            {
                                                                                value: 5,
                                                                                label: "Generate and Send PDF Booking Summary",
                                                                            },
                                                                            {
                                                                                value: 6,
                                                                                label: "Keep previous year bed & Generate and Send PDF Booking Summary",
                                                                            },
                                                                            { value: 7, label: "Run TPN Check" },
                                                                            {
                                                                                value: 8,
                                                                                label: "Biometrics - User Image Flow",
                                                                            },
                                                                            {
                                                                                value: 9,
                                                                                label: "Biometrics - Request Guest Flow",
                                                                            },
                                                                            {
                                                                                value: 10,
                                                                                label: "Auto Allocate Bed",
                                                                            },
                                                                            {
                                                                                value: 11,
                                                                                label: "PayStack Refund",
                                                                            },
                                                                            {
                                                                                value: 12,
                                                                                label: "Biometrics - Request Photo",
                                                                            },
                                                                            {
                                                                                value: 13,
                                                                                label: "Unlink Bed",
                                                                            },
                                                                            {
                                                                                value: 14,
                                                                                label: "Invalidate Lease URL",
                                                                            },
                                                                            {
                                                                                value: 15,
                                                                                label: "Generate NSFAS Lease & Invoice",
                                                                            },
                                                                        ]}
                                                                    />
                                                                </div>
                                                            )}

                                                            {values.do_action == 1 && (values.action_to_do == 1 || values.action_to_do == 2) && (
                                                                <div>
                                                                    <SettingsSelect
                                                                        label="Year"
                                                                        name="booking_year"
                                                                        onChange={setFieldValue}
                                                                        onBlur={setFieldTouched}
                                                                        value={values.booking_year}
                                                                        touched={touched.booking_year}
                                                                        error={errors.booking_year}
                                                                        options={years}
                                                                    />
                                                                </div>
                                                            )}

                                                            {values.do_action == 1 && values.action_to_do == 11 && (
                                                                <div>
                                                                    <Field name="paystack_refund" type="text" as={SettingsTextInput} label="Paystack Refund" />
                                                                </div>
                                                            )}
                                                        </div>

                                                        {values.do_action == 1 && (values.action_to_do == 1 || values.action_to_do == 2) && (
                                                            <div className="grid grid-cols-3 gap-4">
                                                                <div>
                                                                    <SettingsSelect
                                                                        label="Move New Booking"
                                                                        name="do_move_booking"
                                                                        onChange={setFieldValue}
                                                                        onBlur={setFieldTouched}
                                                                        value={values.do_move_booking}
                                                                        touched={touched.do_move_booking}
                                                                        error={errors.do_move_booking}
                                                                        options={[
                                                                            { value: 0, label: "No" },
                                                                            { value: 1, label: "Yes" },
                                                                        ]}
                                                                    />
                                                                </div>

                                                                {values.do_move_booking == 1 && (
                                                                    <div className="col-span-2 grid grid-cols-2">
                                                                        <div>
                                                                            <SettingsSelect
                                                                                label="Location to Move To"
                                                                                name="move_booking_to_location"
                                                                                onChange={setFieldValue}
                                                                                onBlur={setFieldTouched}
                                                                                value={values.move_booking_to_location}
                                                                                touched={touched.move_booking_to_location}
                                                                                error={errors.move_booking_to_location}
                                                                                options={locations}
                                                                            />
                                                                        </div>

                                                                        {(values.move_booking_to_location === "Residents" ||
                                                                            values.move_booking_to_location === "Historical" ||
                                                                            values.move_booking_to_location === "Management 2023") && (
                                                                            <div>
                                                                                <label htmlFor="move_booking_to_location" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline mx-2">
                                                                                    Move to Tag
                                                                                </label>
                                                                                <Field
                                                                                    as="select"
                                                                                    name="move_booking_to_tag"
                                                                                    className="capitalize cursor-pointer relative p-2 mx-2 bg-light_background rounded-md transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
                                                                                >
                                                                                    {values.move_booking_to_location === "Residents" && <option value="0">No Tag</option>}

                                                                                    {tags
                                                                                        .filter(
                                                                                            (tag) =>
                                                                                                tag.parent_folder === values.move_booking_to_location ||
                                                                                                (tag.parent_folder === "Historical" && values.move_booking_to_location == "Management 2023")
                                                                                        )
                                                                                        .map((tag) => (
                                                                                            <option
                                                                                                key={tag.id}
                                                                                                value={tag.id}
                                                                                                className={classNames(tag.id === values.move_booking_to_tag ? "text-white bg-primary" : "text-black")}
                                                                                            >
                                                                                                {tag.name}
                                                                                            </option>
                                                                                        ))}
                                                                                </Field>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}

                                                        <div className="grid grid-cols-3 gap-4">
                                                            <div>
                                                                <SettingsSelect
                                                                    label="Change Bot Flow (Happens after the message is sent)"
                                                                    name="do_change_bot_flow"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.do_change_bot_flow}
                                                                    touched={touched.do_change_bot_flow}
                                                                    error={errors.do_change_bot_flow}
                                                                    options={[
                                                                        { value: 0, label: "No" },
                                                                        { value: 1, label: "Yes" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            {values.do_change_bot_flow == 1 && (
                                                                <div>
                                                                    <SettingsSelect
                                                                        label="Bot Flow to Change to"
                                                                        name="new_bot_flow_id"
                                                                        onChange={setFieldValue}
                                                                        onBlur={setFieldTouched}
                                                                        value={values.new_bot_flow_id}
                                                                        touched={touched.new_bot_flow_id}
                                                                        error={errors.new_bot_flow_id}
                                                                        options={bot_flows
                                                                            .filter((botFlow) => botFlow.parent_bot_flow_id === 0) // Filter to only show parent items
                                                                            .sort((a, b) => a.sort_order - b.sort_order) // Sort by sort_order
                                                                            .map((botFlow) => ({
                                                                                value: botFlow.id,
                                                                                label: botFlow.name,
                                                                            }))}
                                                                    />
                                                                </div>
                                                            )}

                                                            {values.do_change_bot_flow == 1 &&
                                                                values.new_bot_flow_id > 0 &&
                                                                (() => {
                                                                    const selectedBotFlow = bot_flows.find((botFlow) => botFlow.id === values.new_bot_flow_id);

                                                                    // Only render the sub bot flow dropdown if children exist and are non-empty
                                                                    return (
                                                                        selectedBotFlow?.children &&
                                                                        selectedBotFlow.children.length > 0 && (
                                                                            <div>
                                                                                <SettingsSelect
                                                                                    label="Sub Bot Flow to Change to"
                                                                                    name="new_bot_flow_id_sub"
                                                                                    onChange={setFieldValue}
                                                                                    onBlur={setFieldTouched}
                                                                                    value={values.new_bot_flow_id_sub}
                                                                                    touched={touched.new_bot_flow_id_sub}
                                                                                    error={errors.new_bot_flow_id_sub}
                                                                                    options={selectedBotFlow.children
                                                                                        .sort((a, b) => a.sort_order - b.sort_order) // Sort by sort_order
                                                                                        .map((childFlow) => ({
                                                                                            value: childFlow.id,
                                                                                            label: childFlow.name,
                                                                                        }))}
                                                                                />
                                                                            </div>
                                                                        )
                                                                    );
                                                                })()}
                                                        </div>

                                                        <div>
                                                            <SettingsSelect
                                                                label="Send Message"
                                                                name="do_send_message"
                                                                onChange={setFieldValue}
                                                                onBlur={setFieldTouched}
                                                                value={values.do_send_message}
                                                                touched={touched.do_send_message}
                                                                error={errors.do_send_message}
                                                                options={[
                                                                    { value: 0, label: "No" },
                                                                    { value: 1, label: "Yes - Custom Message" },
                                                                    { value: 2, label: "Yes - Template (Non Approved)" },
                                                                    { value: 4, label: "Yes - Template (Approved)" },
                                                                    { value: 3, label: "Yes - Property Gallery" },
                                                                ]}
                                                            />
                                                        </div>

                                                        {values.do_send_message > 0 && (
                                                            <div>
                                                                <div>
                                                                    <SettingsSelect
                                                                        label="Robot Colour"
                                                                        name="robot_colour"
                                                                        onChange={setFieldValue}
                                                                        onBlur={setFieldTouched}
                                                                        value={values.robot_colour}
                                                                        touched={touched.robot_colour}
                                                                        error={errors.robot_colour}
                                                                        options={[
                                                                            { value: 0, label: "Red" },
                                                                            { value: 1, label: "Green" },
                                                                        ]}
                                                                    />
                                                                </div>

                                                                {values.bot_type == "Email" ? (
                                                                    <div>
                                                                        <Field name="email_subject" type="text" as={SettingsTextInput} label="Email Subject" />
                                                                        <div className="mt-4">
                                                                            <SettingsTextArea
                                                                                name="message_to_send"
                                                                                placeholder="Message - Email"
                                                                                errors={errors.message_to_send}
                                                                                emojis={true}
                                                                                setFieldValue={setFieldValue}
                                                                                handleChange={handleChange}
                                                                                value={values.message_to_send}
                                                                                wysiwyg={true}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        {values.do_send_message === 3 && (
                                                                            <div className="mt-4">
                                                                                <SettingsSelect
                                                                                    label="Choose a Gallery"
                                                                                    name="gallery_to_send"
                                                                                    onChange={setFieldValue}
                                                                                    onBlur={setFieldTouched}
                                                                                    value={values.gallery_to_send}
                                                                                    touched={touched.gallery_to_send}
                                                                                    error={errors.gallery_to_send}
                                                                                    options={[
                                                                                        {
                                                                                            value: 0,
                                                                                            label: "Student Property",
                                                                                        },
                                                                                        ...properties.map((property) => ({
                                                                                            value: property.value,
                                                                                            label: property.label,
                                                                                        })),
                                                                                    ]}
                                                                                />
                                                                            </div>
                                                                        )}

                                                                        {values.do_send_message === 1 || values.do_send_message === 3 ? (
                                                                            <div className="mt-4">
                                                                                <label htmlFor="message_to_send" className="text-sm font-medium text-gray-700 flex items-baseline justify-between mb-2">
                                                                                    Message
                                                                                </label>
                                                                                <BookingTextArea
                                                                                    as="textarea"
                                                                                    rows={5}
                                                                                    name="message_to_send"
                                                                                    id="message_to_send"
                                                                                    placeholder="Message to Send"
                                                                                    errors={errors}
                                                                                />

                                                                                <div className="mt-4">
                                                                                    <SettingsSelect
                                                                                        label="Attachment"
                                                                                        name="message_attachment"
                                                                                        onChange={setFieldValue}
                                                                                        onBlur={setFieldTouched}
                                                                                        value={values.message_attachment}
                                                                                        touched={touched.message_attachment}
                                                                                        error={errors.message_attachment}
                                                                                        options={[
                                                                                            {
                                                                                                value: 0,
                                                                                                label: "No Attachment",
                                                                                            },
                                                                                            {
                                                                                                value: 1,
                                                                                                label: "Lease",
                                                                                            },
                                                                                            // {
                                                                                            //     value: 2,
                                                                                            //     label: "Booking Summary",
                                                                                            // },
                                                                                        ]}
                                                                                    />
                                                                                </div>

                                                                                <div className="mt-4 text-sm">
                                                                                    [name] [ozow_booking_link] [ozow_link name='raa'] [raa_booking_link] [choose_bed] [sign_document name='bus-route']
                                                                                    [Move_In_Inspection] [bed_name] [payfast_link name='raa'] [Check In Form]
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <div className="mt-4">
                                                                                {values.template_to_send === 2 ? (
                                                                                    <SettingsSelect
                                                                                        label="Template Message"
                                                                                        name="template_to_send"
                                                                                        onChange={setFieldValue}
                                                                                        onBlur={setFieldTouched}
                                                                                        value={values.template_to_send}
                                                                                        touched={touched.template_to_send}
                                                                                        error={errors.template_to_send}
                                                                                        options={Object.keys(templates).flatMap((category) =>
                                                                                            templates[category]
                                                                                                .sort((a, b) => a.sort_order - b.sort_order)
                                                                                                .map((template) => ({
                                                                                                    value: template.id, // Use template.id as the value
                                                                                                    label: `${category} - ${template.title}`, // Prepend the category name to the template title
                                                                                                }))
                                                                                        )}
                                                                                    />
                                                                                ) : (
                                                                                    <SettingsSelect
                                                                                        label="Template Message"
                                                                                        name="template_to_send"
                                                                                        onChange={setFieldValue}
                                                                                        onBlur={setFieldTouched}
                                                                                        value={values.template_to_send}
                                                                                        touched={touched.template_to_send}
                                                                                        error={errors.template_to_send}
                                                                                        options={whatsappTemplates
                                                                                            .sort((a, b) => (a.sort_order || a.id) - (b.sort_order || b.id))
                                                                                            .map((template) => ({
                                                                                                value: template.id,
                                                                                                label: `${template.title} - ${template.message}`,
                                                                                            }))}
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )}

                                                                {values.id > 0 ? (
                                                                    <div className="mt-4">
                                                                        <label htmlFor="message_to_send" className="text-sm font-medium text-gray-700 flex items-baseline justify-between mb-2">
                                                                            Upload a File (Does not work for List Message Type)
                                                                        </label>
                                                                        {values.file_path !== null ? (
                                                                            <p>
                                                                                File Uploaded <img src={process.env.REACT_APP_LARAVEL_URL + "/" + values.file_path} alt="File Uploaded" />
                                                                            </p>
                                                                        ) : (
                                                                            <input
                                                                                id="file"
                                                                                name="file"
                                                                                type="file"
                                                                                onChange={(event) => {
                                                                                    setFieldValue("file", event.currentTarget.files[0]);
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    <div className="mt-4">File upload only works after saving</div>
                                                                )}
                                                            </div>
                                                        )}

                                                        <div className="grid grid-cols-2 gap-4">
                                                            <div>
                                                                <SettingsSelect
                                                                    label="Message Type"
                                                                    name="message_type"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.message_type}
                                                                    touched={touched.message_type}
                                                                    error={errors.message_type}
                                                                    options={[
                                                                        { value: 0, label: "Normal Message" },
                                                                        { value: 1, label: "List (10 Items Max)" },
                                                                        { value: 2, label: "Buttons (3 Items Max)" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            {values.message_type > 0 && (
                                                                <div>
                                                                    <SettingsSelect
                                                                        label="Items"
                                                                        name="message_class"
                                                                        onChange={setFieldValue}
                                                                        onBlur={setFieldTouched}
                                                                        value={values.message_class}
                                                                        touched={touched.message_class}
                                                                        error={errors.message_class}
                                                                        options={[
                                                                            { value: "", label: "None" },
                                                                            {
                                                                                value: "funding_source_buttons",
                                                                                label: "Funding Source",
                                                                            },
                                                                            {
                                                                                value: "campus_buttons",
                                                                                label: "Campus Buttons",
                                                                            },
                                                                            {
                                                                                value: "study_year_buttons",
                                                                                label: "Study Years",
                                                                            },
                                                                            {
                                                                                value: "institution_buttons",
                                                                                label: "Institutions",
                                                                            },
                                                                            // { value: "applying_as_buttons", label: "Student, Parent, Sponsor Buttons" },
                                                                            // { value: "continue_button", label: "Continue Button" },
                                                                            {
                                                                                value: "budget_buttons",
                                                                                label: "Budget Buttons",
                                                                            },
                                                                            {
                                                                                value: "property_buttons",
                                                                                label: "Property Buttons",
                                                                            },
                                                                            {
                                                                                value: "unit_type_buttons",
                                                                                label: "Unit Type Buttons",
                                                                            },
                                                                            { value: "bank_list", label: "Bank List" },
                                                                        ]}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                                        <SubmitButton
                                                            name="Save"
                                                            disabled={!isValid || isSubmitting || !dirty}
                                                            isSubmitting={isSubmitting}
                                                            icon={faSave}
                                                            text="Save"
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                        />

                                                        <button
                                                            type="button"
                                                            className="inline-flex items-center justify-center w-full px-4 py-3 bg-gray-400 text-body rounded-xl uppercase disabled:opacity-25 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 hover:bg-accent"
                                                            onClick={() => setOpenPopup(false)}
                                                            ref={cancelButtonRef}
                                                        >
                                                            CLOSE
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            <Transition.Root show={openPopup === 2} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => {}}>
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                                    <Formik
                                        initialValues={initialValues}
                                        enableReinitialize="true"
                                        onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                            setSubmitting(true);

                                            await axios({
                                                method: "post",
                                                url: `${process.env.REACT_APP_API_URL}save_bot_flow_rule`,
                                                data: values,
                                            })
                                                .then((resp) => {
                                                    axios({
                                                        method: "get",
                                                        url: `${process.env.REACT_APP_API_URL}get_bot_flows`,
                                                    })
                                                        .then((response) => {
                                                            setBotFlows(response.data[0]);
                                                            setYears(response.data[1]);
                                                            setLoading(false);
                                                        })
                                                        .catch((error) => {
                                                            alert("Failed to get bot flows");
                                                            setLoading(false);
                                                        });
                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                    console.log(error.response);
                                                })
                                                .finally(() => {
                                                    setSubmitting(false);
                                                    setOpenPopup(false);
                                                });
                                        }}
                                    >
                                        {({ values, touched, errors, setFieldValue, setFieldTouched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
                                            <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4">
                                                <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                    <div className="space-y-4">
                                                        <div className="grid grid-cols-2 gap-4">
                                                            <div>
                                                                <SettingsSelect
                                                                    label="Match Message"
                                                                    name="match_message"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.match_message}
                                                                    touched={touched.match_message}
                                                                    error={errors.match_message}
                                                                    options={[
                                                                        { value: 0, label: "No" },
                                                                        { value: 1, label: "Yes" },
                                                                    ]}
                                                                />
                                                            </div>
                                                            <div>{values.match_message == 1 && <Field name="incoming_message" type="text" as={SettingsTextInput} label="Incoming Message" />}</div>

                                                            <div>
                                                                <SettingsSelect
                                                                    label="Match Location"
                                                                    name="match_location"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.match_location}
                                                                    touched={touched.match_location}
                                                                    error={errors.match_location}
                                                                    options={[
                                                                        { value: 0, label: "No" },
                                                                        { value: 1, label: "Yes" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            <div>
                                                                {values.match_location == 1 && (
                                                                    <div className="grid grid-cols-2">
                                                                        <div>
                                                                            <SettingsSelect
                                                                                label="Current Location"
                                                                                name="current_location"
                                                                                onChange={setFieldValue}
                                                                                onBlur={setFieldTouched}
                                                                                value={values.current_location}
                                                                                touched={touched.current_location}
                                                                                error={errors.current_location}
                                                                                options={locations}
                                                                            />
                                                                        </div>

                                                                        {(values.current_location === "Residents" ||
                                                                            values.current_location === "Historical" ||
                                                                            values.current_location === "Management 2023") && (
                                                                            <div>
                                                                                <label for="current_location" class="text-sm font-medium mb-2 text-gray-700 flex items-baseline mx-2">
                                                                                    Current Tag
                                                                                </label>
                                                                                <Field
                                                                                    as="select"
                                                                                    name="current_tag_id"
                                                                                    className="capitalize cursor-pointer relative p-2 mx-2 bg-light_background rounded-md transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
                                                                                >
                                                                                    {values.current_location === "Residents" && <option value="0">No Tag</option>}

                                                                                    {tags
                                                                                        .filter(
                                                                                            (tag) =>
                                                                                                tag.parent_folder === values.current_location ||
                                                                                                (tag.parent_folder === "Historical" && values.current_location == "Management 2023")
                                                                                        )
                                                                                        .map((tag) => (
                                                                                            <option
                                                                                                key={tag.id}
                                                                                                value={tag.id}
                                                                                                className={classNames(tag.id === values.current_tag_id ? "text-white bg-primary" : "text-black")}
                                                                                            >
                                                                                                {tag.name}
                                                                                            </option>
                                                                                        ))}
                                                                                </Field>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>

                                                            <div>
                                                                <SettingsSelect
                                                                    label="Match Funding Type"
                                                                    name="do_match_funding_type"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.do_match_funding_type}
                                                                    touched={touched.do_match_funding_type}
                                                                    error={errors.do_match_funding_type}
                                                                    options={[
                                                                        { value: 0, label: "No" },
                                                                        { value: 1, label: "Yes" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            <div>
                                                                {values.do_match_funding_type == 1 && (
                                                                    <div>
                                                                        <SettingsSelect
                                                                            label="Funding Type"
                                                                            name="current_funding_type"
                                                                            onChange={setFieldValue}
                                                                            onBlur={setFieldTouched}
                                                                            value={values.current_funding_type}
                                                                            touched={touched.current_funding_type}
                                                                            error={errors.current_funding_type}
                                                                            options={funding_types}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>

                                                            <div>
                                                                <SettingsSelect
                                                                    label="Match Property"
                                                                    name="do_match_property"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.do_match_property}
                                                                    touched={touched.do_match_property}
                                                                    error={errors.do_match_property}
                                                                    options={[
                                                                        { value: 0, label: "No" },
                                                                        { value: 1, label: "Yes" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            <div>
                                                                {values.do_match_property === 1 && (
                                                                    <div>
                                                                        <SettingsSelect
                                                                            label="Property"
                                                                            name="current_property_id"
                                                                            onChange={setFieldValue}
                                                                            onBlur={setFieldTouched}
                                                                            value={values.current_property_id}
                                                                            touched={touched.current_property_id}
                                                                            error={errors.current_property_id}
                                                                            options={properties}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>

                                                            <div className="col-span-2">
                                                                <SettingsSelect
                                                                    label="Match Renewal"
                                                                    name="do_match_renewal"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.do_match_renewal}
                                                                    touched={touched.do_match_renewal}
                                                                    error={errors.do_match_renewal}
                                                                    options={[
                                                                        { value: 0, label: "No Match" },
                                                                        { value: 1, label: "New Lead" },
                                                                        { value: 2, label: "Renewal" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            <div className="col-span-2">
                                                                <SettingsSelect
                                                                    label="Match Linked Chat"
                                                                    name="do_match_linked_chat"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.do_match_linked_chat}
                                                                    touched={touched.do_match_linked_chat}
                                                                    error={errors.do_match_linked_chat}
                                                                    options={[
                                                                        { value: 0, label: "No Match" },
                                                                        { value: 1, label: "Has Linked Chat" },
                                                                        { value: 2, label: "No Linked Chat" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            <div>
                                                                <SettingsSelect
                                                                    label="Match Budget"
                                                                    name="do_match_budget"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.do_match_budget}
                                                                    touched={touched.do_match_budget}
                                                                    error={errors.do_match_budget}
                                                                    options={[
                                                                        { value: 0, label: "No" },
                                                                        { value: 1, label: "Yes" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            <div>
                                                                {values.do_match_budget === 1 && (
                                                                    <div>
                                                                        <SettingsSelect
                                                                            label="Budget"
                                                                            name="budget_id"
                                                                            onChange={setFieldValue}
                                                                            onBlur={setFieldTouched}
                                                                            value={values.budget_id}
                                                                            touched={touched.budget_id}
                                                                            error={errors.budget_id}
                                                                            options={lookups ? lookups[15] : null}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>

                                                            <div>
                                                                <SettingsSelect
                                                                    label="Match Study Year"
                                                                    name="do_match_study_year"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.do_match_study_year}
                                                                    touched={touched.do_match_study_year}
                                                                    error={errors.do_match_study_year}
                                                                    options={[
                                                                        { value: 0, label: "No" },
                                                                        { value: 1, label: "Yes" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            <div>
                                                                {values.do_match_study_year === 1 && (
                                                                    <div>
                                                                        <SettingsSelect
                                                                            label="Study Year"
                                                                            name="study_year_id"
                                                                            onChange={setFieldValue}
                                                                            onBlur={setFieldTouched}
                                                                            value={values.study_year_id}
                                                                            touched={touched.study_year_id}
                                                                            error={errors.study_year_id}
                                                                            options={lookups ? lookups[8] : null}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>

                                                            <div>
                                                                <SettingsSelect
                                                                    label="Has Signed Lease"
                                                                    name="do_match_signed_lease"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.do_match_signed_lease}
                                                                    touched={touched.do_match_signed_lease}
                                                                    error={errors.do_match_signed_lease}
                                                                    options={[
                                                                        { value: 0, label: "No Match" },
                                                                        { value: 1, label: "No" },
                                                                        { value: 2, label: "Yes" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            <div>
                                                                <SettingsSelect
                                                                    label="Status"
                                                                    name="status"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.status}
                                                                    touched={touched.status}
                                                                    error={errors.status}
                                                                    options={[
                                                                        { value: "Active", label: "Active" },
                                                                        { value: "Disabled", label: "Disabled" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            <div>
                                                                <SettingsSelect
                                                                    label="End Bot Flow"
                                                                    name="end_bot_flow"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.end_bot_flow}
                                                                    touched={touched.end_bot_flow}
                                                                    error={errors.end_bot_flow}
                                                                    options={[
                                                                        { value: 0, label: "No" },
                                                                        { value: 1, label: "Yes" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            <div className="col-span-2">
                                                                <SettingsSelect
                                                                    label="Send Message"
                                                                    name="do_send_message"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.do_send_message}
                                                                    touched={touched.do_send_message}
                                                                    error={errors.do_send_message}
                                                                    options={[
                                                                        { value: 0, label: "No" },
                                                                        { value: 1, label: "Yes - Custom Message" },
                                                                        {
                                                                            value: 2,
                                                                            label: "Yes - Template (Non Approved)",
                                                                        },
                                                                        { value: 4, label: "Yes - Template (Approved)" },
                                                                        { value: 3, label: "Yes - Property Gallery" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            {values.do_send_message > 0 && (
                                                                <div className="col-span-2">
                                                                    {(values.do_send_message === 1 || values.do_send_message === 3) && (
                                                                        <div className="space-y-4">
                                                                            <SettingsSelect
                                                                                label="Robot Colour"
                                                                                name="robot_colour"
                                                                                onChange={setFieldValue}
                                                                                onBlur={setFieldTouched}
                                                                                value={values.robot_colour}
                                                                                touched={touched.robot_colour}
                                                                                error={errors.robot_colour}
                                                                                options={[
                                                                                    { value: 0, label: "Red" },
                                                                                    { value: 1, label: "Green" },
                                                                                ]}
                                                                            />

                                                                            <BookingTextArea
                                                                                as="textarea"
                                                                                rows={5}
                                                                                name="message_to_send"
                                                                                id="message_to_send"
                                                                                placeholder="Message to Send"
                                                                                errors={errors}
                                                                            />
                                                                        </div>
                                                                    )}

                                                                    {values.do_send_message === 2 && (
                                                                        <SettingsSelect
                                                                            label="Template Message"
                                                                            name="template_to_send"
                                                                            onChange={setFieldValue}
                                                                            onBlur={setFieldTouched}
                                                                            value={values.template_to_send}
                                                                            touched={touched.template_to_send}
                                                                            error={errors.template_to_send}
                                                                            options={Object.keys(templates).flatMap((category) =>
                                                                                templates[category]
                                                                                    .sort((a, b) => a.sort_order - b.sort_order)
                                                                                    .map((template) => ({
                                                                                        value: template.id, // Use template.id as the value
                                                                                        label: `${category} - ${template.title}`, // Prepend the category name to the template title
                                                                                    }))
                                                                            )}
                                                                        />
                                                                    )}

                                                                    {values.do_send_message === 4 && (
                                                                        <SettingsSelect
                                                                            label="Template Message"
                                                                            name="template_to_send"
                                                                            onChange={setFieldValue}
                                                                            onBlur={setFieldTouched}
                                                                            value={values.template_to_send}
                                                                            touched={touched.template_to_send}
                                                                            error={errors.template_to_send}
                                                                            options={whatsappTemplates
                                                                                .sort((a, b) => (a.sort_order || a.id) - (b.sort_order || b.id))
                                                                                .map((template) => ({
                                                                                    value: template.id,
                                                                                    label: `${template.title} - ${template.message}`,
                                                                                }))}
                                                                        />
                                                                    )}
                                                                </div>
                                                            )}

                                                            <div className="col-span-2 grid grid-cols-2 gap-4">
                                                                <div>
                                                                    <SettingsSelect
                                                                        label="Move Chat"
                                                                        name="do_move_chat"
                                                                        onChange={setFieldValue}
                                                                        onBlur={setFieldTouched}
                                                                        value={values.do_move_chat}
                                                                        touched={touched.do_move_chat}
                                                                        error={errors.do_move_chat}
                                                                        options={[
                                                                            { value: 0, label: "No" },
                                                                            { value: 1, label: "Yes" },
                                                                        ]}
                                                                    />
                                                                </div>

                                                                {values.do_move_chat == 1 && (
                                                                    // <div>
                                                                    //     <Field name="move_to_location" type="text" as={SettingsTextInput} label="Location to Move To" />
                                                                    // </div>

                                                                    <div className="grid grid-cols-2">
                                                                        <div>
                                                                            <SettingsSelect
                                                                                label="Location to Move To"
                                                                                name="move_to_location"
                                                                                onChange={setFieldValue}
                                                                                onBlur={setFieldTouched}
                                                                                value={values.move_to_location}
                                                                                touched={touched.move_to_location}
                                                                                error={errors.move_to_location}
                                                                                options={locations}
                                                                            />
                                                                        </div>

                                                                        {(values.move_to_location === "Residents" ||
                                                                            values.move_to_location === "Historical" ||
                                                                            values.move_to_location === "Management 2023") && (
                                                                            <div>
                                                                                <label for="move_to_location" class="text-sm font-medium mb-2 text-gray-700 flex items-baseline mx-2">
                                                                                    Current Tag
                                                                                </label>
                                                                                <Field
                                                                                    as="select"
                                                                                    name="move_to_tag"
                                                                                    className="capitalize cursor-pointer relative p-2 mx-2 bg-light_background rounded-md transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
                                                                                >
                                                                                    {values.move_to_location === "Residents" && <option value="0">No Tag</option>}

                                                                                    {tags
                                                                                        .filter(
                                                                                            (tag) =>
                                                                                                tag.parent_folder === values.move_to_location ||
                                                                                                (tag.parent_folder === "Historical" && values.move_to_location == "Management 2023")
                                                                                        )
                                                                                        .map((tag) => (
                                                                                            <option
                                                                                                key={tag.id}
                                                                                                value={tag.id}
                                                                                                className={classNames(tag.id === values.move_to_tag ? "text-white bg-primary" : "text-black")}
                                                                                            >
                                                                                                {tag.name}
                                                                                            </option>
                                                                                        ))}
                                                                                </Field>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>

                                                            <div className="grid grid-cols-3 gap-4">
                                                                <div>
                                                                    <SettingsSelect
                                                                        label="Do Action"
                                                                        name="do_action"
                                                                        onChange={setFieldValue}
                                                                        onBlur={setFieldTouched}
                                                                        value={values.do_action}
                                                                        touched={touched.do_action}
                                                                        error={errors.do_action}
                                                                        options={[
                                                                            { value: 0, label: "No" },
                                                                            { value: 1, label: "Yes" },
                                                                        ]}
                                                                    />
                                                                </div>

                                                                {values.do_action == 1 && (
                                                                    <div>
                                                                        <SettingsSelect
                                                                            label="Action to do"
                                                                            name="action_to_do"
                                                                            onChange={setFieldValue}
                                                                            onBlur={setFieldTouched}
                                                                            value={values.action_to_do}
                                                                            touched={touched.action_to_do}
                                                                            error={errors.action_to_do}
                                                                            options={[
                                                                                {
                                                                                    value: "1",
                                                                                    label: "Create a Booking (Old Booking Active)",
                                                                                },
                                                                                {
                                                                                    value: "2",
                                                                                    label: "Create a Booking and Archive old Booking",
                                                                                },
                                                                                {
                                                                                    value: "3",
                                                                                    label: "Refund - Location Move",
                                                                                },
                                                                                {
                                                                                    value: "4",
                                                                                    label: "Geneate & Email AOS",
                                                                                },
                                                                                {
                                                                                    value: "5",
                                                                                    label: "Generate and Send PDF Booking Summary",
                                                                                },
                                                                                {
                                                                                    value: "6",
                                                                                    label: "Keep previous year bed & Generate and Send PDF Booking Summary",
                                                                                },
                                                                                { value: "7", label: "Run TPN Check" },
                                                                                {
                                                                                    value: "8",
                                                                                    label: "Biometrics - User Image Flow",
                                                                                },
                                                                                {
                                                                                    value: "9",
                                                                                    label: "Biometrics - Request Guest Flow",
                                                                                },
                                                                                {
                                                                                    value: "10",
                                                                                    label: "Auto Allocate Bed",
                                                                                },
                                                                                {
                                                                                    value: "11",
                                                                                    label: "PayStack Refund",
                                                                                },
                                                                                {
                                                                                    value: "12",
                                                                                    label: "Biometrics - Request Photo",
                                                                                },
                                                                            ]}
                                                                        />
                                                                    </div>
                                                                )}

                                                                {values.do_action === 1 && (values.action_to_do === 1 || values.action_to_do === 2) && (
                                                                    <div>
                                                                        <SettingsSelect
                                                                            label="Year"
                                                                            name="booking_year"
                                                                            onChange={setFieldValue}
                                                                            onBlur={setFieldTouched}
                                                                            value={values.booking_year}
                                                                            touched={touched.booking_year}
                                                                            error={errors.booking_year}
                                                                            options={years}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>

                                                            {values.do_action == 1 && (values.action_to_do == 1 || values.action_to_do == 2) && (
                                                                <div className="grid grid-cols-3 gap-4">
                                                                    <div>
                                                                        <SettingsSelect
                                                                            label="Move New Booking"
                                                                            name="do_move_booking"
                                                                            onChange={setFieldValue}
                                                                            onBlur={setFieldTouched}
                                                                            value={values.do_move_booking}
                                                                            touched={touched.do_move_booking}
                                                                            error={errors.do_move_booking}
                                                                            options={[
                                                                                { value: 0, label: "No" },
                                                                                { value: 1, label: "Yes" },
                                                                            ]}
                                                                        />
                                                                    </div>

                                                                    {values.do_move_booking == 1 && (
                                                                        <div className="col-span-2 grid grid-cols-2">
                                                                            <div>
                                                                                <SettingsSelect
                                                                                    label="Location to Move To"
                                                                                    name="move_booking_to_location"
                                                                                    onChange={setFieldValue}
                                                                                    onBlur={setFieldTouched}
                                                                                    value={values.move_booking_to_location}
                                                                                    touched={touched.move_booking_to_location}
                                                                                    error={errors.move_booking_to_location}
                                                                                    options={locations}
                                                                                />
                                                                            </div>

                                                                            {(values.move_booking_to_location === "Residents" ||
                                                                                values.move_booking_to_location === "Historical" ||
                                                                                values.move_booking_to_location === "Management 2023") && (
                                                                                <div>
                                                                                    <label for="move_booking_to_location" class="text-sm font-medium mb-2 text-gray-700 flex items-baseline mx-2">
                                                                                        Move to Tag
                                                                                    </label>
                                                                                    <Field
                                                                                        as="select"
                                                                                        name="move_booking_to_tag"
                                                                                        className="capitalize cursor-pointer relative p-2 mx-2 bg-light_background rounded-md transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
                                                                                    >
                                                                                        {values.move_booking_to_location === "Residents" && <option value="0">No Tag</option>}

                                                                                        {tags
                                                                                            .filter(
                                                                                                (tag) =>
                                                                                                    tag.parent_folder === values.move_booking_to_location ||
                                                                                                    (tag.parent_folder === "Historical" && values.move_booking_to_location == "Management 2023")
                                                                                            )
                                                                                            .map((tag) => (
                                                                                                <option
                                                                                                    key={tag.id}
                                                                                                    value={tag.id}
                                                                                                    className={classNames(
                                                                                                        tag.id === values.move_booking_to_tag ? "text-white bg-primary" : "text-black"
                                                                                                    )}
                                                                                                >
                                                                                                    {tag.name}
                                                                                                </option>
                                                                                            ))}
                                                                                    </Field>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}

                                                            <div className="col-span-2 grid grid-cols-2 gap-4">
                                                                <div>
                                                                    <SettingsSelect
                                                                        label="Change Bot Flow"
                                                                        name="do_change_bot_flow"
                                                                        onChange={setFieldValue}
                                                                        onBlur={setFieldTouched}
                                                                        value={values.do_change_bot_flow}
                                                                        touched={touched.do_change_bot_flow}
                                                                        error={errors.do_change_bot_flow}
                                                                        options={[
                                                                            { value: 0, label: "No" },
                                                                            { value: 1, label: "Yes" },
                                                                        ]}
                                                                    />
                                                                </div>
                                                                {values.do_change_bot_flow == 1 && (
                                                                    <div>
                                                                        <SettingsSelect
                                                                            label="Bot Flow to Change to"
                                                                            name="new_bot_flow_id"
                                                                            onChange={setFieldValue}
                                                                            onBlur={setFieldTouched}
                                                                            value={values.new_bot_flow_id}
                                                                            touched={touched.new_bot_flow_id}
                                                                            error={errors.new_bot_flow_id}
                                                                            options={bot_flows
                                                                                .filter((botFlow) => botFlow.parent_bot_flow_id === 0) // Filter to only show parent items
                                                                                .sort((a, b) => a.sort_order - b.sort_order) // Sort by sort_order
                                                                                .map((botFlow) => ({
                                                                                    value: botFlow.id,
                                                                                    label: botFlow.name,
                                                                                }))}
                                                                        />
                                                                    </div>
                                                                )}

                                                                {values.do_change_bot_flow == 1 &&
                                                                    values.new_bot_flow_id > 0 &&
                                                                    (() => {
                                                                        const selectedBotFlow = bot_flows.find((botFlow) => botFlow.id === values.new_bot_flow_id);

                                                                        // Only render the sub bot flow dropdown if children exist and are non-empty
                                                                        return (
                                                                            selectedBotFlow?.children &&
                                                                            selectedBotFlow.children.length > 0 && (
                                                                                <div>
                                                                                    <SettingsSelect
                                                                                        label="Sub Bot Flow to Change to"
                                                                                        name="new_bot_flow_id_sub"
                                                                                        onChange={setFieldValue}
                                                                                        onBlur={setFieldTouched}
                                                                                        value={values.new_bot_flow_id_sub}
                                                                                        touched={touched.new_bot_flow_id_sub}
                                                                                        error={errors.new_bot_flow_id_sub}
                                                                                        options={selectedBotFlow.children
                                                                                            .sort((a, b) => a.sort_order - b.sort_order) // Sort by sort_order
                                                                                            .map((childFlow) => ({
                                                                                                value: childFlow.id,
                                                                                                label: childFlow.name,
                                                                                            }))}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        );
                                                                    })()}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                                        <SubmitButton
                                                            name="Save"
                                                            disabled={!isValid || isSubmitting || !dirty}
                                                            isSubmitting={isSubmitting}
                                                            icon={faSave}
                                                            text="Save"
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                        />

                                                        <button
                                                            type="button"
                                                            className="inline-flex items-center justify-center w-full px-4 py-3 bg-gray-400 text-body rounded-xl uppercase disabled:opacity-25 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 hover:bg-accent"
                                                            onClick={() => setOpenPopup(false)}
                                                            ref={cancelButtonRef}
                                                        >
                                                            CLOSE
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            <Transition.Root show={openPopup === 3} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => {}}>
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                                    <Formik
                                        initialValues={initialValues}
                                        enableReinitialize="true"
                                        onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                            setSubmitting(true);

                                            await axios({
                                                method: "post",
                                                url: `${process.env.REACT_APP_API_URL}save_bot_flow_option`,
                                                data: values,
                                            })
                                                .then((resp) => {
                                                    axios({
                                                        method: "get",
                                                        url: `${process.env.REACT_APP_API_URL}get_bot_flows`,
                                                    })
                                                        .then((response) => {
                                                            setBotFlows(response.data[0]);
                                                            setYears(response.data[1]);
                                                            setLoading(false);
                                                        })
                                                        .catch((error) => {
                                                            alert("Failed to get bot flows");
                                                            setLoading(false);
                                                        });
                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                    console.log(error.response);
                                                })
                                                .finally(() => {
                                                    setSubmitting(false);
                                                    setOpenPopup(false);
                                                });
                                        }}
                                    >
                                        {({ values, touched, errors, setFieldValue, setFieldTouched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
                                            <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4">
                                                <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                    <div className="space-y-4">
                                                        <div className="grid grid-cols-2 gap-4">
                                                            <div>
                                                                <Field
                                                                    name="option_name"
                                                                    type="text"
                                                                    as={SettingsTextInput}
                                                                    label={"Option Name (" + values.option_name?.length + ")"}
                                                                    maxLength={24}
                                                                />
                                                            </div>

                                                            <div>
                                                                <Field name="sort_order" type="number" as={SettingsTextInput} label="Sort Order" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                                        <SubmitButton
                                                            name="Save"
                                                            disabled={!isValid || isSubmitting || !dirty}
                                                            isSubmitting={isSubmitting}
                                                            icon={faSave}
                                                            text="Save"
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                        />

                                                        <button
                                                            type="button"
                                                            className="inline-flex items-center justify-center w-full px-4 py-3 bg-gray-400 text-body rounded-xl uppercase disabled:opacity-25 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 hover:bg-accent"
                                                            onClick={() => setOpenPopup(false)}
                                                            ref={cancelButtonRef}
                                                        >
                                                            CLOSE
                                                        </button>
                                                    </div>

                                                    <div className="text-center mt-8 italic -my-6">
                                                        <strong>*Option Name:</strong> List items max length 24. Buttons max length 20.
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            <Transition.Root show={openManageGroups === true} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => {}}>
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                                    <Formik
                                        initialValues={initialValues}
                                        enableReinitialize="true"
                                        onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                            setSubmitting(true);

                                            // await axios({
                                            //     method: "post",
                                            //     url: `${process.env.REACT_APP_API_URL}save_bot_flow_option`,
                                            //     data: values,
                                            // })
                                            //     .then((resp) => {
                                            //         axios({
                                            //             method: "get",
                                            //             url: `${process.env.REACT_APP_API_URL}get_bot_flows`,
                                            //         })
                                            //             .then((response) => {
                                            //                 setBotFlows(response.data[0]);
                                            //                 setYears(response.data[1]);
                                            //                 setLoading(false);
                                            //             })
                                            //             .catch((error) => {
                                            //                 alert("Failed to get bot flows");
                                            //                 setLoading(false);
                                            //             });
                                            //     })
                                            //     .catch((error) => {
                                            //         console.log(error);
                                            //         console.log(error.response);
                                            //     })
                                            //     .finally(() => {
                                            //         setSubmitting(false);
                                            //         setOpenPopup(false);
                                            //     });
                                        }}
                                    >
                                        {({ values, touched, errors, setFieldValue, setFieldTouched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
                                            <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4">
                                                <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                    <div className="space-y-4">
                                                        <h2 className="font-bold ">Manage Bot Flow Groups</h2>
                                                        table
                                                    </div>

                                                    <div className="mt-5 sm:mt-6">
                                                        <button
                                                            type="button"
                                                            className="inline-flex items-center justify-center w-full px-4 py-3 bg-gray-400 text-body rounded-xl uppercase disabled:opacity-25 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 hover:bg-accent"
                                                            onClick={() => setOpenManageGroups(false)}
                                                            ref={cancelButtonRef}
                                                        >
                                                            CLOSE
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    );
}
