import React, { Fragment, useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import ErrorBoundary from "../../containers/ErrorBoundary";

import NotificationToast from "../Helpers/NotificationToast";
import SubmitButton from "../Forms/SubmitButton";
import CustomButton from "../Helpers/CustomButton";
import SettingsSelect from "../Forms/SettingsSelect";
import SettingsTextInput from "../Forms/SettingsTextInput";
import SettingsTextArea from "../Forms/SettingsTextarea";
import { closeModal } from "../../store/modal/modalReducer";
import { getTemplates } from "../../store/template/templateActions";
import { getTemplateCategories } from "../../store/template/templateActions";
import { getTags } from "../../store/tag/tagActions";
import { getCollections } from "../../store/collection/collectionActions";
import { getAppointments } from "../../store/appointments/appointmentActions";
import { getRenewals } from "../../store/renewal/renewalActions";
import { getFolders } from "../../store/tag/folderActions";

export default function SlideoverLookupForm(props) {
	const dispatch = useDispatch();
	const [open] = useState(true);
	const { current_staff } = useSelector((state) => state.auth);
	const { folders } = useSelector((state) => state.folders);
	const [tagFolders, setTagFolders] = useState([]);

	useEffect(() => {
		// Filter folders to only include those with has_tags=1
		if (folders && folders.length > 0) {
			const foldersWithTags = folders.filter(folder => folder.has_tags === 1 || folder.has_tags === true);
			setTagFolders(foldersWithTags);
		}
	}, [folders]);

	var initial_value,
		type,
		api_method,
		button = "";

	if (props.action == "edit") {
		initial_value = {
			name: props.data.value,
			parent_folder: props.data.type == "tag" ? props.data.parent_folder : "",
			has_tags: props.data.type == "folder" ? (props.data.has_tags === 1 || props.data.has_tags === true) : false,
		};
		type = "Editing " + props.data.value;
		api_method = "PUT";
		button = "Update";
	} else {
		initial_value = {
			name: "",
			parent_folder: "",
			has_tags: false,
		};
		type = "Create a " + props.data.type;
		api_method = "POST";
		button = "Create";
	}

	// Generate folder options for the parent_folder select
	const folderOptions = [
		{ label: "Residents", value: "Residents" },
		{ label: "General", value: "General" }
	];

	// Add any folders with has_tags=1 to the options
	tagFolders.forEach(folder => {
		if (folder.name !== "Residents" && folder.name !== "General") {
			folderOptions.push({ label: folder.name, value: folder.name });
		}
	});

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-50"
				onClose={() => dispatch(closeModal())}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-in-out duration-500"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in-out duration-500"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<ErrorBoundary>
					<div className="fixed inset-0 overflow-hidden">
						<div className="absolute inset-0 overflow-hidden">
							<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
								<Transition.Child
									as={Fragment}
									enter="transform transition ease-in-out duration-500 sm:duration-700"
									enterFrom="translate-x-full"
									enterTo="translate-x-0"
									leave="transform transition ease-in-out duration-500 sm:duration-700"
									leaveFrom="translate-x-0"
									leaveTo="translate-x-full"
								>
									<Dialog.Panel className="pointer-events-auto w-screen max-w-md">
										<div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
											<div className="flex min-h-0 flex-1 flex-col overflow-y-scroll bg-light_background">
												<div className="bg-dark_background py-6">
													<div className=" px-4 sm:px-6">
														<div className="flex items-start justify-between">
															<Dialog.Title className="text-lg font-medium text-white">
																{type}{" "}
															</Dialog.Title>
															<div className="ml-3 flex h-7 items-center">
																<button
																	type="button"
																	className="rounded-md text-white hover:text-gray-200"
																	onClick={() => dispatch(closeModal())}
																>
																	<span className="sr-only">Close panel</span>
																	<FontAwesomeIcon
																		className="h-6 w-6"
																		aria-hidden="true"
																		icon={faTimes}
																	/>
																</button>
															</div>
														</div>
													</div>
												</div>
												<div className="relative mt-6 flex-1 px-4 sm:px-6">
													<Formik
														initialValues={initial_value}
														validationSchema={Yup.object({
															name: Yup.string().required(
																"You need to type in a name"
															),
														})}
														onSubmit={async (
															values,
															{ setSubmitting, setErrors, resetForm }
														) => {
															setSubmitting(true);
															await axios({
																method: api_method,
																url:
																	process.env.REACT_APP_API_URL +
																	props.data.link,
																data: {
																	name: values.name,
																	parent_folder: values.parent_folder,
																	added_by: current_staff?.id,
																	has_tags: props.data.type == "folder" ? values.has_tags : undefined,
																},
															})
																.then(() => {
																	toast(
																		<NotificationToast
																			title={values.name + " saved"}
																			message={
																				"This " +
																				props.data.type +
																				" will be usable by all users."
																			}
																		/>
																	);
																})
																.catch((error) => {
																	toast(
																		<NotificationToast
																			title="Something went wrong"
																			message={error.response}
																			type="error"
																		/>
																	);
																})
																.finally(() => {
																	if (props.callback == "getTemplates") {
																		dispatch(getTemplates());
																	} else if (
																		props.callback == "getTemplateCategories"
																	) {
																		dispatch(getTemplateCategories());
																	} else if (props.callback == "getTags") {
																		dispatch(getTags());
																	} else if (props.callback == "getFolders") {
																		dispatch(getFolders());
																	} else if (
																		props.callback == "getCollections"
																	) {
																		dispatch(getCollections());
																	} else if (
																		props.callback == "getAppointments"
																	) {
																		dispatch(getAppointments());
																	} else if (props.callback == "getRenewals") {
																		dispatch(getRenewals());
																	} else {
																		dispatch(props.callback);
																	}

																	dispatch(closeModal());

																	resetForm();
																});
														}}
													>
														{({
															  values,
															  errors,
															  onChange,
															  isValid,
															  dirty,
															  handleSubmit,
															  setFieldValue,
															  isSubmitting,
														  }) => (
															<Form
																onSubmit={handleSubmit}
																className="space-y-8"
															>
																<SettingsTextInput
																	name="name"
																	placeholder="Name"
																	errors={errors.name}
																	value={values.name}
																/>

																{props.data.bookings_count !== undefined && (
																	<p className="mt-2">Bookings = {props.data.bookings_count}</p>
																)}

																{(props.data.type == "tag" ||
																	props.data.type == "Tag") && (
																	<SettingsSelect
																		label="Parent Folder"
																		name="parent_folder"
																		value={values.parent_folder}
																		error={errors.parent_folder}
																		options={folderOptions}
																	/>
																)}

																{(props.data.type == "folder") && (
																	<div className="mt-4">
																		<label className="flex items-center">
																			<input
																				type="checkbox"
																				name="has_tags"
																				checked={values.has_tags}
																				onChange={(e) => setFieldValue("has_tags", e.target.checked)}
																				className="h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded"
																			/>
																			<span className="ml-2 text-sm text-gray-700">
																				Has Tags
																			  </span>
																		</label>
																		<p>This will appear as a new group on the left sidebar</p>
																	</div>
																)}

																<div className="flex flex-shrink-0 space-x-4 justify-end px-4 py-4">
																	<CustomButton
																		type="button"
																		onClick={() => dispatch(closeModal())}
																		text="Cancel"
																	/>
																	<SubmitButton
																		name="Submit"
																		disabled={
																			!isValid || isSubmitting || !dirty
																		}
																		isSubmitting={isSubmitting}
																		text={button}
																	/>
																</div>
															</Form>
														)}
													</Formik>
												</div>
											</div>
										</div>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</div>
				</ErrorBoundary>
			</Dialog>
		</Transition.Root>
	);
}