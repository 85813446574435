import React, { useEffect, Fragment, useState } from "react";
import { closeModal } from "../../store/modal/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Dialog, Transition, Listbox, Menu } from "@headlessui/react";
import ErrorBoundary from "../../containers/ErrorBoundary";
import { Formik, Form, Field } from "formik";
import SubmitButton from "../Forms/SubmitButton";
import SettingsTextInput from "../Forms/SettingsTextInput";
import SettingsSelect from "../Forms/SettingsSelect";
import CustomButton from "../Helpers/CustomButton";
import Select from "react-select";
import { Link } from "react-router-dom";
import { getBooking } from "../../store/booking/bookingActions";
import {
	faTableLayout,
	faPlus,
	faLoader,
} from "@fortawesome/pro-regular-svg-icons";
import { openModal } from "../../store/modal/modalReducer";
import { toast } from "react-toastify";
import NotificationToast from "../Helpers/NotificationToast";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import $ from "jquery";

export default function ModalProprBed(props) {
	const dispatch = useDispatch();
	const { current_staff } = useSelector((state) => state.auth);
	const [open] = useState(true);
	const [loaded, setLoaded] = useState(false);
	const [unlinking, setUnlinking] = useState(false);
	const [bed, setBed] = useState({});
	const [units, setUnits] = useState([]);
	const [unitTypes, setUnitTypes] = useState([]);
	const { settings } = useSelector((state) => state.settings);
	const [chosenUnit, setChosenUnit] = useState(
		$(props.thisbed).attr("unitid") > 0
			? parseInt($(props.thisbed).attr("unitid"))
			: 0
	);
	const [bedList, setBedList] = useState([]);
	const [thisType, setThisType] = useState(
		$(props.thisbed).attr("bedid") > 0 ? 1 : 0
	);
	const [bookings, setBookings] = useState([]);
	const [waitlist, setWaitlist] = useState([]);
	const [bedDelinked, setBedDelinked] = useState(false);

	const [sendingMessages, setSendingMessages] = useState(false);
	const [showMessageSection, setShowMessageSection] = useState(false);
	const [messageToSend, setMessageToSend] = useState("");
	const [messageToSendId, setMessageToSendId] = useState(0);
	const [wiFiLoading, setWiFiLoading] = useState(false);
	const [updateInfo, setUpdateInfo] = useState(true);
	const [years, setYears] = useState(0);
	const [allPricing, setAllPricing] = useState([]);
	const [auditHistory, setAuditHistory] = useState([]);
	const [showAuditHistory, setShowAuditHistory] = useState(false);
	const [loadingAudits, setLoadingAudits] = useState(false);

	const { whatsapp_templates } = useSelector((state) => state.templates);
	const activeYear = props.activeYear;

	const [initialValues, setInitialValues] = useState({
		form_type: $(props.thisbed).attr("bedid") > 0 ? 1 : 0,
		unit_type_id: 0,
		price: 0,
		bed_deposit: 0,
		name: "",
		unit_id:
			$(props.thisbed).attr("unitid") > 0
				? parseInt($(props.thisbed).attr("unitid"))
				: 0,
		bed_price_nsfas: 0,
		bed_status: $(props.thisbed).hasClass("blocked") ? 1 : 0,
		bed_deposit_nsfas: 0,
		id:
			$(props.thisbed).attr("bedid") > 0
				? parseInt($(props.thisbed).attr("bedid"))
				: 0,
		property_id: props.property_id,
		floor_id: props.floor_id,
		gender: 0,
		allowed_type: 0,
		wifi: null,
		pricing_year: 0,
		full_price: [],
	});

	useEffect(async () => {
		if (props.property_id > 0 && props.floor_id > 0 && units.length === 0) {
			axios({
				method: "get",
				url: `${process.env.REACT_APP_API_URL}get_bed_info_new/${props.property_id}/${props.floor_id}/${thisType}`,
			})
				.then((response) => {
					//   setBed( response.data.bed );

					setUnits(response.data.units);
					setUnitTypes(response.data.unit_types);
				})
				.catch((error) => {
					alert("units load failed");
				});
		}

		axios({
			method: "get",
			url: `${process.env.REACT_APP_API_URL}get_years`,
		})
			.then((response) => {
				let dbYears = response.data;
				dbYears.push({ label: "All years", value: 0 });
				dbYears.sort((a, b) => a.value - b.value);
				setYears(dbYears);
			})
			.catch((error) => {
				alert("year load failed");
			});

		//Type Bed
		if (thisType === 1) {
			if (units.length > 0 && chosenUnit > 0) {
				// console.log("get beds");

				axios({
					method: "get",
					url: `${process.env.REACT_APP_API_URL}get_beds_by_unit/${chosenUnit}`,
				})
					.then((response) => {
						setBedList(response.data);
					})
					.catch((error) => {
						alert("beds for unit load failed");
					});
			}

			if (initialValues.id > 0 && updateInfo === true) {
				setUpdateInfo(false);

				axios({
					method: "get",
					url: `${process.env.REACT_APP_API_URL}get_bed_info/${initialValues.id}`,
				})
					.then((response) => {
						setAllPricing(response.data.full_price);

						let pricings = response.data.full_price;
						let indexForYear = 0;
						let price_all = true;

						let price = 0;
						let bed_deposit = 0;
						let bed_booking_fee_pvt = 0;
						let bed_price_nsfas = 0;
						let bed_deposit_nsfas = 0;
						let bed_booking_fee_nsfas = 0;

						if (pricings.length > 0) {
							pricings.map((price) => {
								if (price.year_id == settings.year_id) {
									price_all = false;
									indexForYear = price.year_id;

									bed_price_nsfas = price.nsfas_price;
									bed_deposit_nsfas = price.nsfas_deposit;
									bed_booking_fee_nsfas = price.nsfas_booking_fee;

									bed_deposit = price.private_deposit;
									bed_booking_fee_pvt = price.private_booking_fee;
									price = price.private_price;
								}
							});

							if (price_all === true) {
								pricings.map((price) => {
									if (price.year_id == 0) {
										indexForYear = price.year_id;

										bed_price_nsfas = price.nsfas_price;
										bed_deposit_nsfas = price.nsfas_deposit;
										bed_booking_fee_nsfas = price.nsfas_booking_fee;

										bed_deposit = price.private_deposit;
										bed_booking_fee_pvt = price.private_booking_fee;
										price = price.private_price;
									}
								});
							}
						}

						let bedData = response.data;
						bedData.pricing_year = indexForYear;

						bedData.nsfas_price = bed_price_nsfas;
						bedData.nsfas_deposit = bed_deposit_nsfas;
						bedData.bed_booking_fee_nsfas = bed_booking_fee_nsfas;
						bedData.bed_booking_fee = bed_booking_fee_pvt;

						bedData.private_price = price;
						bedData.private_deposit = bed_deposit;

						setInitialValues(bedData);
					})
					.catch((error) => {
						alert("bed load failed");
					});

				axios({
					method: "get",
					url: `${process.env.REACT_APP_API_URL}get_bed_bookings/${initialValues.id}/2/${activeYear}`,
				})
					.then((response) => {
						let allres = response.data;

						allres.forEach(function (x) {
							x.bed = { id: initialValues.id };
						});

						setBookings(response.data);
					})
					.catch((error) => {
						console.log(error);
					});

				axios({
					method: "get",
					url: `${process.env.REACT_APP_API_URL}get_bed_bookings/${initialValues.id}/3/${activeYear}`,
				})
					.then((response) => {
						let allres = response.data;

						allres.forEach(function (x) {
							x.bed = { id: initialValues.id };
						});

						// console.log(response.data)
						setWaitlist(response.data);
					})
					.catch((error) => {
						console.log(error);
					});
			}

			//Type Unit
		} else {
			if (chosenUnit > 0) {
				axios({
					method: "get",
					url: `${process.env.REACT_APP_API_URL}get_unit_info/${chosenUnit}`,
				})
					.then((response) => {
						setInitialValues(response.data);
						setLoaded(true);
					})
					.catch((error) => {
						console.log(error);
						alert("bed load failed");
					});
			}
		}
	}, [chosenUnit, units, bedDelinked, updateInfo]);

	const loadAuditHistory = async () => {
		if (!showAuditHistory && initialValues.id > 0) {
			setLoadingAudits(true);
			try {
				const response = await axios({
					method: 'get',
					url: `${process.env.REACT_APP_API_URL}bed/audit-history/${initialValues.id}`,
				});
				setAuditHistory(response.data);
			} catch (error) {
				console.error('Failed to load audit history:', error);
				toast(
					<NotificationToast
						title="Error"
						message="Failed to load audit history"
						type="error"
					/>
				);
			}
			setLoadingAudits(false);
		}
		setShowAuditHistory(!showAuditHistory);
	};

	const options_bed_status = [
		{ value: 0, label: "Available" },
		{ value: 1, label: "Blocked" },
		{ value: 2, label: "Blocked - Yellow" },
	];

	let options_type = [
		{ value: 0, label: "Unit" },
		{ value: 1, label: "Bed" },
	];

	const options_gender = [
		{ value: 0, label: "Specified by Floor" },
		{ value: 1, label: "Male" },
		{ value: 2, label: "Female" },
	];

	const options_allowed_types = [
		{ value: 0, label: "All" },
		{ value: 1, label: "NSFAS Only" },
		{ value: 2, label: "Private/Bursary Only" },
	];

	const customStyles = {
		control: (provided, state) => ({
			...provided,
			boxShadow: "none",
			border:
				state.isFocused && "none" ? "1px solid #9F7D38" : "1px solid #d1d5db",
			cursor: "pointer",
			"&:hover": {
				border: state.isFocused ? "1px solid #9F7D38" : "1px solid #d1d5db",
				cursor: "pointer",
			},
			borderRadius: "10px",
			padding: 0,
		}),
		container: (provided, state) => ({
			...provided,
			padding: 0,
			border: 0,
		}),
		dropdownIndicator: (provided, state) => ({
			...provided,
			color: "#e7ebe5",
		}),
	};

	function unlinkBed() {
		if (window.confirm("Are you sure?")) {
			setUnlinking(true);

			if (
				$(props.thisbed).attr("bedid") > 0 ||
				$(props.thisbed).hasClass("mapped")
			) {
				$(props.thisbed).attr("class", "");
				$(props.thisbed).attr("fill", "rgb(228,228,228)");
				$(props.thisbed).removeAttr("bedid");
				$(props.thisbed).removeAttr("unitid");
				$(props.thisbed).removeAttr("id");

				//Save SVG
				let map_svg = $(".map_svg").html();

				axios({
					method: "post",
					url: `${process.env.REACT_APP_API_URL}save_svg_map/${props.floor_id}`,
					data: {
						map: map_svg,
					},
				})
					.then((response) => {
						window.location.reload();
					})
					.catch((error) => {
						alert("failed to save map");
						console.log(error);
					});
			} else if ($(props.thisbed).hasClass("room")) {
				$(props.thisbed).attr("class", "");
				$(props.thisbed).removeAttr("unitid");
				$(props.thisbed).removeAttr("id");

				//Save SVG
				let map_svg = $(".map_svg").html();

				axios({
					method: "post",
					url: `${process.env.REACT_APP_API_URL}save_svg_map/${props.floor_id}`,
					data: {
						map: map_svg,
					},
				})
					.then((response) => {
						window.location.reload();
					})
					.catch((error) => {
						alert("failed to save map");
						console.log(error);
					});
			}
		}
	}

	function sendMessageShow(show) {
		if (show === true) {
			setShowMessageSection(false);
			setMessageToSend("");
			setMessageToSendId(0);
		} else {
			setShowMessageSection(true);
		}
	}

	async function sendMessage() {
		let fullSendList = bookings.concat(waitlist);

		let sendListNew = fullSendList.filter(function (sl) {
			if (sl?.user.cell !== "") {
				return true;
			}
		});

		if (messageToSendId > 0) {
			setSendingMessages(true);

			async function processArray(array) {
				for (const item of array) {
					console.log("sending " + item.user.name + " " + item.user.surname);

					const the_message = messageToSend;
					var template = messageToSendId;
					let replaced_message = messageToSend;

					replaced_message = the_message.replace("[name]", item.user.name);
					replaced_message = replaced_message.replace(
						"[surname]",
						item.user.surname
					);
					replaced_message = replaced_message.replace(
						"[email]",
						item.user.email
					);

					var form_data = new FormData();

					var timestamp = new Date();
					var platform = "whatsapp";
					var from = current_staff?.id;
					var user_id = item.user.id;
					var to = item.user.cell;

					form_data.append("timestamp", timestamp);
					form_data.append("message", replaced_message);
					form_data.append("platform", platform);
					form_data.append("from", from);
					form_data.append("booking", item.id);
					form_data.append("to", to);
					form_data.append("user_id", user_id);
					form_data.append("staff_id", current_staff?.id);
					form_data.append("template", template);

					await axios
						.post(`${process.env.REACT_APP_API_URL}send`, form_data)
						.then((response) => {
							toast(
								<NotificationToast
									title={"whatsapp successful"}
									message={"Your message has been sent."}
								/>
							);
						})
						.catch((error) => {
							console.log(error);
							toast(
								<NotificationToast
									title="Something went wrong"
									message={JSON.stringify(error.response.data)}
									type="error"
								/>
							);
						});
				}

				return true;
			}

			await processArray(sendListNew);

			setSendingMessages(false);
			setMessageToSend("");
			setMessageToSendId(0);
			setShowMessageSection(false);
		} else {
			alert("Please Choose a template to send");
		}
	}

	function delinkBed(bed_id) {
		setBedDelinked(true);
		props.setMappedBeds(true);
		axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}delink_user_bed`,
			data: {
				booking_id: bed_id,
				user: current_staff,
				move_location: 1,
			},
		})
			.then((response) => {
				// dispatch(getBooking(props.current_booking.id));
				// setChosenBed(0);
				// dispatch(closeModal());
				setBedDelinked(false);
				props.setMappedBeds(false);
				// console.log(response)
			})
			.catch((error) => {
				alert("properties failed");
			});
	}

	async function createWifiAccount(bed_id) {
		setWiFiLoading(true);

		await axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}wifi-create-account-bed`,
			data: { bed_id: bed_id },
		})
			.then((response) => {
				toast(
					<NotificationToast
						title={"WIFI Account Created"}
						message={"WiFi Created"}
					/>
				);
			})
			.catch((response) => {
				toast(
					<NotificationToast
						title={"WIFI Issue"}
						message={response.data}
						type="error"
					/>
				);
			})
			.finally(() => {
				setWiFiLoading(false);
				setUpdateInfo(true);
			});
	}

	const updateValues = (data, fieldUpdate, years) => {
		let year_found = false;
		let allYearsInfo = [];

		if (years && years.length > 0) {
			years.map((year) => {
				if (data === year.year_id) {
					year_found = true;

					fieldUpdate("bed_price_nsfas", year.nsfas_price);
					fieldUpdate("bed_deposit_nsfas", year.nsfas_deposit);

					fieldUpdate("price", year.private_price);
					fieldUpdate("bed_deposit", year.private_deposit);
				}

				if (year.year_id === 0) {
					allYearsInfo.push(year.nsfas_price);
					allYearsInfo.push(year.nsfas_deposit);
					allYearsInfo.push(year.private_price);
					allYearsInfo.push(year.private_deposit);
				}
			});
		}

		if (year_found === false) {
			if (allYearsInfo.length > 0 && data === 0) {
				fieldUpdate("bed_price_nsfas", allYearsInfo[0]);
				fieldUpdate("bed_deposit_nsfas", allYearsInfo[1]);

				fieldUpdate("price", allYearsInfo[2]);
				fieldUpdate("bed_deposit", allYearsInfo[3]);
			} else {
				fieldUpdate("bed_price_nsfas", 0);
				fieldUpdate("bed_deposit_nsfas", 0);

				fieldUpdate("price", 0);
				fieldUpdate("bed_deposit", 0);
			}
		}
	};

	async function priceByUnit(bed_id) {
		await axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}change_price_by_unit`,
			data: { bed_id: bed_id },
		})
			.then((response) => {
				toast(
					<NotificationToast
						title={"Pricing updated"}
						message={"Pricing changed to per unit"}
					/>
				);
			})
			.catch((response) => {
				toast(<NotificationToast title={"Pricing Error"} type="error" />);
			});
	}

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				static
				className="fixed z-50 inset-0 overflow-y-auto"
				open={open}
				onClose={() => dispatch(closeModal())}
			>
				<div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span
						className="hidden sm:inline-block sm:align-middle sm:h-screen"
						aria-hidden="true"
					>
            &#8203;
          </span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<ErrorBoundary>
							<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full sm:p-6">
								<div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
									<CustomButton
										type="secondary"
										className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
										onClick={() => dispatch(closeModal())}
									>
										Close
									</CustomButton>
								</div>
								<div className="pr-8 w-full">
									<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
										<Dialog.Title
											as="h1"
											className="text-lg font-medium text-gray-900"
										>
											<div className="flex justify-between">
                        <span>
                          {current_staff?.status === "Admin" ? "Edit" : "View"}{" "}
							Unit/Bed
                        </span>

												{!current_staff?.building_manager && (
													<CustomButton
														type="alert"
														text="Send whatsapp"
														onClick={() => sendMessageShow(showMessageSection)}
													/>
												)}

												{current_staff?.status === "Admin" &&
													(initialValues.id > 0 ||
														initialValues.unit_id > 0) && (
														<CustomButton
															type="alert"
															text={
																unlinking === true
																	? "Please wait..."
																	: "Unlink on Map"
															}
															onClick={() => unlinkBed()}
														/>
													)}
											</div>
											<div>
												{showMessageSection === true ? (
													<div className="flex justify-between pr-6 bg-dark_background pt-8 pb-28 mt-5">
														<div className="flex pl-6 space-x-4 h-8 flex-row-reverse">
															{/* <BookingTemplate platform={'whatsapp'} className="ml-4"/> */}

															<div>
																<Menu
																	as="div"
																	className="z-10 relative inline-block text-left bg-primary px-2 -mt-6 self-center py-1 rounded-xl hover:bg-primary_hover transition duration-150 ease-in-ease-out hover:-translate-y-0.5 ml-5"
																>
																	<div>
																		<Menu.Button className="rounded-full flex items-center text-white hover:cursor-pointer ">
                                      <span className="sr-only">
                                        Open options
                                      </span>
																			<FontAwesomeIcon
																				className="h-5 w-5"
																				aria-hidden="true"
																				icon={faTableLayout}
																			/>
																		</Menu.Button>
																	</div>

																	<Transition
																		as={Fragment}
																		enter="transition ease-out duration-100"
																		enterFrom="transform opacity-0 scale-95"
																		enterTo="transform opacity-100 scale-100"
																		leave="transition ease-in duration-75"
																		leaveFrom="transform opacity-100 scale-100"
																		leaveTo="transform opacity-0 scale-95"
																	>
																		<Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-80 whitespace-nowrap h-80 overflow-scroll rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
																			<div>
																				<p className="text-center p-4 bg-dark_background text-white focus:outline-none">
																					System Approved Templates
																				</p>
																				{whatsapp_templates.length > 0 &&
																					whatsapp_templates.map((template) => {
																						return (
																							<Menu.Item key={template.id}>
																								<button
																									type="submit"
																									className="whitespace-nowrap w-min hover:bg-gray-200 hover:border-gray-200 block px-4 py-2 text-sm"
																									onClick={() => {
																										setMessageToSend(
																											template.message
																										);
																										setMessageToSendId(
																											template.id
																										);
																									}}
																								>
																									<p className="text-left font-medium">
																										{template.title}
																									</p>
																									<p className=" w-full text-gray-500 ">
																										{template.message}
																									</p>
																								</button>
																							</Menu.Item>
																						);
																					})}
																			</div>
																		</Menu.Items>
																	</Transition>
																</Menu>

																<button
																	type="button"
																	className="text-white uppercase items-center px-4 py-2 rounded-lg text-sm bg-primary hover:bg-primary_hover focus:ring-primary ml-5 mt-12"
																	onClick={() => sendMessage()}
																>
																	{sendingMessages === true ? (
																		<>
																			<FontAwesomeIcon
																				className="w-4 h-4 animate-spin"
																				icon={faLoader}
																			/>
																			<span className="w-full">Loading</span>
																		</>
																	) : (
																		<span className="w-full">Send</span>
																	)}
																</button>
															</div>
															<textarea
																cols={100}
																defaultValue={messageToSend}
																className="px-6 py-4 block sm:text-sm !outline-none resize-none bg-light_background h-28 w-full"
																disabled
															></textarea>
														</div>
													</div>
												) : (
													""
												)}
											</div>
										</Dialog.Title>

										<Formik
											initialValues={initialValues}
											// validate={(values) => {
											// 	const errors = {};
											//
											// 	if (
											// 		values.form_type === 1 &&
											// 		(values.id > 0 || values.name !== "")
											// 	) {
											// 		if (values.price === "") {
											// 			errors.price = "Price needs to be a number";
											// 		}
											// 		if (values.bed_price_nsfas === "") {
											// 			errors.bed_price_nsfas =
											// 				"NSFAS Price needs to be a number";
											// 		}
											// 		if (values.bed_deposit === "") {
											// 			errors.bed_deposit =
											// 				"Bed Deposit needs to be a number";
											// 		}
											// 		if (values.bed_deposit_nsfas === "") {
											// 			errors.bed_deposit_nsfas =
											// 				"NSFAS Bed Deposit needs to be a number";
											// 		}
											// 	}
											//
											// 	return errors;
											// }}
											// validationSchema={Yup.object().shape({
											//   price: Yup.number().required(),
											//   bed_deposit: Yup.number().required(),
											//   bed_price_nsfas: Yup.number().required(),
											//   bed_deposit_nsfas: Yup.number().required(),
											//   bed_status: Yup.number().required(),
											// })}
											enableReinitialize="true"
											onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
												setSubmitting(true);
												try {
													// Initialize update_bulk array
													let update_bulk = [];

													// First save the bed data
													const response = await axios({
														method: "post",
														url: `${process.env.REACT_APP_API_URL}save_new_bed`,
														data: values,
													});

													if (values.form_type === 1 && values.id === -1) {
														values.id = response.data;
													}

													if (values.form_type === 0 && values.unit_id === -1) {
														values.unit_id = response.data;
													}

													// Update SVG classes and attributes
													$(props.thisbed).attr("class", "");

													if (values.form_type === 1) {
														if (values.bed_status === 1) {
															$(props.thisbed).addClass("blocked");
														} else if (values.bed_status === 2) {
															$(props.thisbed).addClass("blocked");
															$(props.thisbed).addClass("yellow");
														} else {
															$(props.thisbed).removeClass("blocked");
														}

														$(props.thisbed).attr("bedid", values.id);
														$(props.thisbed).attr("id", "bed_" + values.id);
														$(props.thisbed).attr("style", "");

														let cls_add = "";
														let cls_step = "";

														if (values.unit_type_id > 0) {
															cls_add += cls_step + "room_type_" + values.unit_type_id;
															cls_step = " ";
														}

														if (values.gender > 0) {
															cls_add += cls_step + "gender_" + values.gender;
															cls_step = " ";
														}

														if (values.allowed_type > 0) {
															cls_add += cls_step + "allowed_type_" + values.allowed_type;
															cls_step = " ";
														}

														$(props.thisbed).addClass("mapped " + cls_add);
													} else {
														$(props.thisbed).addClass("room");
														$(props.thisbed).attr("id", "unit_" + values.unit_id);

														// Bulk bed feature - only for units
														$("[unitid='" + values.unit_id + "']").each(function () {
															let bed_id = parseInt($(this).attr("bedid"));

															if (bed_id > 0) {
																$(this).addClass("allowed_type_" + values.allowed_type);
																update_bulk.push({
																	bed_id: bed_id,
																	allowed_type: values.allowed_type,
																});
															}
														});
													}

													$(props.thisbed).attr("unitid", values.unit_id);

													// Save SVG map
													const map_svg = $(".map_svg").html();
													await axios({
														method: "post",
														url: `${process.env.REACT_APP_API_URL}save_svg_map/${props.floor_id}`,
														data: {
															map: map_svg,
															update_bulk: update_bulk,
														},
													});

													// Use the updateBed function from props to update state
													await props.updateBed(values);

													// Close the modal
													dispatch(closeModal());

												} catch (error) {
													console.error("Failed to save:", error);
													setErrors({ submit: "Failed to save changes" });
												} finally {
													setSubmitting(false);
												}
											}}
										>
											{({
												  values,
												  touched,
												  errors,
												  setFieldValue,
												  setFieldTouched,
												  handleSubmit,
												  isSubmitting,
												  isValid,
												  dirty,
											  }) => (
												<Form
													onSubmit={handleSubmit}
													className="space-y-8 m-4 w-full "
												>
													{Object.keys(errors).map((key) => (
														<li
															key={key}
															className="text-red-400 col-span-1 text-sm font-light"
														>
															{errors[key]}
														</li>
													))}
													<div className="flex space-x-4">
														{current_staff?.status === "Admin" && (
															<div className="w-1/2">
																<div className="flex space-x-4 mb-6">
																	<div className="w-1/2 space-y-6">
																		<div>
																			<label
																				htmlFor="unit_id"
																				className="text-sm font-medium text-gray-700"
																			>
																				What is this?
																			</label>
																			<Select
																				id="form_type"
																				name="form_type"
																				options={options_type}
																				onChange={(val) => {
																					setFieldValue("form_type", val.value);
																					setThisType(val.value);
																				}}
																				onBlur={setFieldTouched}
																				value={
																					options_type &&
																					options_type.length > 0
																						? options_type.find(
																							(option) =>
																								option.value ===
																								values.form_type
																						)
																						: ""
																				}
																				components={{
																					IndicatorSeparator: () => null,
																				}}
																				styles={customStyles}
																				className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
																			/>
																			<p className="text-red-400 text-sm">
																				{errors.unit_id}
																			</p>
																		</div>
																	</div>

																	<div className="w-1/2 space-y-6">
																		<div>
																			<label
																				htmlFor="unit_id"
																				className="text-sm font-medium text-gray-700"
																			>
																				Unit
																			</label>
																			<Select
																				id="unit_id"
																				name="unit_id"
																				options={units}
																				onChange={(val) => {
																					setFieldValue("unit_id", val.value);
																					setChosenUnit(val.value);
																				}}
																				onBlur={setFieldTouched}
																				value={
																					units && units.length > 0
																						? units.find(
																							(option) =>
																								option.value ===
																								parseInt(values.unit_id)
																						)
																						: ""
																				}
																				components={{
																					IndicatorSeparator: () => null,
																				}}
																				styles={customStyles}
																				className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
																			/>
																			<p className="text-red-400 text-sm">
																				{errors.unit_id}
																			</p>
																		</div>
																	</div>
																</div>
																<div className="flex space-x-4 mb-6">
																	<div className="w-1/2 space-y-6">
																		{values.form_type === 1 && (
																			<SettingsSelect
																				label="Bed Pricing"
																				name="unit_type_id"
																				onChange={setFieldValue}
																				onBlur={setFieldTouched}
																				value={values.unit_type_id}
																				touched={touched.unit_type_id}
																				error={errors.unit_type_id}
																				options={unitTypes}
																			/>
																		)}
																	</div>

																	<div className="w-1/2 space-y-6">
																		{values.form_type === 0 ||
																			(values.form_type === 2 && (
																				<SettingsSelect
																					label="Gender"
																					name="gender"
																					onChange={setFieldValue}
																					onBlur={setFieldTouched}
																					value={values.gender}
																					touched={touched.gender}
																					error={errors.gender}
																					options={options_gender}
																				/>
																			))}

																		<SettingsSelect
																			label="Allowed Types"
																			name="allowed_type"
																			onChange={setFieldValue}
																			onBlur={setFieldTouched}
																			value={values.allowed_type}
																			touched={touched.allowed_type}
																			error={errors.allowed_type}
																			options={options_allowed_types}
																		/>
																	</div>
																</div>

																{((values.form_type === 1 &&
																		values.id === -1) ||
																	(values.form_type === 0 &&
																		values.unit_id === -1)) && (
																	<Field
																		name="name"
																		type="text"
																		as={SettingsTextInput}
																		label={
																			values.form_type === 1
																				? "Bed Name"
																				: "Unit Name"
																		}
																	/>
																)}

																{values.form_type === 1 &&
																	values.unit_id > 0 && (
																		<div className="flex space-x-4 mb-6">
																			<div className="w-1/2 space-y-6">
																				<SettingsSelect
																					label="Bed"
																					name="id"
																					onChange={setFieldValue}
																					onBlur={setFieldTouched}
																					value={values.id}
																					touched={touched.id}
																					error={errors.id}
																					options={bedList}
																				/>
																			</div>

																			<div className="w-1/2 space-y-6"></div>
																		</div>
																	)}

																{values &&
																values.full_price &&
																values.full_price.length > 0 ? (
																	<>
																		<p className="font-bold mb-4 text-blue-600">
																			Priced By Bed
																		</p>
																		<CustomButton
																			type="secondary"
																			text="Change to Priced By Unit"
																			onClick={(event) => {
																				event.preventDefault();
																				priceByUnit(values.id);
																				dispatch(closeModal());
																			}}
																		/>
																	</>
																) : (
																	<p className="font-bold mb-4 text-blue-600">
																		Priced By Unit Type
																	</p>
																)}

																{values &&
																values.full_price &&
																values.full_price.length > 0 ? (
																	<div className="mb-5">
																		<p className="mr-2 mt-4">
																			<b>Below Pricing is Active for </b>
																		</p>
																		<SettingsSelect
																			name="pricing_year"
																			onChange={setFieldValue}
																			onBlur={setFieldTouched}
																			updateValues={(data) =>
																				updateValues(
																					data,
																					setFieldValue,
																					allPricing
																				)
																			}
																			value={values.pricing_year}
																			touched={touched.pricing_year}
																			error={errors.pricing_year}
																			options={years}
																		/>
																	</div>
																) : (
																	""
																)}

																{values.form_type === 1 &&
																	values.unit_id > 0 &&
																	values.id !== 0 && (
																		<>
																			<div className="flex space-x-4">
																				<div className="w-1/2 space-y-6">
																					<Field
																						name="price"
																						type="text"
																						as={SettingsTextInput}
																						label="BED PRICE (PRIVATE/BURSARY)"
																						prepend="R"
																					/>
																					<Field
																						name="bed_deposit"
																						type="text"
																						as={SettingsTextInput}
																						label="BED DEPOSIT (PRIVATE/BURSARY)"
																						prepend="R"
																					/>

																					<Field
																						name="bed_booking_fee"
																						type="text"
																						as={SettingsTextInput}
																						label="BED BOOKING FEE (PRIVATE/BURSARY)"
																						prepend="R"
																					/>

																					<SettingsSelect
																						label="Bed Status"
																						name="bed_status"
																						onChange={setFieldValue}
																						onBlur={setFieldTouched}
																						value={values.bed_status}
																						touched={touched.bed_status}
																						error={errors.bed_status}
																						options={options_bed_status}
																					/>
																				</div>

																				<div className="w-1/2 space-y-6">
																					<Field
																						name="bed_price_nsfas"
																						type="text"
																						as={SettingsTextInput}
																						label="BED PRICE (NSFAS)"
																						prepend="R"
																					/>

																					<Field
																						name="bed_deposit_nsfas"
																						type="text"
																						as={SettingsTextInput}
																						label="BED DEPOSIT (NSFAS)"
																						prepend="R"
																					/>

																					<Field
																						name="bed_booking_fee_nsfas"
																						type="text"
																						as={SettingsTextInput}
																						label="BED BOOKING FEE (NSFAS)"
																						prepend="R"
																					/>
																				</div>
																			</div>
																			<div className="text-center my-6">
																				Bed ID: {values.id}
																			</div>
																		</>
																	)}

																{/* <div>
                                                                <SvgLoader svgXML={mp}>

                                                                </SvgLoader>
                                                            </div> */}

																<div className="flex space-x-6">
																	<SubmitButton
																		name="Save"
																		disabled={
																			!isValid || isSubmitting || !dirty
																		}
																		isSubmitting={isSubmitting}
																		text="Save"
																	/>
																	<CustomButton
																		type="secondary"
																		text="Close"
																		onClick={() => dispatch(closeModal())}
																	/>
																</div>
															</div>
														)}

														<div className="w-1/2">
															<h2 className="text-lg font-medium text-gray-900 mb-4">
																Booking History
															</h2>
															{bookings &&
																bookings.length > 0 &&
																bookings?.map((b) => (
																	<div className="mb-4">
																		<Link
																			to={
																				b.declined
																					? "/booking/declined?user_id=" +
																					(b?.user ? b?.user.id : "") +
																					"&booking_id=" +
																					(b?.id ? b?.id : "")
																					: b.tag
																						? "/booking/management?tag=" +
																						b.tag +
																						"&user_id=" +
																						(b?.user ? b?.user.id : "") +
																						"&booking_id=" +
																						(b?.id ? b?.id : "")
																						: "/booking/" +
																						b.location
																							?.toLowerCase()
																							.replace(/\s/g, "") +
																						"?user_id=" +
																						(b?.user ? b?.user.id : "") +
																						"&booking_id=" +
																						(b?.id ? b?.id : "")
																			}
																			onClick={() => {
																				dispatch(getBooking(b.id));
																				dispatch(closeModal());
																			}}
																		>
																			<div key={b.id}>
																				{b.booking_reference} {b.user?.name}
																				<br />
																				Booked: {b.created_at}
																				<br />
																				Paid:{" "}
																				{b.rates?.length > 0
																					? b.rates[b.rates.length - 1].paid ===
																					1
																						? "Yes"
																						: "No"
																					: "No"}
																				<br />
																				Lease Signed:{" "}
																				{b.rates?.length > 0
																					? b.rates[b.rates.length - 1]
																						.signed_lease_at !== ""
																						? b.rates[b.rates.length - 1]
																							.signed_lease_at
																						: "No"
																					: "No"}
																				<br />
																				Gender:{" "}
																				{b.user?.profile?.gender
																					? b.user.profile.gender === 1
																						? "Male"
																						: " "
																					: " "}
																				{b.user?.profile?.gender
																					? b.user.profile.gender === 2
																						? "Female"
																						: " "
																					: " "}
																				<br />
																			</div>
																		</Link>
																		{current_staff?.status === "Admin" && (
																			<>
																				<button
																					type="button"
																					className="bg-dark_background hover:bg-dark_background focus:ring-dark_background text-white px-2 py-1 rounded-lg"
																					onClick={() => delinkBed(b.id)}
																				>
																					De-link
																				</button>
																				<button
																					type="button"
																					className="bg-dark_background hover:bg-dark_background focus:ring-dark_background text-white px-2 py-1 rounded-lg ml-2"
																					onClick={() =>
																						dispatch(
																							openModal({
																								modal_type:
																									"ModalBedAssignment",
																								modal_props: {
																									current_booking: b,
																								},
																							})
																						)
																					}
																				>
																					Allocate New Bed
																				</button>
																			</>
																		)}
																	</div>
																))}
														</div>
														<div className="w-1/2">
															<div>
																<div className="flex justify-between items-center mb-4">
																	<h2 className="text-lg font-medium text-gray-900">Bed History</h2>
																	<CustomButton
																		type="button"
																		text={showAuditHistory ? "Hide History" : "Show History"}
																		onClick={loadAuditHistory}
																		loading={loadingAudits}
																	/>
																</div>

																{showAuditHistory && (
																	<>
																		{loadingAudits ? (
																			<div className="text-center py-4">
																				<FontAwesomeIcon
																					icon={faLoader}
																					spin
																					className="h-8 w-8 text-gray-400"
																				/>
																			</div>
																		) : (
																			<>
																				{auditHistory.length > 0 ? (
																					<div className="space-y-4">
																						{auditHistory.map((audit) => (
																							<div key={audit.id} className="p-4 bg-gray-50 rounded-lg border border-gray-200">
																								<div className="flex justify-between items-start">
																									<div>
                      <span className="font-medium">
                        {audit.change_type === 'status_change' ? 'Status Change' : 'Booking Change'}
                      </span>
																									</div>
																									<span className="text-xs text-gray-500">
                      {new Date(audit.created_at).toLocaleString()}
                    </span>
																								</div>

																								<div className="mt-2">
																									<p className="text-sm">
																										{audit.change_type === 'status_change' ? (
																											<>
                          <span className="inline-block px-2 py-1 rounded-full text-xs mr-2 bg-yellow-100 text-yellow-800">
                            Status Change
                          </span>
																												<span>{audit.description}</span>
																												<div className="mt-2 flex space-x-4">
																													<div className="text-sm">
																														<span className="text-gray-500">From:</span>
																														<span className={`ml-1 font-medium ${
																															audit.old_status.value === 0 ? 'text-green-600' :
																																audit.old_status.value === 1 ? 'text-red-600' :
																																	'text-yellow-600'
																														}`}>
                                {audit.old_status.label}
                              </span>
																													</div>
																													<div className="text-sm">
																														<span className="text-gray-500">To:</span>
																														<span className={`ml-1 font-medium ${
																															audit.new_status.value === 0 ? 'text-green-600' :
																																audit.new_status.value === 1 ? 'text-red-600' :
																																	'text-yellow-600'
																														}`}>
                                {audit.new_status.label}
                              </span>
																													</div>
																												</div>
																											</>
																										) : (
																											<>
                          <span className={`inline-block px-2 py-1 rounded-full text-xs mr-2 ${
							  audit.change_type === 'assigned' ? 'bg-green-100 text-green-800' :
								  audit.change_type === 'removed' ? 'bg-red-100 text-red-800' :
									  'bg-blue-100 text-blue-800'
						  }`}>
                            {audit.change_type}
                          </span>
																												{audit.description}
																											</>
																										)}
																									</p>
																									<p className="text-xs text-gray-500 mt-2">
																										Changed by: {audit.changed_by?.name || 'System'}
																									</p>
																								</div>
																							</div>
																						))}
																					</div>
																				) : (
																					<p className="text-gray-500 text-sm">No history available for this bed.</p>
																				)}
																			</>
																		)}
																	</>
																)}
															</div>

															<h2 className="text-lg font-medium text-gray-900 mb-4">
																Waiting List
															</h2>
															{waitlist?.map((b) => (
																<div className="mb-4">
																	<Link
																		to={
																			b.waiting_bed_id
																				? "/booking/declined"
																				: b.tag
																					? "/booking/management?tag=" + b.tag
																					: "/booking/" +
																					b.location
																						?.toLowerCase()
																						.replace(/\s/g, "")
																		}
																		onClick={() => {
																			dispatch(getBooking(b.id));
																			dispatch(closeModal());
																		}}
																	>
																		<div key={b.id}>
																			{b.booking_reference} {b.user?.name}
																			<br />
																			Booked: {b.created_at}
																			<br />
																			Paid:{" "}
																			{b.rates?.length > 0
																				? b.rates[b.rates.length - 1].paid === 1
																					? "Yes"
																					: "No"
																				: "No"}
																			<br />
																			Lease Signed:{" "}
																			{b.rates?.length > 0
																				? b.rates[b.rates.length - 1]
																					.signed_lease_at !== ""
																					? b.rates[b.rates.length - 1]
																						.signed_lease_at
																					: "No"
																				: "No"}
																			<br />
																			Gender:{" "}
																			{b.user?.profile?.gender
																				? b.user.profile.gender === 1
																					? "Male"
																					: " "
																				: " "}
																			{b.user?.profile?.gender
																				? b.user.profile.gender === 2
																					? "Female"
																					: " "
																				: " "}
																			<br />
																		</div>
																	</Link>
																	<button
																		type="button"
																		className="bg-dark_background hover:bg-dark_background focus:ring-dark_background text-white px-2 py-1 rounded-lg"
																		onClick={() => delinkBed(b.id)}
																	>
																		De-link
																	</button>
																	<button
																		type="button"
																		className="bg-dark_background hover:bg-dark_background focus:ring-dark_background text-white px-2 py-1 rounded-lg ml-2"
																		onClick={() =>
																			dispatch(
																				openModal({
																					modal_type: "ModalBedAssignment",
																					modal_props: {
																						current_booking: b,
																					},
																				})
																			)
																		}
																	>
																		Allocate New Bed
																	</button>
																</div>
															))}
														</div>
													</div>
												</Form>
											)}
										</Formik>

										<p>
											<b>Bed Has Wifi: </b>{" "}
											{initialValues?.wifi !== null ? "Yes" : "No"}
										</p>
										<hr />
										{initialValues?.property?.wifi_property_id != null ? (
											<>
												{initialValues?.wifi !== null ? (
													<>
														<p>
															<b>Wifi Details:</b>
														</p>
														<small>
															<i>Type:</i> Account
														</small>
														<br />
														<small>
															<i>ID:</i> {initialValues?.wifi.wifi_id}
														</small>
														<br />
														<small>
															<i>Username:</i> {initialValues?.wifi.username}
														</small>
														<br />
														<small>
															<i>Password:</i> {initialValues?.wifi.password}
														</small>
														<br />
													</>
												) : (
													<div className="w-1/3 mt-5">
														<CustomButton
															text="Create Wifi Account"
															styling="w-full mb-2"
															icon={faPlus}
															onClick={() =>
																createWifiAccount(initialValues.id)
															}
															loading={wiFiLoading}
														/>
													</div>
												)}
												<br />
											</>
										) : (
											""
										)}
									</div>
								</div>
							</div>
						</ErrorBoundary>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
