import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faLinkSlash, faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import { Formik } from "formik";
import axios from "axios";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import NotificationToast from "../../components/Helpers/NotificationToast";
import CustomButton from "../../components/Helpers/CustomButton";
import { getBooking } from "../../store/booking/bookingActions";
import { openModal } from "../../store/modal/modalReducer";
import ErrorBoundary from "../../containers/ErrorBoundary";

const GridBookingMda = ({ current_booking, current_staff }) => {
	const dispatch = useDispatch();
	const [showManualLink, setShowManualLink] = useState(false);
	const [mdaBalance, setMdaBalance] = useState(null);
	const [mdaBalanceLoading, setMdaBalanceLoading] = useState(false);
	const [mdaLoadingT, setMdaLoadingT] = useState(false);
	const [mdaLoadingL, setMdaLoadingL] = useState(false);
	const [bookingCharges, setBookingCharges] = useState([]);
	const [bookingChargesLoading, setBookingChargesLoading] = useState(false);

	useEffect(() => {
		// Load balance when the component mounts
		if (current_booking?.user_mda?.mda_tenant_id) {
			getBalance(current_booking.id, current_booking?.user.id);
			getBookingCharges(current_booking.id);
		}
	}, [current_booking?.id, current_booking?.user_mda?.mda_tenant_id]);

	async function getBalance(booking_id, user_id) {
		setMdaBalanceLoading(true);
		setMdaBalance(null);

		await axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}mda/get_balance`,
			data: { user_id: user_id, booking_id: booking_id },
		})
			.then((response) => {
				setMdaBalance(response.data);
			})
			.catch((response) => {
				toast(<NotificationToast title={"MDA Balance Issue"} message={"Balance could not be loaded"} type="error" />);
			})
			.finally(() => {
				setMdaBalanceLoading(false);
			});
	}

	async function getBookingCharges(booking_id) {
		setBookingChargesLoading(true);

		await axios({
			method: "get",
			url: `${process.env.REACT_APP_API_URL}booking_charges/${booking_id}`,
		})
			.then((response) => {
				setBookingCharges(response.data);
			})
			.catch((error) => {
				console.error("Error loading booking charges:", error);
			})
			.finally(() => {
				setBookingChargesLoading(false);
			});
	}

	function mda_create_tenant(type, action) {
		if (type === "tenant" && action === "create") {
			setMdaLoadingT(true);

			axios({
				method: "post",
				url: `${process.env.REACT_APP_API_URL}mda/create_tenant/${current_booking.user.id}/${current_booking.id}`,
				data: { current_staff: current_staff },
			})
				.then((response) => {
					let resp = response.data.split("|");

					if (resp[0] === "updated" || resp[0] === "done") {
						dispatch(getBooking(current_booking.id));
						toast(<NotificationToast title={"Tenant Created"} message={"A tenant has been created on MDA"} />);
					} else {
						toast(
							<NotificationToast title={"MDA Issue"} message={response.data} type="error" />,
							{
								autoClose: false,
							}
						);
					}
				})
				.catch((response) => {
					toast(<NotificationToast title={"MDA Issue"} message={response.data} type="error" />);
				})
				.finally(() => {
					setMdaLoadingT(false);
				});
		} else if (type === "tenant" && action === "delete") {
			setMdaLoadingT(true);

			axios({
				method: "post",
				url: `${process.env.REACT_APP_API_URL}mda/delete_tenant/${current_booking.user.id}/${current_booking.id}`,
				data: { current_staff: current_staff },
			})
				.then((response) => {
					if (response.data === "done") {
						dispatch(getBooking(current_booking.id));
						toast(<NotificationToast title={"Tenant Deleted"} message={"A tenant has been deleted on MDA"} />);
					} else {
						toast(
							<NotificationToast title={"MDA Issue"} message={response.data} type="error" />,
							{
								autoClose: false,
							}
						);
					}
				})
				.catch((response) => {
					toast(<NotificationToast title={"MDA Issue"} message={response.data} type="error" />);
				})
				.finally(() => {
					setMdaLoadingT(false);
				});
		} else if (type == "lease" && action == "create") {
			setMdaLoadingL(true);

			axios({
				method: "post",
				url: `${process.env.REACT_APP_API_URL}mda/create_lease/${current_booking.user.id}/${current_booking.id}`,
				data: { current_staff: current_staff },
			})
				.then((response) => {
					if (response.data === "done" || response === "done") {
						dispatch(getBooking(current_booking.id));
						toast(<NotificationToast title={"Lease Created"} message={"A lease has been created on MDA"} />);
					} else {
						if (response.data === "Can't find Unit!") {
							dispatch(
								openModal({
									modal_type: "ModalMDAChooseUnit",
									modal_props: { current_booking: current_booking },
								})
							);
						} else {
							toast(
								<NotificationToast title={"MDA Issue"} message={response.data} type="error" />,
								{
									autoClose: false,
								}
							);
						}
					}
				})
				.catch((response) => {
					toast(<NotificationToast title={"MDA Issue"} message={response.data} type="error" />);
				})
				.finally(() => {
					setMdaLoadingL(false);
				});
		} else if (type == "lease" && action == "delete") {
			setMdaLoadingL(true);

			axios({
				method: "post",
				url: `${process.env.REACT_APP_API_URL}mda/delete_lease/${current_booking.user.id}/${current_booking.id}`,
				data: { current_staff: current_staff },
			})
				.then((response) => {
					if (response.data === "done") {
						dispatch(getBooking(current_booking.id));
						toast(<NotificationToast title={"Lease Deleted"} message={"A lease has been deleted on MDA"} />);
					} else {
						toast(
							<NotificationToast title={"MDA Issue"} message={response.data} type="error" />,
							{
								autoClose: false,
							}
						);
					}
				})
				.catch((response) => {
					toast(<NotificationToast title={"MDA Issue"} message={response.data} type="error" />);
				})
				.finally(() => {
					setMdaLoadingL(false);
				});
		} else if (type == "lease" && action == "unlink") {
			setMdaLoadingL(true);

			axios({
				method: "post",
				url: `${process.env.REACT_APP_API_URL}mda/unlink_lease/${current_booking.user.id}/${current_booking.id}`,
				data: { current_staff: current_staff },
			})
				.then((response) => {
					dispatch(getBooking(current_booking.id));
					toast(<NotificationToast title={"Lease Unlinked"} message={"A lease has been unlinked from MDA"} />);
				})
				.catch((response) => {
					toast(<NotificationToast title={"MDA Issue"} message={response.data} type="error" />);
				})
				.finally(() => {
					setMdaLoadingL(false);
				});
		}
	}

	// Helper function to get status color based on status code and response
	const getStatusColor = (status, mda_response) => {
		// Check for error in mda_response first, regardless of status
		if (mda_response !== null) {
			try {
				const parsed = JSON.parse(mda_response);
				if (parsed.ErrorCode !== undefined) return 'text-red-500'; // Error takes precedence
			} catch (e) {
				// If can't parse, continue with status logic
			}
		}

		// Then check status
		if (status === 1) return 'text-green-600';
		if (status === 0) {
			if (mda_response === null) return 'text-yellow-500'; // Pending
			try {
				const parsed = JSON.parse(mda_response);
				return parsed.ErrorCode === undefined ? 'text-green-600' : 'text-red-500';
			} catch (e) {
				return 'text-red-500'; // Assume failed if can't parse
			}
		}
		return 'text-gray-500'; // Unknown
	};

	const getStatusText = (status, mda_response) => {
		// Check for error in mda_response first, regardless of status
		if (mda_response !== null) {
			try {
				const parsed = JSON.parse(mda_response);
				if (parsed.ErrorCode !== undefined) return 'Failed';
			} catch (e) {
				// If can't parse, continue with status logic
			}
		}

		// Then check status
		if (status === 1) return 'Processed';
		if (status === 0) {
			if (mda_response === null) return 'Pending';
			try {
				const parsed = JSON.parse(mda_response);
				return parsed.ErrorCode === undefined ? 'Success' : 'Failed';
			} catch (e) {
				return 'Failed';
			}
		}
		return 'Unknown';
	};

	// Helper function to parse error messages
	const parseErrorMessage = (message) => {
		try {
			const response = JSON.parse(message);

			// Check if this is an error message (has ErrorCode)
			if (response.ErrorCode !== undefined) {
				// Get the main error message
				let errorMsg = response.Message || 'Unknown error';

				// Add the first detailed error if available
				if (response.FurtherDetails && response.FurtherDetails.length > 0) {
					errorMsg += `: ${response.FurtherDetails[0].Message}`;
				}

				return errorMsg;
			}

			// Not an error message
			return 'Success';
		} catch (e) {
			// If JSON parsing fails, return the raw message
			return message;
		}
	};

	// Helper function to safely format dates - prevents the "Cannot read properties of null" error
	const safeFormatDate = (dateString) => {
		try {
			if (!dateString) return "-";
			return format(new Date(dateString), "dd MMM yyyy");
		} catch (error) {
			return "-";
		}
	};

	return (
		<div>
			{current_booking?.user_mda === undefined ||
			current_booking?.user_mda === null ||
			current_booking?.user_mda?.mda_tenant_id === null ||
			current_booking?.user_mda?.mda_tenant_id === 0 ? (
				<>
					{!current_staff.building_manager ? (
						<>
							<CustomButton
								text="Create Tenant"
								styling="w-full"
								icon={faLink}
								onClick={() => mda_create_tenant("tenant", "create")}
								loading={mdaLoadingT ? 1 : 0}
								loadingtext="Creating Tenant..."
							/>
							<CustomButton
								text="Manually Link Tenant"
								styling="w-full mt-2"
								icon={faLink}
								onClick={() => setShowManualLink(true)}
								type="button"
							/>
							{showManualLink === true ? (
								<>
									<Formik
										initialValues={{ tenant_id: "" }}
										validate={(values) => {
											const errors = {};
											if (!values.tenant_id) {
												errors.tenant_id = "Required";
											}
											return errors;
										}}
										onSubmit={async (values, { setSubmitting }) => {
											values.user_id = current_booking?.user.id;
											values.booking_id = current_booking?.id;
											await axios({
												method: "post",
												url: process.env.REACT_APP_API_URL + "mda/manual_mda_link",
												data: values,
											}).then((response) => {
												dispatch(getBooking(current_booking.id));
											});
										}}
									>
										{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
											<form onSubmit={handleSubmit} className="mt-2">
												<input
													type="text"
													name="tenant_id"
													onChange={handleChange}
													onBlur={handleBlur}
													value={values.tenant_id}
													placeholder="Tenant Code"
													className="rounded-lg block w-full font-light border border-gray-300 shadow-sm py-2 pl-2 focus:outline-none focus:ring-primary focus:border-primary text-sm transition ease-linear duration-10 hover:border-primary"
												/>
												{errors.tenant_id && touched.tenant_id && errors.tenant_id}
												<br />
												<button
													type="submit"
													disabled={isSubmitting}
													className="bg-dark_background hover:bg-dark_background focus:ring-dark_background relative flex text-white uppercase items-center px-4 py-2 rounded-lg text-sm focus:z-10 focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
												>
													Save Tenant Code
												</button>
											</form>
										)}
									</Formik>
								</>
							) : (
								""
							)}
						</>
					) : (
						""
					)}
				</>
			) : (
				<div>
					<ErrorBoundary>
						<div>
							<strong>Tenant ID:</strong>{" "}
							{current_booking?.user_mda?.mda_tenant_id > 0 ? (
								<a className="underline text-blue-500" target="_blank" href={"https://managerweb.mdapropsys.com/master-data/tenants/" + current_booking?.user_mda?.mda_tenant_id}>
									{current_booking?.user_mda?.mda_tenant_id}
								</a>
							) : (
								"None"
							)}
						</div>

						{/* Always show balance section with placeholders or loading state */}
						<div className="mt-2">
							{mdaBalanceLoading ? (
								<div className="my-2">
									<FontAwesomeIcon icon={faSpinnerThird} className="text-black animate-spin text-xl mr-2" />
									<span>Loading balance information...</span>
								</div>
							) : (
								<>
									<strong>Balance {mdaBalance?.date || "-"} :</strong> R {mdaBalance?.balance != null ? mdaBalance.balance.toFixed(2) : "-"}
									<br />
									<strong>Current Balance: </strong> R {mdaBalance?.current != null ? mdaBalance.current.toFixed(2) : "-"}
									<br />
									<strong>Lease Start Date: </strong> {mdaBalance?.lease_start_date ? safeFormatDate(mdaBalance.lease_start_date) : "-"}
									<br />
									<strong>Lease End Date: </strong> {mdaBalance?.lease_end_date ? safeFormatDate(mdaBalance.lease_end_date) : "-"}
									<br />
									<strong>Current Rental: </strong> R {mdaBalance?.current_rental != null ? mdaBalance.current_rental.toFixed(2) : "-"}
									<br />
									<strong>Parking: </strong> R {mdaBalance?.parking != null ? mdaBalance.parking.toFixed(2) : "-"}
									{mdaBalance?.vacate_date && (
										<div className="text-red-500">
											<strong>Vacate Date: </strong> {safeFormatDate(mdaBalance.vacate_date)}
										</div>
									)}
									{mdaBalance?.terminate_date && (
										<div className="text-red-500">
											<strong>Terminate Date: </strong> {safeFormatDate(mdaBalance.terminate_date)}
										</div>
									)}
								</>
							)}
						</div>

						{!current_staff.building_manager && (process.env.REACT_APP_COLOUR !== "UC" || current_staff.isSuper == 1) ? (
							<CustomButton
								text={"Unlink Lease + Tenant from MDA"}
								styling="w-full mt-2"
								icon={faLinkSlash}
								onClick={() => mda_create_tenant("lease", "unlink")}
								loading={mdaLoadingL ? 1 : 0}
								loadingtext="Deleting Lease..."
							/>
						) : (
							""
						)}

						{current_booking?.rates &&
						current_booking?.rates.length > 0 &&
						current_booking?.rates[current_booking?.rates.length - 1].mda_lease_id &&
						current_booking?.rates[current_booking?.rates.length - 1].mda_lease_id !== null &&
						current_booking?.rates[current_booking?.rates.length - 1].mda_lease_id !== 0 ? (
							<>
								<div>Lease ID: {current_booking?.rates[current_booking?.rates.length - 1].mda_lease_id}</div>

								{!current_staff.building_manager && (process.env.REACT_APP_COLOUR !== "UC" || current_staff.isSuper == 1) ? (
									<CustomButton
										text={"Delete Lease from MDA"}
										styling="w-full mt-2"
										icon={faLinkSlash}
										onClick={() => mda_create_tenant("lease", "delete")}
										loading={mdaLoadingL ? 1 : 0}
										loadingtext="Deleting Lease..."
									/>
								) : (
									""
								)}
							</>
						) : (
							<>
								{!current_staff.building_manager ? (
									<>
										<CustomButton
											text={"Delete Tenant (Permanent)"}
											styling="w-full mt-2"
											icon={faLinkSlash}
											onClick={() => mda_create_tenant("tenant", "delete")}
											loading={mdaLoadingT ? 1 : 0}
											loadingtext="Deleting Tenant..."
										/>

										<CustomButton
											text="Create Lease (Automatic)"
											styling="w-full mt-2"
											icon={faLink}
											onClick={() => mda_create_tenant("lease", "create")}
											loading={mdaLoadingL ? 1 : 0}
											loadingtext="Creating Lease..."
										/>

										{(current_staff.id === 1 || current_staff.email === "sarel@qholdings.co.za") && (
											<CustomButton
												text="Create Lease (Manual)"
												styling="w-full mt-2"
												icon={faLink}
												onClick={() =>
													dispatch(
														openModal({
															modal_type: "ModalMDAChooseUnit",
															modal_props: { current_booking: current_booking },
														})
													)
												}
												loading={mdaLoadingL ? 1 : 0}
												loadingtext="Creating Lease..."
											/>
										)}
									</>
								) : (
									""
								)}
							</>
						)}

						{/* Booking Charges Section */}
						<div className="mt-5">
							<h3 className="font-bold mb-2">Booking Charges</h3>
							{bookingChargesLoading ? (
								<div className="flex items-center">
									<FontAwesomeIcon icon={faSpinnerThird} className="text-black animate-spin text-xl mr-2" />
									<span>Loading charges...</span>
								</div>
							) : bookingCharges && bookingCharges.length > 0 ? (
								<div>
									<div className="space-y-4">
										{bookingCharges.map((charge, index) => (
											<div
												key={index}
												className="bg-white rounded-lg shadow overflow-hidden hover:shadow-md transition-shadow duration-300"
											>
												<div className="px-4 py-5 sm:px-6 flex justify-between items-center bg-gray-50">
													<div>
														<h3 className="text-sm font-medium text-gray-900">Period: {charge.period}</h3>
														<p className="text-xs text-gray-500 mt-1">Batch: {charge.batch_no}</p>
													</div>
													<div
														className={`px-2 py-1 rounded-full text-xs font-medium text-white ${
															getStatusColor(charge.status, charge.mda_response).replace('text-', 'bg-').replace('500', '100')
														} ${getStatusColor(charge.status, charge.mda_response)}`}
													>
														{getStatusText(charge.status, charge.mda_response)}
													</div>
												</div>

												<div className="border-t border-gray-200 px-4 py-4">
													<div className="flex justify-between items-center mb-2">
														<span className="text-sm font-medium text-gray-500">Amount</span>
														<span className="text-sm font-semibold">
															R {charge.amount ? parseFloat(charge.amount).toFixed(2) : "0.00"}
														  </span>
													</div>

													<p className="text-xs text-gray-600 mt-2">{charge.description}</p>

													{charge.status === 1 && charge.mda_response !== null && parseErrorMessage(charge.mda_response) !== 'Success' && (
														<div className="mt-3 p-2 bg-red-50 rounded text-xs text-red-500">
															{parseErrorMessage(charge.mda_response)}
														</div>
													)}
												</div>
											</div>
										))}
									</div>
								</div>
							) : (
								<p className="text-sm text-gray-500">No charges found</p>
							)}
						</div>
					</ErrorBoundary>
				</div>
			)}
		</div>
	);
};

export default GridBookingMda;