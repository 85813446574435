import { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw, EditorState, ContentState, convertFromHTML } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { toast } from "react-toastify";

export default function BookingWysiwyg({ ...props }) {
    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(props.value)))
    );

    useEffect(() => {
        setEditorState(
            EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(props.value)))
        );
    }, [props.value]);

    const staff_list = props.staff.map((staff) => ({
        text: staff.name,
        value: staff.name,
        url: String(staff.id),
    }));

    const onEditorStateChange = (newEditorState) => {
        // Get the current content and selection
        const content = newEditorState.getCurrentContent();
        const selection = newEditorState.getSelection();
        const block = content.getBlockForKey(selection.getStartKey());
        const text = block.getText();

        // Check if we just typed a space
        if (selection.getStartOffset() > 0 && text[selection.getStartOffset() - 1] === ' ') {
            // Look for @ pattern in the text before the space
            const textBeforeSpace = text.slice(0, selection.getStartOffset() - 1);
            const matches = textBeforeSpace.match(/@(\w+)$/);

            if (matches) {
                const mentionText = matches[1];
                // Find if this matches any staff member
                const matchedStaff = staff_list.find(
                    staff => staff.text.toLowerCase() === mentionText.toLowerCase()
                );

                if (matchedStaff) {
                    // Auto-select the mention
                    // The mention will be properly formatted by the editor
                } else {
                    toast.error(`Invalid mention: ${mentionText}. Please select from the suggestions.`, {
                        duration: 3000,
                        position: 'bottom-right',
                    });
                }
            }
        }

        setEditorState(newEditorState);
    };

    const onBlur = () => {
        const forFormik = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        props.setFieldValue("message", forFormik);
    };

    return (
        <Editor
            mention={{
                separator: " ",
                trigger: "@",
                suggestions: staff_list,
                // Enforce suggestions to be selected
                suggestionsPortalHost: document.body,
                mentionComponent: (mention) => (
                    <span className="mention" data-staff-id={mention.url}>
                        @{mention.text}
                    </span>
                ),
            }}
            hashtag={{}}
            {...props}
            onEditorStateChange={onEditorStateChange}
            editorClassName="px-2 py-2 block w-full sm:text-sm !outline-none resize-none bg-light_background h-full"
            wrapperClassName="bg-light_background"
            editorState={editorState}
            onBlur={onBlur}
            toolbar={{
                options: ["inline", "blockType", "fontSize", "list", "textAlign", "colorPicker", "link"],
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                emoji: false,
            }}
        />
    );
}