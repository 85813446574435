import { useState, useEffect } from "react";
import { usePopper } from "react-popper";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuildingColumns } from "@fortawesome/pro-regular-svg-icons";
import { Popover } from "@headlessui/react";

export default function BookingwaBank({ handleSubmit, platform, ...props }) {
    let default_options = [
        {
            value: "stanley_goldmashu",
            label: "Stanley – Goldmashu",
            link: "stanley_goldmashu",
            siteurl: "https://api.urbancircle.co.za/bank_docs/uc/Goldmashu.pdf?v=2",
        },
        {
            value: "drivelines_mabotrent_1",
            label: "Drivelines – Mabotrent 1",
            link: "drivelines_mabotrent_1",
            siteurl: "https://api.urbancircle.co.za/bank_docs/uc/Mabotrent1.pdf?v=2",
        },
        {
            value: "nota_bene_mabotrent_2",
            label: "Nota Bene  - Mabotrent 2",
            link: "nota_bene_mabotrent_2",
            siteurl: "https://api.urbancircle.co.za/bank_docs/uc/Mabotrent2.pdf?v=2",
        },
        {
            value: "eastside_petraglo",
            label: "Eastside – Petraglo",
            link: "eastside_petraglo",
            siteurl: "https://api.urbancircle.co.za/bank_docs/uc/Petraglo.pdf?v=2",
        },
        {
            value: "w_a_petraglo",
            label: "W+A – Petraglo",
            link: "w_a_petraglo",
            siteurl: "https://api.urbancircle.co.za/bank_docs/uc/Petraglo.pdf?v=2",
        },
        {
            value: "highbury_lisacraft",
            label: "Highbury – Lisacraft",
            link: "highbury_lisacraft",
            siteurl: "https://api.urbancircle.co.za/bank_docs/uc/Lisacraft.pdf?v=2",
        },
        {
            value: "sovereign_noskop_1",
            label: "Sovereign – Noskop 1",
            link: "sovereign_noskop_1",
            siteurl: "https://api.urbancircle.co.za/bank_docs/uc/Noskop1.pdf?v=2",
        },
        {
            value: "nursery_ordiforce",
            label: "Nursery Square – Ordiaforce",
            link: "nursery_ordiforce",
            siteurl: "https://api.urbancircle.co.za/bank_docs/uc/Ordiforce.pdf?v=2",
        },
    ];

    if (process.env.REACT_APP_COLOUR === "QL") {
        default_options = [
            {
                value: "sorrynos_2",
                label: "Kent - Sorrynos 2",
                link: "sorrynos_2",
                siteurl: "https://api.urbancircle.co.za/bank_docs/ql/Sorrynos_2.pdf",
            },
            {
                value: "sorrynos_1",
                label: "Homage - Sorrynos 1",
                link: "sorrynos_1",
                siteurl: "https://api.urbancircle.co.za/bank_docs/ql/Sorrynos_1.pdf",
            },
            {
                value: "noskop_8",
                label: "Forest Views - Noskop 8",
                link: "noskop_8",
                siteurl: "https://api.urbancircle.co.za/bank_docs/ql/Noskop_8.pdf",
            },
            {
                value: "sorrynos_3",
                label: "Malbec - Sorrynos 3",
                link: "sorrynos_3",
                siteurl: "https://api.urbancircle.co.za/bank_docs/ql/Sorrynos_3.pdf",
            },
            {
                value: "unlock_23",
                label: "Unison - Middle/North - Unlock 23",
                link: "unlock_23",
                siteurl: "https://api.urbancircle.co.za/bank_docs/ql/Unlock_23.pdf",
            },
            {
                value: "unlock_5",
                label: "Unison - South - Unlock 5",
                link: "unlock_5",
                siteurl: "https://api.urbancircle.co.za/bank_docs/ql/Unlock_5.pdf",
            },
            {
                value: "limoscape",
                label: "The Ox - Limoscape",
                link: "limoscape",
                siteurl: "https://api.urbancircle.co.za/bank_docs/ql/Limoscape.pdf",
            },
        ];
    }

    let [referenceElement, setReferenceElement] = useState();
    let [popperElement, setPopperElement] = useState();
    let [options, setOptions] = useState(default_options);
    let { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: "top",
    });

    return (
        <Popover>
            <Popover.Button
                ref={setReferenceElement}
                className="ml-4 relative inline-block text-white bg-primary px-2  py-1 rounded-xl hover:bg-primary_hover transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
            >
                <FontAwesomeIcon className="h-4 w-4" aria-hidden="true" icon={faBuildingColumns} />
            </Popover.Button>

            <Popover.Panel ref={setPopperElement} style={styles.popper} {...attributes.popper} className="bg-white rounded-lg pb-4  z-20">
                <h1 className="bg-dark_background text-white p-2 rounded-t-lg">Bank Confirmation Letters</h1>
                <div className="flex flex-col items-start px-4 mt-2">
                    {options?.map((o) => (
                        <button key={"bpopt_" + o.value} type="button" className="text-gray-600 hover:text-primary" onClick={() => props.setFieldValue("message", o.siteurl)}>
                            {o.label}
                        </button>
                    ))}
                </div>
            </Popover.Panel>
        </Popover>
    );
}
