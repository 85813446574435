import Select from "react-select";
import { Field } from "formik";
import { useField } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
export default function SettingsSelect({ options, ...props }) {
    const [field, meta, { setValue, setTouched }] = useField(props);
    const original_options = options;

    switch (options) {
        case "yesNo":
            options = [
                { value: 1, label: "Yes" },
                { value: 0, label: "No" },
            ];
            break;
        case "none":
            options = [];
            break;
        case "titles":
            options = [
                { value: 1, label: "Mr" },
                { value: 2, label: "Ms" },
                { value: 3, label: "Mrs" },
                { value: 4, label: "Dr" },
            ];
            break;
        case "gender":
            options = [
                { value: 1, label: "Male" },
                { value: 2, label: "Female" },
            ];
            break;
        case "vendor":
            options = [
                { value: 1, label: "Fundi" },
                { value: 2, label: "AE switch" },
                { value: 3, label: "Embizo" },
            ];
            break;
        case "order_types":
            options = [
                { value: 1, label: "General " },
                { value: 2, label: "Unit Specific" },
            ];
            break;
        case "order_type_general":
            options = [{ value: 1, label: "General " }];
            break;
        case "order_reasons":
            options = [
                { value: 1, label: "R & M " },
                { value: 2, label: "Reinstatement" },
                { value: 3, label: "Tenant Cost" },
            ];
            break;
        case "order_reasons_general":
            options = [
                { value: 1, label: "R & M " },
                { value: 2, label: "Reinstatement" },
            ];
            break;
        case "nsfas_status":
            options = [
                { value: 1, label: "Absconded" },
                { value: 2, label: "Bursar Invoice Sent" },
                { value: 3, label: "Contacted" },
                { value: 4, label: "Docs Loaded" },
                { value: 5, label: "Ex-tenant" },
                { value: 6, label: "Funds Loaded" },
                { value: 7, label: "Wits Invoice Sent" },
                { value: 8, label: "No Response" },
                { value: 9, label: "Not Funded" },
                { value: 10, label: "Not moved-in (Lease)" },
                { value: 11, label: "Not moved-in (No Lease)" },
                { value: 12, label: "Paid" },
                { value: 13, label: "Verified" },
                { value: 14, label: "Verify error" },
                { value: 15, label: "Bad Debt" },
                { value: 16, label: "Awaiting Funds" },
                { value: 17, label: "Payment Arrangement" },
                { value: 18, label: "PNYA" },
                { value: 19, label: "No Status" },
                { value: 20, label: "Appealing" },
                { value: 21, label: "Appeals" },
                { value: 22, label: "Provisionally Funded" },
            ];
            break;
        case "provinces":
            options = [
                { value: "EC", label: "Eastern Cape" },
                { value: "FS", label: "Free State" },
                { value: "GP", label: "Gauteng" },
                { value: "KZ", label: "KwaZulu-Natal" },
                { value: "LP", label: "Limpopo" },
                { value: "MP", label: "Mpumalanga" },
                { value: "NC", label: "Northen Cape" },
                { value: "NW", label: "North West" },
                { value: "WC", label: "Western Cape" },
            ];
            break;
        case "bank_account_types":
            options = [
                { value: "Current", label: "Current" },
                { value: "Cheque", label: "Cheque" },
                { value: "Savings", label: "Savings" },
                { value: "Credit", label: "Credit" },
            ];
            break;
        case "nationality":
            options = require("../../data/countries.json");
            break;
        case "assisted_by":
            options = require("../../data/assisted_by.json");
            break;
        case "referral":
            options = require("../../data/referral.json");
            break;
        default:
            options = options;
            break;
    }

    const onChange = ({ value, price }) => {
        setValue(value);
        if (props.getUnits) {
            props.getUnits(value);
        }
        if (props.getUnitTypes) {
            props.getUnitTypes(value);
        }
        if (props.getFloors) {
            props.getFloors(value);
        }
        if (props.getBeds) {
            props.getBeds(value);
        }
        if (props.contributions) {
            props.contributions(value);
        }
        if (props.items) {
            props.items(value);
        }
        if (props.itemPrice) {
            props.setFieldValue(props.itemPrice, price);
        }
        if (props.getManagers) {
            props.getManagers(value);
        }
        if (props.updateValues) {
            props.updateValues(value, props.setFieldValue, props.allPricing);
        }
        if (props.updateBookingForm) {
            props.updateBookingForm(value, props.setFieldValue);
        }
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            boxShadow: "none",
            border: state.isFocused && "none" ? "1px solid #9F7D38" : "1px solid #d1d5db",
            cursor: "pointer",
            "&:hover": {
                border: state.isFocused ? "1px solid #9F7D38" : "1px solid #d1d5db",
                cursor: "pointer",
            },
            borderRadius: "10px",
            padding: 0,
        }),
        container: (provided, state) => ({
            ...provided,
            padding: 0,
            border: 0,
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: "#e7ebe5",
        }),
    };

    return (
        <div className="col-span-6 sm:col-span-3">
            <label htmlFor={props.name} className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                {props.label}
                {props.required && !props.value && <p className="text-xs font-light text-red-400">required</p>}
            </label>
            <Select
                id={props.name}
                isDisabled={props.disabled}
                options={options}
                onChange={onChange}
                onBlur={setTouched}
                value={options && options.length > 0 ? options.find((option) => option.value === field.value) : ""}
                components={{
                    IndicatorSeparator: () => null,
                }}
                styles={customStyles}
                className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
            />
            <p className="text-red-400 text-sm">{props.errors}</p>
        </div>
    );
}
