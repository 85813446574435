import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useParams, Link} from "react-router-dom";
import TimeSince from "../Helpers/TimeSince";
import TypeIcon from "../Helpers/TypeIcon";
import ProfilePicture from "../../components/Auth/ProfilePicture";
import LazyLoad from "react-lazy-load";
import moment from "moment/moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinnerThird} from "@fortawesome/pro-regular-svg-icons";
import {faBedBunk, faCheckCircle, faPeople} from "@fortawesome/pro-solid-svg-icons";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export default function BookingList({
										setMessageLoading,
										bookings,
										setSortFilter,
										filterLocation,
										filterGender,
										filterFunding,
										setMsgCount,
										tag_id,
										setShowNewMessages,
										chatsLoading,
										collection_tag_id,
										filterVendor,
										filterStatus,
										renewal_tag,
										setFilterCount,
										filterStudyYear,
										filterUnitType,
										filterBookingSummary,
										filterBudget,
				filterBotFlow,
									}) {
	const dispatch = useDispatch();
	const [tab, setTab] = useState("open");
	const [tabSort, setTabSort] = useState("asc");

	const {type} = useParams();
	const {current_booking, flagged_ids} = useSelector((state) => state.booking);
	const {current_staff, staff} = useSelector((state) => state.auth);

	const [bookingsFiltered, setBookingsFiltered] = useState([]);

	let booking_id = "";
	let user_id = "";

	// console.log(current_booking)

	if (current_booking) {
		booking_id = String(current_booking.id);
		user_id = current_booking.user.id;
	}

	useEffect(() => {
		let thisTab = 0;

		if (tab === "closed") {
			thisTab = 1;
		}

		let filter1 = {};

		if (tabSort === "asc") {
			filter1 = bookings.sort((a, b) => {
				const aTime = Math.max(new Date(a.n).getTime() || 0, new Date(a.nn).getTime() || 0);
				const bTime = Math.max(new Date(b.n).getTime() || 0, new Date(b.nn).getTime() || 0);
				return bTime - aTime; // Newest first
			});
		} else {
			filter1 = bookings.sort((a, b) => {
				const aTime = Math.max(new Date(a.n).getTime() || 0, new Date(a.nn).getTime() || 0);
				const bTime = Math.max(new Date(b.n).getTime() || 0, new Date(b.nn).getTime() || 0);
				return aTime - bTime; // Oldest first
			});
		}

		let filter2 = filter1.filter((row) => {
			if (type !== "collections" && type !== "renewals") {
				return parseInt(row.g) === parseInt(thisTab);
			} else if (type === "collections") {
				return parseInt(row.w) === parseInt(thisTab);
			} else {
				return parseInt(row.ae) === parseInt(thisTab);
			}
		});

		let filter3 = filter2.filter((row) => {
			if (filterLocation && filterLocation.id != 0) {
				if (filterLocation.id === 1000) {
					return row.i === null;
				} else {
					return parseInt(row.i) === parseInt(filterLocation.id);
				}
			} else {
				return true;
			}
		});

		let filter4 = filter3.filter((row) => {
			if (filterGender && filterGender.id != 0) {
				return parseInt(row.x) === parseInt(filterGender.id);
			} else {
				return true;
			}
		});

		let filter5 = filter4.filter((row) => {
			if (filterFunding && filterFunding.id != 0) {
				return parseInt(row.v) === parseInt(filterFunding.id);
			} else {
				return true;
			}
		});

		let filter6 = filter5.filter((row) => {
			if (tab === "snoozed") {
				return moment(row.j).isSameOrAfter(moment(moment().format("Y-MM-DD HH:mm:ss")));
			} else {
				if (row.j === null) {
					return true;
				} else {
					return moment(row.j).isSameOrBefore(moment(moment().format("Y-MM-DD HH:mm:ss")));
				}
			}
		});

		if (type === "collections") {
			let filter7 = filter6.filter((row) => {
				if (filterVendor && filterVendor.id != 0) {
					return parseInt(row.aa) === parseInt(filterVendor.id);
				} else {
					return true;
				}
			});

			var filter8 = filter7.filter((row) => {
				if (filterStatus && filterStatus.id != 0) {
					return parseInt(row.ab) === parseInt(filterStatus.id);
				} else {
					return true;
				}
			});
		} else {
			var filter8 = filter6;
		}

		if (current_staff.building_manager) {
			filter8 = filter6.filter((row) => {
				var prop_id = row.i;
				if (row.i == 6 || row.i == 7 || row.i == 3) {
					var prop_id = 6;
				}
				return parseInt(prop_id) === parseInt(current_staff.bm_property_id);
			});
		}

		// New sorting logic for robot colors (red, yellow, green)
		let filter9 = filter8.sort((a, b) => {
			// Helper function to get robot priority (red = 3, yellow = 2, green = 1)
			const getRobotPriority = (booking) => {
				if (booking.al > 0) return 2; // Yellow
				if (booking.s === "a") return 1; // Green
				return 3; // Red
			};

			const priorityA = getRobotPriority(a);
			const priorityB = getRobotPriority(b); // First sort by robot priority

			if (priorityA !== priorityB) {
				return priorityB - priorityA; // Higher priority (red) comes first
			} // If same priority, sort by timestamp

			return a.s > b.s ? -1 : 1;
		});

		let filter10 = filter9.filter((row) => {
			if (filterStudyYear && filterStudyYear.id != 0) {
				return parseInt(row.am) === parseInt(filterStudyYear.id);
			} else {
				return true;
			}
		});

		let filter11 = filter10.filter((row) => {
			if (filterUnitType && filterUnitType.id == 1) {
				return parseInt(row.ao) > 0;
			} else if (filterUnitType && filterUnitType.id == 2) {
				return parseInt(row.ao) === 0 || row.ao === null;
			} else {
				return true;
			}
		});

		let filter12 = filter11.filter((row) => {
			if (filterBookingSummary && filterBookingSummary.id == 1) {
				return row.ap === "yes";
			} else if (filterBookingSummary && filterBookingSummary.id == 2) {
				return row.ap === "no";
			} else {
				return true;
			}
		});

		let filter13 = filter12.filter((row) => {
			if (filterBudget && filterBudget.id != 0) {
				return parseInt(row.aq) === parseInt(filterBudget.id);
			} else {
				return true;
			}
		});

		let filter14 = filter13.filter((row) => {
			if (filterBotFlow && filterBotFlow.id !== 0) {
				switch (filterBotFlow.id) {
					case 1: // Red
						return row.s === "c";
					case 2: // Green
						return row.s === "a";
					case 3: // Bot Flow
						return row.al > 0;
					default:
						return true;
				}
			} else {
				return true;
			}
		});

		setFilterCount(filter14.length);
		setBookingsFiltered(filter14);
	}, [tab, bookings, tabSort, filterLocation]);

	function toggleSortOrder(toggle) {
		setSortFilter(toggle);
		setTabSort(toggle);
	}

	// console.log(bookingsFiltered);

	return (
		<div
			className="h-auto max-h-80 xl:max-h-screen overflow-y-scroll xl:overflow-auto xl:-bottom-14 z-10 pt-14 -mt-14 xl:mt-0">
			<div>
                <span className="relative z-0 inline-flex justify-center shadow-sm w-full px-2">
                    {/* <div>
                        <button
                            type="button"
                            onClick={() => toggleAssigned("mine")}
                            className={classNames(
                                tabMe === "mine" ? "bg-white" : "",
                                "px-2 py-2 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary transition ease-linear delay-50"
                            )}
                        >
                            Mine
                        </button>
                        <button
                            type="button"
                            onClick={() => toggleAssigned("all")}
                            className={classNames(
                                tabMe === "all" ? "bg-white" : "",
                                "rounded-md px-2 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary transition ease-linear delay-50"
                            )}
                        >
                            All
                        </button>
                    </div> */}

					<div>
                        <button
							type="button"
							onClick={() => toggleSortOrder("asc")}
							className={classNames(
								tabSort === "asc" ? "bg-white" : "",
								"px-2 py-2 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary transition ease-linear delay-50"
							)}
						>
                            Newest
                        </button>
                        <button
							type="button"
							onClick={() => toggleSortOrder("desc")}
							className={classNames(
								tabSort === "desc" ? "bg-white" : "",
								"rounded-md px-2 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary transition ease-linear delay-50"
							)}
						>
                            Oldest
                        </button>
                    </div>
                </span>
			</div>

			<div>
                <span className="relative z-0 inline-flex justify-center shadow-sm w-full px-2">
                    <button
						type="button"
						onClick={() => setTab("open")}
						className={classNames(
							tab === "open" ? "bg-white" : "",
							"px-2 py-2 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary transition ease-linear delay-50"
						)}
					>
                        Open
                    </button>
                    <button
						type="button"
						onClick={() => setTab("snoozed")}
						className={classNames(
							tab === "snoozed" ? "bg-white" : "",
							"rounded-md px-2 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary transition ease-linear delay-50"
						)}
					>
                        Snoozed
                    </button>
                    <button
						type="closed"
						onClick={() => setTab("closed")}
						className={classNames(
							tab === "closed" ? "bg-white" : "",
							"px-2 py-2 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary transition ease-linear delay-50"
						)}
					>
                        Closed
                    </button>
                </span>
			</div>

			{chatsLoading && chatsLoading === true ? (
				<div className="w-full h-full text-center mt-5">
                    <span className="text-blue-comparemed top-1/2 my-0 mx-auto block relative w-72 h-72">
                        <FontAwesomeIcon icon={faSpinnerThird} className="text-black animate-spin text-5xl"/>
                    </span>
				</div>
			) : (
				<div>
					{bookingsFiltered.length ? (
						bookingsFiltered.map((booking) => (
							<LazyLoad height={118} key={"booking_" + booking.f} threshold={0.55}>
								<div key={booking.a}>
									<Link
										to={
											tag_id > 0 || tag_id === "orphaned"
												? "/booking/" + type + "?tag=" + tag_id + "&user_id=" + booking.f + "&booking_id=" + booking.a
												: collection_tag_id > 0 || collection_tag_id === "-1"
													? "/booking/" + type + "?collection_tag=" + (collection_tag_id > 0 ? collection_tag_id : -1) + "&user_id=" + booking.f + "&booking_id=" + booking.a
													: renewal_tag > 0 || renewal_tag === "-1"
														? "/booking/" + type + "?renewal_tag=" + (renewal_tag > 0 ? renewal_tag : -1) + "&user_id=" + booking.f + "&booking_id=" + booking.a
														: "/booking/" + type + "?user_id=" + booking.f + "&booking_id=" + booking.a
										}
									>
										<div
											className={classNames(
												booking.f === user_id ? "border-l-primary bg-gray-100" : "bg-light_background border-l-light_background",
												"border-l-8 p-4 hover:cursor-pointer hover:bg-gray-200 hover:border-l-primary_hover transition ease-linear delay-10"
											)}
											// onClick={() => {
											//     setShowNewMessages(true);
											//     //     // if current applicant is selected, remove the onclick event
											//     //     if (current_booking !== booking.a) {
											//     console.log("getBooking 2");
											//     dispatch(getBooking(booking.a));
											//     //         setMessageLoading(true);
											//     //         setMsgCount(25);
											//     //     }
											// }}
										>
											{/* {console.log(booking)} */}
											<div className="flex space-x-3 items-center relative">
												<div>
                                                    <span className="inline-block relative">
                                                        <TypeIcon
															platform={
																booking.m && booking.mm
																	? new Date(booking.m).getTime() > new Date(booking.mm).getTime()
																		? booking.m
																		: booking.mm
																	: booking.m || booking.mm
															}
															className="h-8 w-8 text-gray-400"
														/>
                                                        <span
															className={classNames(
																booking.al > 0 ? "bg-yellow-400" : booking.s === "a" ? "bg-green-400" : "bg-red-400",
																"absolute top-0 right-0 block h-2 w-2 rounded-full ring-1 ring-white"
															)}
														/>
                                                    </span>

													<div className="absolute bottom-0 left-0">{booking.an > 0 &&
														<FontAwesomeIcon icon={faPeople}
																		 className="h-5 w-5 text-primary"/>}</div>
													<div className="absolute top-0 right-0">{booking.ap === "yes" &&
														<FontAwesomeIcon icon={faCheckCircle}
																		 className="h-5 w-5 text-primary"/>}</div>
													<div className="absolute top-0 right-6">
														{((process.env.REACT_APP_COLOUR === "UC" && booking.ar > 0) || (process.env.REACT_APP_COLOUR === "QL" && booking.ar2 > 0)) && (
															<FontAwesomeIcon
																icon={faBedBunk}
																className={`h-5 w-5 ${
																	booking.au !== null
																		? 'text-pink-500'
																		: booking.at !== null
																			? 'text-black'
																			: 'text-blue-500'
																}`}
															/>
														)}
													</div>
												</div>
												<div className="flex-1 space-y-2">
													<p
														className={classNames(
															flagged_ids.length > 0 && flagged_ids.includes(booking.d) ? "text-red-500" : "",
															" font-light truncate w-48 capitalize"
														)}
													>
														{booking.e}
														{(type === "all" || type === "you" || type === "mentions") && " - " + booking.p}
													</p>

													<div className="flex items-center justify-between flex-wrap">
														<h3
															className={classNames(
																flagged_ids.length > 0 && flagged_ids.includes(booking.d) ? "text-red-600" : "text-gray-600",
																" xl:w-28 xl:truncate w-2/3 overflow-auto"
															)}
														>
															{booking.b} {booking.c ? booking.c : ""}{" "}
														</h3>
														<p className={classNames(flagged_ids.length > 0 && flagged_ids.includes(booking.d) ? "text-red-500" : "text-gray-500", " text-sm")}>
															{booking.n || booking.nn ? <TimeSince
																time={Math.max(new Date(booking.n || 0).getTime(), new Date(booking.nn || 0).getTime())}/> : null}
														</p>
													</div>
													<div>
														<p
															className={classNames(
																flagged_ids.length > 0 && flagged_ids.includes(booking.d) ? "text-red-500" : "text-gray-500",
																" text-sm truncate w-48"
															)}
														>
															{(() => {
																const nTime = new Date(booking.n).getTime() || 0;
																const nnTime = new Date(booking.nn).getTime() || 0; // If nn timestamp is more recent, show oo message // Otherwise show o message

																if (nnTime > nTime) {
																	return booking.oo ? booking.oo.replace(/<\/?[^>]+(>|$)/g, "") : booking.o ? booking.o.replace(/<\/?[^>]+(>|$)/g, "") : "No Message";
																} else {
																	return booking.o ? booking.o.replace(/<\/?[^>]+(>|$)/g, "") : booking.oo ? booking.oo.replace(/<\/?[^>]+(>|$)/g, "") : "No Message";
																}
															})()}
														</p>
													</div>
												</div>

												<div className="absolute top-0 right-0 flex">
													{booking?.assigned_to && Array.isArray(booking.assigned_to) > 0 ? booking.assigned_to.map((a) =>
														<ProfilePicture key={a} id={a} size="20"/>) : ""}
												</div>
											</div>
										</div>
									</Link>
									<hr className="border-t-2"/>
								</div>
							</LazyLoad>
						))
					) : (
						<></>
					)}
				</div>
			)}
		</div>
	);
}
