import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Formik, Form } from "formik";
import SubmitButton from "../../components/Forms/SubmitButton";
import SettingsSelect from "../../components/Forms/SettingsSelect";

const StatusBadge = ({ status }) => {
    const colors = {
        unallocated: "bg-green-200",
        blue_bed: "bg-blue-400 text-white",
        black_bed: "bg-gray-800 text-white",
        pink_bed: "bg-pink-400",
        red_block: "bg-red-300",
        yellow_block: "bg-yellow-200",
    };

    const displayText = status
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

    return (
        <span className={`px-2 py-1 rounded-full text-sm ${colors[status]}`}>
            {displayText}
        </span>
    );
};

const BedList = ({ beds }) => {
    const renderBedContent = (bed) => {
        const isUnallocated = bed.status === 'unallocated';
        const content = (
            <div className="text-sm text-gray-500 flex-1 min-w-0">
                <span className="inline-block truncate">
                    {bed.name} • <span className="text-gray-400">{bed.type}</span>
                </span>
            </div>
        );

        if (isUnallocated || !bed.booking_id || !bed.user_id) {
            return content;
        }

        return (
            <a
                href={`/booking/movedin?user_id=${bed.user_id}&booking_id=${bed.booking_id}`}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline flex-1 min-w-0"
                title={`View booking details for ${bed.name} (${bed.type})`}
            >
                {content}
            </a>
        );
    };

    return (
        <div className="mt-2 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
            {beds.map((bed) => (
                <div key={bed.id} className="flex items-center space-x-2 min-w-0">
                    {renderBedContent(bed)}
                    <div className="flex-shrink-0">
                        <StatusBadge status={bed.status} />
                    </div>
                </div>
            ))}
        </div>
    );
};

const FloorSummary = ({ floor, propertyId }) => {
    const floorStats = floor.units.reduce((acc, unit) => {
        unit.beds.forEach(bed => {
            acc.total++;
            acc[bed.status] = (acc[bed.status] || 0) + 1;
        });
        return acc;
    }, { total: 0 });

    const statItems = [
        { label: "Total", value: floorStats.total, color: "text-gray-500" },
        { label: "Blue/Purple", value: floorStats.blue_bed || 0, color: "text-blue-500" },
        { label: "Black", value: floorStats.black_bed || 0, color: "text-gray-800" },
        { label: "Pink", value: floorStats.pink_bed || 0, color: "text-pink-500" },
        { label: "Red", value: floorStats.red_block || 0, color: "text-red-500" },
        { label: "Yellow", value: floorStats.yellow_block || 0, color: "text-yellow-500" },
        { label: "Unallocated", value: floorStats.unallocated || 0, color: "text-green-500" }
    ];

    return (
        <div className="px-4 py-5 sm:p-6 border-b">
            <div className="flex flex-col space-y-2">
                <div className="flex justify-between items-center">
                    <h4 className="text-md font-medium text-gray-900">
                        {floor.name} ({floorStats.total} beds)
                        <a
                            className="ml-2 text-blue-500 underline"
                            href={`/properties/step2/${propertyId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            View Property
                        </a> |
                        <a
                            className="text-blue-500 underline"
                            href={`/properties/step3/${propertyId}/${floor.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            View Floor
                        </a>
                    </h4>
                </div>
                <div className="grid grid-cols-7 gap-4 text-sm">
                    {statItems.map(item => (
                        <div key={item.label}>
                            <span className={item.color}>{item.label}: </span>
                            <span className="font-medium">{item.value}</span>
                        </div>
                    ))}
                </div>
            </div>

            <div className="mt-4">
                {floor.units.map((unit) => (
                    <div key={unit.id} className="pl-4 mt-4">
                        <h5 className="text-sm font-medium text-gray-900">{unit.name}</h5>
                        <BedList beds={unit.beds} />
                    </div>
                ))}
            </div>
        </div>
    );
};

const PropertySummary = ({ property, expanded, toggleExpand }) => {
    const statItems = [
        { label: "Total", value: property.summary.total, color: "text-gray-500" },
        { label: "Blue/Purple", value: property.summary.blue_bed, color: "text-blue-500" },
        { label: "Black", value: property.summary.black_bed, color: "text-gray-800" },
        { label: "Pink", value: property.summary.pink_bed, color: "text-pink-500" },
        { label: "Red", value: property.summary.red_block, color: "text-red-500" },
        { label: "Yellow", value: property.summary.yellow_block, color: "text-yellow-500" },
        { label: "Unallocated", value: property.summary.unallocated, color: "text-green-500" }
    ];

    return (
        <div className="bg-white shadow sm:rounded-lg">
            <div
                className="px-4 py-5 sm:p-6 cursor-pointer hover:bg-gray-50"
                onClick={() => toggleExpand(`property-${property.id}`)}
            >
                <div className="flex items-center justify-between">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">{property.name}</h3>
                    <span className="transform transition-transform duration-200">
                        {expanded[`property-${property.id}`] ? "▼" : "▶"}
                    </span>
                </div>
                <div className="mt-2 grid grid-cols-7 gap-4">
                    {statItems.map(item => (
                        <div key={item.label} className="text-sm">
                            <span className={item.color}>{item.label}: </span>
                            <span className="font-medium">{item.value}</span>
                        </div>
                    ))}
                </div>
            </div>

            {expanded[`property-${property.id}`] && (
                <div className="border-t border-gray-200">
                    {property.floors.map((floor) => (
                        <FloorSummary
                            key={floor.id}
                            floor={floor}
                            propertyId={property.id}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

const OverallSummary = ({ totals }) => {
    const summaryItems = [
        { label: "Total Beds", value: totals.total, className: "bg-gray-50" },
        { label: "Blue Beds", value: totals.blue_bed, className: "bg-blue-50" },
        { label: "Black Beds", value: totals.black_bed, className: "bg-gray-100" },
        { label: "Pink Beds", value: totals.pink_bed, className: "bg-pink-50" },
        { label: "Red Block", value: totals.red_block, className: "bg-red-50" },
        { label: "Yellow Block", value: totals.yellow_block, className: "bg-yellow-50" },
        { label: "Unallocated", value: totals.unallocated, className: "bg-green-50" }
    ];

    return (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Overall Summary</h3>
                <div className="mt-4 grid grid-cols-7 gap-4">
                    {summaryItems.map(item => (
                        <div key={item.label} className={`${item.className} px-4 py-2 rounded-lg`}>
                            <dt className="text-sm font-medium text-gray-500">{item.label}</dt>
                            <dd className="mt-1 text-2xl font-semibold text-gray-900">{item.value}</dd>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default function BedAllocationReport() {
    const [loading, setLoading] = useState(false);
    const [properties, setProperties] = useState([]);
    const [reportData, setReportData] = useState(null);
    const [expanded, setExpanded] = useState({});
    const { current_staff } = useSelector((state) => state.auth);

    const years = [
        { label: "2023", value: 1 },
        { label: "2024", value: 2 },
        { label: "2025", value: 3 },
    ];

    useEffect(() => {
        loadProperties();
    }, []);

    const loadProperties = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}properties`);
            const allProperties = [
                { id: 0, label: "All Properties", value: 0 },
                ...response.data.map(prop => ({
                    id: prop.value,
                    label: prop.label,
                    value: prop.value,
                    name: prop.label,
                }))
            ];
            setProperties(allProperties);
        } catch (error) {
            console.error("Error loading properties:", error);
        }
    };

    const toggleExpand = (key) => {
        setExpanded((prev) => ({
            ...prev,
            [key]: !prev[key],
        }));
    };

    const handleGenerateReport = async (values, { setSubmitting }) => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}reports/bed-allocation`, {
                params: {
                    property_id: values.property_id === 0 ? null : values.property_id,
                    year_id: values.year_id,
                },
            });
            setReportData(response.data);
        } catch (error) {
            console.error("Error loading report:", error);
        } finally {
            setLoading(false);
            setSubmitting(false);
        }
    };

    return (
        <div className="w-auto bg-gray-100 my-6 xl:my-0 xl:mx-4 rounded-3xl flex-1 p-5">
            <h1 className="text-2xl font-semibold text-gray-900 mb-6">Bed Allocation Report</h1>

            <Formik
                initialValues={{
                    year_id: 3,
                    property_id: 0,
                }}
                onSubmit={handleGenerateReport}
            >
                {({ values, setFieldValue, handleSubmit, isValid, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                        <div className="grid lg:grid-cols-12 md:lg:grid-cols-6 gap-12 mt-5 mb-5">
                            <SettingsSelect
                                label="Year"
                                name="year_id"
                                onChange={setFieldValue}
                                value={values.year_id}
                                options={years}
                            />
                            <SettingsSelect
                                label="Select Building (Optional)"
                                name="property_id"
                                onChange={setFieldValue}
                                value={values.property_id}
                                options={properties}
                            />
                        </div>

                        <SubmitButton
                            name="Submit"
                            disabled={!isValid || isSubmitting}
                            isSubmitting={isSubmitting}
                            text="Generate Report"
                        />

                        {loading && (
                            <div className="mt-4 flex items-center justify-center">
                                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
                                <span className="ml-2">Generating report...</span>
                            </div>
                        )}

                        {reportData && !loading && (
                            <div className="mt-6">
                                <OverallSummary totals={reportData.totals} />
                                <div className="mt-6 space-y-4">
                                    {reportData.properties.map((property) => (
                                        <PropertySummary
                                            key={property.id}
                                            property={property}
                                            expanded={expanded}
                                            toggleExpand={toggleExpand}
                                        />
                                    ))}
                                </div>
                            </div>
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    );
}