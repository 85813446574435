import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { faPenToSquare, faTrashCan, faPlus } from "@fortawesome/pro-regular-svg-icons";
import SettingsIconButton from "../Forms/SettingsIconButton";
import { openModal } from "../../store/modal/modalReducer";

export default function SettingsEditableRow({ ...props }) {
    const dispatch = useDispatch();
    const disabled = props.bookings_count > 0 ? true : false;

    const { managers } = useSelector((state) => state.managers);
    let manager_empty = [];

    if (props.appointments && props.appointments.length > 0) {
        props.appointments.map((appointment) => {
            if (appointment.managers !== "" && appointment.managers !== null && appointment.managers.includes(props.id.toString())) {
                manager_empty.push(props.id);
            }
        });
    }

    return (
        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className={"text-sm font-medium text-gray-500"} style={{ color: props.color ? props.color : "" }}>
                {props.label}
                {props.small && (
                    <>
                        <br />
                        <small className="text-gray-500">{props.small}</small>
                    </>
                )}

                {props.managerSmall && (
                    <>
                        {props.managerSmall &&
                            props.managerSmall.length > 0 &&
                            props.managerSmall.map((manager) => (
                                <>
                                    <br />
                                    <small className="text-gray-500">
                                        {manager?.property?.name} - {props.value}
                                        <span className="ml-5">
                                            <SettingsIconButton
                                                type="button"
                                                icon={faPenToSquare}
                                                id={props.id}
                                                onClick={() =>
                                                    dispatch(
                                                        openModal({
                                                            modal_type: props.modal,
                                                            modal_props: {
                                                                data: props,
                                                                schedule: manager,
                                                                action: "edit",
                                                                callback: props.callback,
                                                            },
                                                        })
                                                    )
                                                }
                                            />
                                        </span>
                                    </small>
                                </>
                            ))}
                    </>
                )}
                {props.managers && (
                    <>
                        <br />
                        {managers &&
                            managers.length > 0 &&
                            managers.map((manager) => (
                                <>
                                    {props.managers.split(",").includes(manager.id.toString()) ? (
                                        <>
                                            <small className="text-gray-500">
                                                {manager.name} {manager.surname} - {manager.email}
                                            </small>
                                            <br />
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </>
                            ))}
                    </>
                )}
            </dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {props.description && <span className="flex-grow">{props.description}</span>}
                <span className="ml-4 flex-shrink-0">
                    <div className="flex space-x-2">
                        {props.type != "manager" ? (
                            <SettingsIconButton
                                type="button"
                                icon={faPenToSquare}
                                id={props.id}
                                // Removed the disabled={props.type === "folder" ? true : false} to allow folder editing
                                onClick={() =>
                                    dispatch(
                                        openModal({
                                            modal_type: props.modal,
                                            modal_props: {
                                                data: props,
                                                action: "edit",
                                                callback: props.callback,
                                            },
                                        })
                                    )
                                }
                            />
                        ) : (
                            ""
                        )}
                        {props.type != "manager" ? (
                            <SettingsIconButton
                                type="button"
                                icon={faTrashCan}
                                id={props.id}
                                disabled={disabled}
                                onClick={() =>
                                    !disabled
                                        ? dispatch(
                                            openModal({
                                                modal_type: "ModalNotification",
                                                modal_props: {
                                                    type: "warning",
                                                    heading: "Are you sure that you want to delete this " + props.type + "?",
                                                    dataType: props.type,
                                                    data: props,
                                                    body: "This is permanent and cannot be undone",
                                                    buttonText: "Delete",
                                                    confirmTitle: "Deleted",
                                                    confirmMessage: "This " + props.type + " has been removed",
                                                    confirmMethod: "DELETE",
                                                },
                                            })
                                        )
                                        : dispatch(
                                            openModal({
                                                modal_type: "ModalNotification",
                                                modal_props: {
                                                    type: "error",
                                                    heading: "You cannot delete this tag",
                                                    dataType: props.type,
                                                    data: {
                                                        link: "",
                                                    },
                                                    body: "There are bookings tied to it",
                                                },
                                            })
                                        )
                                }
                            />
                        ) : (
                            <>
                                <SettingsIconButton
                                    type="button"
                                    icon={faPlus}
                                    id={props.id}
                                    onClick={() =>
                                        dispatch(
                                            openModal({
                                                modal_type: props.modal,
                                                modal_props: {
                                                    data: props,
                                                    action: "add",
                                                    callback: props.callback,
                                                },
                                            })
                                        )
                                    }
                                />

                                {manager_empty.length === 0 ? (
                                    <>
                                        <SettingsIconButton
                                            type="button"
                                            icon={faTrashCan}
                                            id={props.id}
                                            onClick={() =>
                                                dispatch(
                                                    openModal({
                                                        modal_type: "ModalNotification",
                                                        modal_props: {
                                                            type: "warning",
                                                            heading: "Are you sure that you want to delete this " + props.type + "?",
                                                            dataType: props.type,
                                                            data: props,
                                                            body: "This is permanent and cannot be undone",
                                                            buttonText: "Delete",
                                                            confirmTitle: "Deleted",
                                                            confirmMessage: "This " + props.type + " has been removed",
                                                            confirmMethod: "GET",
                                                        },
                                                    })
                                                )
                                            }
                                        />
                                    </>
                                ) : (
                                    ""
                                )}
                            </>
                        )}
                    </div>
                </span>
            </dd>
        </div>
    );
}