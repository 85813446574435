import React, { Fragment, useState, useRef, useEffect } from "react";
import { closeModal } from "../../store/modal/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import ErrorBoundary from "../../containers/ErrorBoundary";
import axios from "axios";

export default function ModalFilterMessages(props) {
	const dispatch = useDispatch();
	const [open] = useState(true);
	const filterProp = useRef();
	const filterGender = useRef();
	const filterFunding = useRef();
	const filterVendor = useRef();
	const filterStatus = useRef();
	const filterDoc = useRef();
	const filterStudyYear = useRef();
	const filterUnitType = useRef();
	const filterBookingSummary = useRef();
	const filterBudget = useRef();
	const filterBotFlow = useRef();

	const vendor_data = [
		{ value: 1, label: "Fundi" },
		{ value: 2, label: "AE switch" },
		{ value: 3, label: "Embizo" },
	];
	const status_data = [
		{ value: 1, label: "Absconded" },
		{ value: 2, label: "Bursar Invoice Sent" },
		{ value: 3, label: "Contacted" },
		{ value: 4, label: "Docs Loaded" },
		{ value: 5, label: "Ex-tenant" },
		{ value: 6, label: "Funds Loaded" },
		{ value: 7, label: "Wits Invoice Sent" },
		{ value: 8, label: "No Response" },
		{ value: 9, label: "Not Funded" },
		{ value: 10, label: "Not moved-in (Lease)" },
		{ value: 11, label: "Not moved-in (No Lease)" },
		{ value: 12, label: "Paid" },
		{ value: 13, label: "Verified" },
		{ value: 14, label: "Verify error" },
		{ value: 15, label: "Bad Debt" },
		{ value: 16, label: "Awaiting Funds" },
		{ value: 17, label: "Payment Arrangement" },
		{ value: 18, label: "PNYA" },
		{ value: 19, label: "No Status" },
		{ value: 20, label: "Appealing" },
		{ value: 21, label: "Appeals" },
		{ value: 22, label: "Provisionally Funded" },
	];

	const botFlowTypes = [
		{label: "All Robots", value: ""},
		{label: "Red", value: "1"},
		{label: "Green", value: "2"},
		{label: "Bot Flow", value: "3"}
	];

	function doFilter() {
		if (filterProp.current.value > 0) {
			props.setFilterLocation({
				id: parseInt(filterProp.current.value),
				name: filterProp.current[filterProp.current.selectedIndex].text,
			});
		} else {
			props.setFilterLocation({ id: 0, name: "" });
		}

		if (process.env.REACT_APP_COLOUR === "UC") {
			if (filterGender.current.value > 0) {
				props.setFilterGender({
					id: parseInt(filterGender.current.value),
					name: filterGender.current[filterGender.current.selectedIndex].text,
				});
			} else {
				props.setFilterGender({ id: 0, name: "" });
			}

			if (filterFunding.current.value > 0) {
				props.setFilterFunding({
					id: parseInt(filterFunding.current.value),
					name: filterFunding.current[filterFunding.current.selectedIndex].text,
				});
			} else {
				props.setFilterFunding({ id: 0, name: "" });
			}

			if (filterStudyYear.current.value > 0) {
				props.setFilterStudyYear({
					id: parseInt(filterStudyYear.current.value),
					name: filterStudyYear.current[filterStudyYear.current.selectedIndex]
						.text,
				});
			} else {
				props.setFilterStudyYear({ id: 0, name: "" });
			}

			if (filterBudget.current.value > 0) {
				props.setFilterBudget({
					id: parseInt(filterBudget.current.value),
					name: filterBudget.current[filterBudget.current.selectedIndex].text,
				});
			} else {
				props.setFilterBudget({ id: 0, name: "" });
			}

			if (filterUnitType.current.value > 0) {
				props.setFilterUnitType({
					id: parseInt(filterUnitType.current.value),
					name: filterUnitType.current[filterUnitType.current.selectedIndex]
						.text,
				});
			} else {
				props.setFilterUnitType({ id: 0, name: "" });
			}

			if (filterBookingSummary.current.value > 0) {
				props.setFilterBookingSummary({
					id: parseInt(filterBookingSummary.current.value),
					name: filterBookingSummary.current[
						filterBookingSummary.current.selectedIndex
						].text,
				});
			} else {
				props.setFilterBookingSummary({ id: 0, name: "" });
			}

			if (filterBotFlow.current.value !== "") {
				props.setFilterBotFlow({
					id: parseInt(filterBotFlow.current.value),
					name: botFlowTypes.find(type => type.value === filterBotFlow.current.value)?.label
				});
			} else {
				props.setFilterBotFlow({ id: 0, name: "" });
			}
		}

		if (props.type === "collections") {
			if (filterVendor.current.value > 0) {
				props.setFilterVendor({
					id: parseInt(filterVendor.current.value),
					name: filterVendor.current[filterVendor.current.selectedIndex].text,
				});
			} else {
				props.setFilterVendor({ id: 0, name: "" });
			}

			if (filterStatus.current.value > 0) {
				props.setFilterStatus({
					id: parseInt(filterStatus.current.value),
					name: filterStatus.current[filterStatus.current.selectedIndex].text,
				});
			} else {
				props.setFilterStatus({ id: 0, name: "" });
			}
		} else {
			props.setFilterVendor({ id: 0, name: "" });
			props.setFilterStatus({ id: 0, name: "" });
		}

		// setFilterGender

		if (
			props.type != "management" &&
			props.type != "mentions" &&
			props.type != "collections"
		) {
			axios({
				method: "get",
				url: `${process.env.REACT_APP_API_URL}event/${props.type}`,
			});
		}

		if (props.type == "management") {
			axios({
				method: "get",
				url: `${process.env.REACT_APP_API_URL}tag_event/${props.tag_id}`,
			});
		}

		if (props.type == "collections") {
			axios({
				method: "get",
				url: `${process.env.REACT_APP_API_URL}collection_event/${props.collection_tag_id}`,
			});
		}

		if (props.type == "mentions") {
			axios({
				method: "get",
				url: `${process.env.REACT_APP_API_URL}mentions/${props.current_staff_id}`,
			});
		}

		// console.log(filterDoc)

		// if (filterDoc.current.value > 0) {
		//   props.setFilterDocuments({
		//     id: parseInt(filterDoc.current.value),
		//     name: filterDoc.current[filterDoc.current.selectedIndex].text,
		//   });
		// } else {
		//   props.setFilterDocuments({ id: 0, name: "" });
		// }

		dispatch(closeModal());
	}

	// console.log(props)

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				static
				className="fixed z-50 inset-0 overflow-y-auto"
				open={open}
				onClose={() => dispatch(closeModal())}
			>
				<div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span
						className="hidden sm:inline-block sm:align-middle sm:h-screen"
						aria-hidden="true"
					>
            &#8203;
          </span>

					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6">
							<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
								<Dialog.Title
									as="h1"
									className="text-lg font-medium text-gray-900 capitalize"
								>
									Filter Chat by Property{" "}
									{process.env.REACT_APP_COLOUR === "UC" ? " & Gender" : ""}{" "}
									{props.type === "collections" ? " & Vendor & Status" : ""}
								</Dialog.Title>

								<Dialog.Panel>
									<ErrorBoundary>
										<div className="flex space-x-2 flex-col justify-center flex-wrap sm:flex-row">
											<select
												name="property_id"
												ref={filterProp}
												className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
											>
												<option value="">All Buildings</option>
												<option
													value="1000"
													selected={
														"1000" == props.filterLocation.id
															? "selected"
															: false
													}
												>
													No Building
												</option>

												{props.properties &&
													props.properties.length > 0 &&
													props.properties.map((p) => (
														<option
															key={p.value}
															value={p.value}
															selected={
																p.value == props.filterLocation.id
																	? "selected"
																	: false
															}
														>
															{p.label}
														</option>
													))}
											</select>

											{process.env.REACT_APP_COLOUR === "UC" ? (
												<>
													<select
														name="gender_id"
														ref={filterGender}
														className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
													>
														<option value="">All Genders</option>

														{props.genderOptions &&
															props.genderOptions.length > 0 &&
															props.genderOptions.map((p) => (
																<option
																	key={p.value}
																	value={p.value}
																	selected={
																		p.value == props.filterGender.id
																			? "selected"
																			: false
																	}
																>
																	{p.label}
																</option>
															))}
													</select>

													<select
														name="gender_id"
														ref={filterFunding}
														className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
													>
														<option value="">All Funding</option>

														{props.fundingOptions &&
															props.fundingOptions.length > 0 &&
															props.fundingOptions.map((p) => (
																<option
																	key={p.value}
																	value={p.value}
																	selected={
																		p.value == props.filterFunding.id
																			? "selected"
																			: false
																	}
																>
																	{p.label}
																</option>
															))}
													</select>

													<select
														name="study_year_id"
														ref={filterStudyYear}
														className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
													>
														<option value="">All Study Years</option>

														{props.lookups[8] &&
															props.lookups[8].length > 0 &&
															props.lookups[8].map((p) => (
																<option
																	key={p.value}
																	value={p.value}
																	selected={
																		p.value == props.filterStudyYear.id
																			? "selected"
																			: false
																	}
																>
																	{p.label}
																</option>
															))}
													</select>

													<select
														name="unit_type_id"
														ref={filterUnitType}
														className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
													>
														<option value="">Ignore Unit Type</option>
														<option
															value="1"
															selected={
																props.filterUnitType.id == 1
																	? "selected"
																	: false
															}
														>
															Has Unit Type
														</option>
														<option
															value="2"
															selected={
																props.filterUnitType.id == 2
																	? "selected"
																	: false
															}
														>
															No Unit Type
														</option>
													</select>

													<select
														name="booking_summary"
														ref={filterBookingSummary}
														className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
													>
														<option value="">Ignore Booking Summary</option>
														<option
															value="1"
															selected={
																props.filterBookingSummary.id == 1
																	? "selected"
																	: false
															}
														>
															Has Booking Summary
														</option>
														<option
															value="2"
															selected={
																props.filterBookingSummary.id == 2
																	? "selected"
																	: false
															}
														>
															No Booking Summary
														</option>
													</select>

													<select
														name="budget_id"
														ref={filterBudget}
														className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
													>
														<option value="">All Budgets</option>

														{props.lookups[15] &&
															props.lookups[15].length > 0 &&
															props.lookups[15].map((p) => (
																<option
																	key={p.value}
																	value={p.value}
																	selected={
																		p.value == props.filterBudget.id
																			? "selected"
																			: false
																	}
																>
																	{p.label}
																</option>
															))}
													</select>

													<select
														name="bot_flow"
														ref={filterBotFlow}
														className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2"
													>
														{botFlowTypes.map(type => (
															<option
																key={type.value}
																value={type.value}
																selected={type.value === props.filterBotFlow?.id?.toString()}
															>
																{type.label}
															</option>
														))}
													</select>
												</>
											) : (
												""
											)}

											{props.type === "collections" ? (
												<>
													<select
														name="vendor_id"
														ref={filterVendor}
														className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
													>
														<option value="">All Vendor</option>

														{vendor_data &&
															vendor_data.length > 0 &&
															vendor_data.map((v) => (
																<option
																	key={v.value}
																	value={v.value}
																	selected={
																		v.value == props.filterVendor.id
																			? "selected"
																			: false
																	}
																>
																	{v.label}
																</option>
															))}
													</select>

													<select
														name="status_id"
														ref={filterStatus}
														className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
													>
														<option value="">All Status</option>

														{status_data &&
															status_data.length > 0 &&
															status_data.map((s) => (
																<option
																	key={s.value}
																	value={s.value}
																	selected={
																		s.value == props.filterStatus.id
																			? "selected"
																			: false
																	}
																>
																	{s.label}
																</option>
															))}
													</select>
												</>
											) : (
												""
											)}

											{/* {props.booking_list_name && props.booking_list_name == "documents" && (

                          <select
                            name="documents"
                            ref={filterDoc}
                            className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                          >
                            <option value="">All Documents</option>

                            {docType &&
                              docType.length > 0 &&
                              docType.map((p) => (
                                <option
                                  key={p.value}
                                  value={p.value}
                                  selected={
                                    p.value == props.filterDocuments.id
                                      ? "selected"
                                      : false
                                  }
                                >
                                  {p.label}
                                </option>
                              ))}
                          </select>
                    )} */}

											<button
												className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
												onClick={() => doFilter()}
											>
												Filter
											</button>
										</div>
									</ErrorBoundary>
								</Dialog.Panel>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
