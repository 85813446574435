import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faSignature, faDownload, faEye, faLoader, faTrash } from '@fortawesome/pro-regular-svg-icons';
import CustomButton from '../Helpers/CustomButton';
import { toast } from 'react-toastify';
import NotificationToast from '../Helpers/NotificationToast';
import ViewFile from '../Helpers/ViewFile';
import { useDispatch } from 'react-redux';
import { openModal } from '../../store/modal/modalReducer';

const GridDocumentGeneration = ({ current_booking, current_staff }) => {
	const [documents, setDocuments] = useState([]);
	const [selectedDocument, setSelectedDocument] = useState('');
	const [loading, setLoading] = useState(false);
	const [loadingSign, setLoadingSign] = useState(false);
	const [downloadingFile, setDownloadingFile] = useState(false);
	const [bookingFiles, setBookingFiles] = useState([]);
	const [loadingFiles, setLoadingFiles] = useState(false);
	const [deletingFile, setDeletingFile] = useState(null);
	const dispatch = useDispatch();

	// Define fetchBookingFiles function before using it
	const fetchBookingFiles = () => {
		if (current_booking?.id) {
			setLoadingFiles(true);
			axios.get(`${process.env.REACT_APP_API_URL}booking/${current_booking.id}/files`)
				.then(response => {
					setBookingFiles(response.data || []);
				})
				.catch(error => {
					console.error('Error fetching booking files:', error);
					toast(<NotificationToast title="Error" message="Could not load booking files" type="error" />);
				})
				.finally(() => {
					setLoadingFiles(false);
				});
		}
	};

	// Define deleteFile function
	const deleteFile = async (fileId) => {
		if (window.confirm('Are you sure you want to delete this file?')) {
			setDeletingFile(fileId);
			try {
				await axios.delete(`${process.env.REACT_APP_API_URL}delete_file_admin/${fileId}`);
				toast(<NotificationToast title="Success" message="File deleted successfully" />);
				fetchBookingFiles(); // Refresh the file list
			} catch (error) {
				console.error('Error deleting file:', error);
				toast(<NotificationToast title="Error" message="Failed to delete file" type="error" />);
			} finally {
				setDeletingFile(null);
			}
		}
	};

	useEffect(() => {
		// Fetch available document templates
		axios.get(`${process.env.REACT_APP_API_URL}get_document_templates`)
			.then(response => {
				setDocuments(response.data);
			})
			.catch(error => {
				console.error('Error fetching documents:', error);
				toast(<NotificationToast title="Error" message="Could not load document templates" type="error" />);
			});

		// Fetch booking files
		fetchBookingFiles();
	}, [current_booking]);

	const generateDocument = (requireSignature = false) => {
		if (!selectedDocument) {
			toast(<NotificationToast title="Error" message="Please select a document" type="error" />);
			return;
		}

		const loadingState = requireSignature ? setLoadingSign : setLoading;
		loadingState(true);

		const endpoint = requireSignature
			? `${process.env.REACT_APP_API_URL}document/generate-for-signing`
			: `${process.env.REACT_APP_API_URL}document/generate`;

		axios.post(endpoint, {
			document_id: selectedDocument,
			booking_id: current_booking.id,
			user_id: current_booking.user.id,
			format: 'url'
		})
			.then(response => {
				if (response.data.status === 'success') {
					if (requireSignature) {
						toast(<NotificationToast title="Success" message="Document generated and ready for signing" />);
					} else {
						// For direct download
						// window.location.href = response.data.url;
						toast(<NotificationToast title="Success" message="Document generated successfully" />);
					}
					// Refresh the files list after generating a document
					fetchBookingFiles();
				} else {
					toast(<NotificationToast title="Error" message={response.data.message || "An error occurred"} type="error" />);
				}
			})
			.catch(error => {
				console.error('Error generating document:', error);
				toast(<NotificationToast title="Error" message="Failed to generate document" type="error" />);
			})
			.finally(() => {
				loadingState(false);
			});
	};

	const downloadFile = async (file) => {
		setDownloadingFile(true);
		try {
			const response = await axios({
				method: "get",
				responseType: "blob",
				url: `${process.env.REACT_APP_API_URL}download_file/${file.id}`,
			});

			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", file.name + "." + (file.extension || ''));
			document.body.appendChild(link);
			link.click();
		} catch (error) {
			console.error('Error downloading file:', error);
			toast(<NotificationToast title="File Missing" message="Something went wrong, we could not download this file!" type="error" />);
		} finally {
			setDownloadingFile(false);
		}
	};

	const viewFiles = (file) => {
		dispatch(openModal({
			modalType: 'VIEW_FILES',
			modalProps: {
				files: [file]
			}
		}));
	};

	return (
		<div>
			<h3 className="text-lg font-medium mb-4">Generate Documents</h3>

			<div className="mb-4">
				<label htmlFor="document-select" className="block text-sm font-medium text-gray-700 mb-1">
					Select Document Template
				</label>
				<select
					id="document-select"
					className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
					value={selectedDocument}
					onChange={(e) => setSelectedDocument(e.target.value)}
				>
					<option value="">Select a document</option>
					{documents.map(doc => (
						<option key={doc.id} value={doc.id}>{doc.name}</option>
					))}
				</select>
			</div>

			<div className="flex flex-col space-y-3">
				<CustomButton
					text="Generate Document"
					styling="w-full"
					icon={faFilePdf}
					onClick={() => generateDocument(false)}
					loading={loading}
				/>

				<CustomButton
					text="Generate for Signing"
					styling="w-full"
					icon={faSignature}
					onClick={() => generateDocument(true)}
					loading={loadingSign}
				/>
			</div>

			<div className="mt-6">
				<h4 className="font-medium mb-2">Recent Documents</h4>
				{loadingFiles ? (
					<p className="text-sm text-gray-500">Loading documents...</p>
				) : bookingFiles.length > 0 && bookingFiles.some(file => file.document_id > 0) ? (
					<div className="space-y-2">
						{bookingFiles
							.filter(file => file.document_id > 0)
							.map(file => (
								<div key={file.id} className="flex justify-between items-center p-2 border rounded">
									<span className="text-sm truncate max-w-[60%]" title={file.name || file.filename || 'Document'}>
										{file.name || file.filename || 'Document'}
									</span>
									<div className="flex items-center space-x-3">
										{file.extension && (file.extension.toLowerCase() === 'pdf' ||
											file.extension.toLowerCase() === 'jpg' ||
											file.extension.toLowerCase() === 'jpeg' ||
											file.extension.toLowerCase() === 'png' ||
											file.extension.toLowerCase() === 'gif') && (
											<FontAwesomeIcon
												icon={faEye}
												className="cursor-pointer text-primary hover:text-gray-500"
												title="View file"
												onClick={() =>
													dispatch(
														openModal({
															modal_type: "ModalViewFiles",
															modal_props: {
																files: [
																	{
																		id: file.id,
																	},
																],
															},
														})
													)
												}
											/>
										)}
										<FontAwesomeIcon
											icon={downloadingFile ? faLoader : faDownload}
											className={`cursor-pointer ${downloadingFile ? 'animate-spin' : ''} text-primary hover:text-primary_hover`}
											title="Download file"
											onClick={() => downloadFile(file)}
										/>
										<FontAwesomeIcon
											icon={deletingFile === file.id ? faLoader : faTrash}
											className={`cursor-pointer ${deletingFile === file.id ? 'animate-spin' : ''} text-red-500 hover:text-red-700`}
											title="Delete file"
											onClick={() => deleteFile(file.id)}
										/>
									</div>
								</div>
							))}
					</div>
				) : (
					<p className="text-sm text-gray-500">No documents generated yet</p>
				)}
			</div>
		</div>
	);
};

export default GridDocumentGeneration;