import { faExternalLinkAlt } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

export default function SettingsNewPage() {
    return (
        <div className="container p-5 mx-auto">
            <h1 className="font-bold mb-5">Settings - New</h1>
            <div className="flex justify-start items-center gap-4 mb-5">
                <Link to="/bot_flow" className="bg-primary text-white px-4 py-2 rounded-full">
                    Bot Flows
                </Link>

                <Link to="/admin_lookups/building_room_types" className="bg-primary text-white px-4 py-2 rounded-full">
                    Building Room Type Filters
                </Link>

                <Link to="/admin/whatsapp_templates" className="bg-primary text-white px-4 py-2 rounded-full">
                    Whatsapp Templates
                </Link>

                <a className="bg-primary text-white px-4 py-2 rounded-full" target="_blank" href={process.env.REACT_APP_LARAVEL_URL + "admin/documents"}>
                    Document Templates <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-2" />
                </a>

                <a className="bg-primary text-white px-4 py-2 rounded-full" target="_blank" href={process.env.REACT_APP_LARAVEL_URL + "admin/mda_transaction_types"}>
                    MDA Transaction Types <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-2" />
                </a>

                <Link to="/admin/uploads" className="bg-primary text-white px-4 py-2 rounded-full">
                    Uploads
                </Link>
            </div>
        </div>
    );
}
