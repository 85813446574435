import React, { Fragment, useState, useEffect } from "react";
import { closeModal } from "../../store/modal/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import { Formik, Form, Field } from "formik";
import ErrorBoundary from "../../containers/ErrorBoundary";
import NotificationToast from "../Helpers/NotificationToast";
import SubmitButton from "../Forms/SubmitButton";
import { getBooking } from "../../store/booking/bookingActions";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export default function ModalAssign(props) {
	const dispatch = useDispatch();
	const [open] = useState(true);
	const { tags } = useSelector((state) => state.tags);
	const { current_booking } = useSelector((state) => state.booking);
	const [locations, setLocations] = useState({
		noTags: [], // folders with has_tag = 0
		hasTags: [] // folders with has_tag = 1
	});
	const [allowFolders, setAllowFolders] = useState(true);
	const [currentLocation, setCurrentLocation] = useState("");

	// Get current tag
	let currentTag = current_booking?.tag || "";

	// Load folders when component mounts
	useEffect(() => {
		if (allowFolders) {
			getFolders();
		}
	}, [allowFolders]);

	// Removed getCollections() function as requested

	// Removed getRenewals() function as requested

	async function getFolders() {
		setAllowFolders(false);
		await axios({
			method: "get",
			url: `${process.env.REACT_APP_API_URL}folders`,
		}).then((response) => {
			let allFolders = response.data.folders;
			let noTagsFolders = [];
			let hasTagsFolders = [];

			// Filter out specific folders we don't want to show
			const excludedFolders = ["All", "Declined", "Cancelled", "Archived"];

			// Folders are already sorted by sort_order from the backend
			allFolders.forEach((folder) => {
				// Log folder data to understand the has_tags format
				console.log(`Folder: ${folder.name}, has_tags: ${folder.has_tags}, type: ${typeof folder.has_tags}`);

				if (!excludedFolders.includes(folder.name)) {
					// Create the folder object
					const folderObj = {
						id: folder.id,
						name: folder.name === "All Residents" ? "Residents" : folder.name
					};

					// Add to appropriate group based on has_tags value
					// Convert has_tags to number to ensure proper comparison (could be string "1" or "0")
					if (Number(folder.has_tags) === 1) {
						hasTagsFolders.push(folderObj);
					} else {
						noTagsFolders.push(folderObj);
					}

					// Set current location if it matches
					if (
						(folder.name === current_booking.location ||
							(folder.name === "All Residents" && current_booking.location === "Residents"))
					) {
						setCurrentLocation(folderObj.name);
					}
				}
			});

			console.log("Folders with tags:", hasTagsFolders);
			console.log("Folders without tags:", noTagsFolders);

			setLocations({
				noTags: noTagsFolders,
				hasTags: hasTagsFolders
			});
		}).catch(error => {
			console.error("Error fetching folders:", error);
		});
	}

	// Filter tags based on selected location
	const getTagsForLocation = (locationName) => {
		console.log("Getting tags for location:", locationName);
		const filteredTags = tags.filter(tag => tag.parent_folder === locationName);
		console.log("Filtered tags:", filteredTags);
		return filteredTags;
	};

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				static
				className="fixed z-50 inset-0 overflow-y-auto"
				open={open}
				onClose={() => dispatch(closeModal())}
			>
				<div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span
						className="hidden sm:inline-block sm:align-middle sm:h-screen"
						aria-hidden="true"
					>
            &#8203;
          </span>

					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<ErrorBoundary>
							<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
								<div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
									<button
										type="button"
										className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
										onClick={() => dispatch(closeModal())}
									>
										<span className="sr-only">Close</span>
										<FontAwesomeIcon
											className="h-6 w-6"
											aria-hidden="true"
											icon={faTimes}
										/>
									</button>
								</div>

								<div className="pr-8 w-full">
									<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
										<Dialog.Title
											as="h1"
											className="text-lg font-medium text-gray-900"
										></Dialog.Title>

										<Formik
											initialValues={{
												location: currentLocation || "",
												tag: currentTag,
											}}
											enableReinitialize="true"
											validate={(values) => {
												const errors = {};
												if (!values.location) {
													errors.location = "Please select a location";
												}
												return errors;
											}}
											onSubmit={async (
												values,
												{ setSubmitting, setErrors, resetForm }
											) => {
												setSubmitting(true);

												let submitData = {
													location: values.location,
													tag: values.tag,
													currentLocation: props.currentLocation,
													type: "folders" // Setting type to folders since collections and renewals are removed
												};

												await axios({
													method: "put",
													url: `${process.env.REACT_APP_API_URL}assign/${props.id}`,
													data: submitData,
												})
													.then(() => {
														const title = "Booking Moved";
														const message =
															"Booking moved to " + values.location;

														toast(
															<NotificationToast
																title={title}
																message={message}
															/>
														);
													})
													.catch((error) => {
														console.log(error);
														console.log(error.response);
													})
													.finally(() => {
														setSubmitting(false);
														resetForm();
														dispatch(getBooking());

														dispatch(closeModal());
													});
											}}
										>
											{({
												  values,
												  touched,
												  errors,
												  setFieldValue,
												  setFieldTouched,
												  handleSubmit,
												  isSubmitting,
												  isValid,
												  dirty,
											  }) => (
												<Form
													onSubmit={handleSubmit}
													className="space-y-8 m-4 w-full "
												>
													<h2>Location:</h2>

													<div>
														<Field
															as="select"
															name="location"
															className="capitalize cursor-pointer relative p-2 mx-2 bg-light_background rounded-md focus:outline-none transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
															onChange={(e) => {
																setFieldValue("location", e.target.value);
																// Reset tag when location changes
																setFieldValue("tag", "");
																console.log("Selected location:", e.target.value);
																console.log("Has tags?", locations.hasTags.some(loc => loc.name === e.target.value));
															}}
														>
															<option value="">Select a location</option>

															{/* Display locations with no tags */}
															{locations.noTags.length > 0 && (
																<optgroup label="Folders">
																	{locations.noTags.map((location) => (
																		<option
																			key={location.id}
																			value={location.name}
																			className={classNames(
																				location.name === current_booking?.location
																					? "text-white bg-primary"
																					: "text-black"
																			)}
																		>
																			{location.name}
																		</option>
																	))}
																</optgroup>
															)}

															{/* Display locations with tags */}
															{locations.hasTags.length > 0 && (
																<optgroup label="Folders with Tags">
																	{locations.hasTags.map((location) => (
																		<option
																			key={location.id}
																			value={location.name}
																			className={classNames(
																				location.name === current_booking?.location
																					? "text-white bg-primary"
																					: "text-black"
																			)}
																		>
																			{location.name}
																		</option>
																	))}
																</optgroup>
															)}
														</Field>

														{errors.location && touched.location && (
															<p className="text-red-400 text-sm ml-2">
																{errors.location}
															</p>
														)}

														{/* Dynamically show tags based on selected location */}
														{values.location &&
															locations.hasTags.some(loc => loc.name === values.location) && (
																<div className="mt-4">
																	<h3 className="text-sm font-medium mb-2">Tag:</h3>
																	<Field
																		as="select"
																		name="tag"
																		className="capitalize cursor-pointer relative p-2 mx-2 bg-light_background rounded-md transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
																	>
																		<option value="">No Tag</option>
																		{getTagsForLocation(values.location).map((tag) => (
																			<option
																				key={tag.id}
																				value={tag.id}
																				className={classNames(
																					tag.id === currentTag
																						? "text-white bg-primary"
																						: "text-black"
																				)}
																			>
																				{tag.name}
																			</option>
																		))}
																	</Field>
																</div>
															)}
													</div>

													<SubmitButton
														name="Save"
														disabled={!isValid || isSubmitting || !dirty}
														isSubmitting={isSubmitting}
														text="Assign"
													/>
												</Form>
											)}
										</Formik>
									</div>
								</div>
							</div>
						</ErrorBoundary>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
}